
import { getData, upload, deleteData } from './requests.js'

export const uploadMedia = (form_data, progress_callback) => {
	return upload('/api/media/upload', form_data, progress_callback)
}

// TODO: user based media resource access 
export const getMedia = () => {
	return getData('/api/media')
}

export const deleteMedia = (file_name) => {
	return deleteData('/api/media', {file_name: file_name})
}

export const getIcons = () => {
	return getData('/api/media/icons')
}

