
import React from 'react'
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { store, Event } from '../redux.js'


import FormCantiereConfig from '../forms/FormCantiereConfig.js';
import FormDataloggerConfig from '../forms/FormDataloggerConfig.js';
import FormDeviceConfig from '../forms/FormDeviceConfig.js';
import FormChannelConfig from '../forms/FormChannelConfig.js';

import { deleteCantiere, deleteDatalogger, deleteDevice, deleteChannel, deleteMapObject, deleteMap } from '../actions.js'

class DeleteMapModal extends React.Component {
	constructor(props) {
		super(props);
		
		this.apply = () => {
			deleteMap(this.props.map_id)
			this.hide();
		}

		this.hide = () => {
			store.dispatch({type: Event.CLOSE_DIALOG});
		}
		
	}
	
	render() {
		return (
			<>
				{this.props.open && this.props.type === "remove_map" && <Dialog
					className="modal-config"
					isOpen={true}
					onClose={this.hide}
					title="Cancella mappa"
				>
					<Classes.DIALOG_BODY>
						<div className='modal-content'>
							<> Sei sicuro di voler eliminare la mappa <b>{this.props.map_name}</b> ? </>
						</div>
					</Classes.DIALOG_BODY>
					<Classes.DIALOG_FOOTER>
						<Button 
							intent={Intent.PRIMARY}
							onClick={this.apply}
						>
							Elimina
						</Button>
						<Button 
							onClick={this.hide}
						>
							Anulla
						</Button>
					</Classes.DIALOG_FOOTER>

				</Dialog>
				}
			</>
		);
	}

}

const mapStoreToProps = (store) => {
	return ({
		open: store.modal_open,
		type: store.modal_type,
		map_id : store.modal_args.id,
		map_name : store.modal_args.name
		
	})
};

export default connect(mapStoreToProps)(DeleteMapModal)