
import React, { useEffect, useState } from 'react'
import { InputGroup, NumericInput, Switch, Icon } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import {StringValue, TextValue, FormValue, NumericValue, DropdownMenu } from '../components/Form.js'
import '../css/Form.scss'

import {store, Event} from "../redux.js"
import { getCurrentMapObjects } from "../actions.js"
import { RemoveButton, RemoveFromMapButton } from "../modules/Buttons.js"
import { TooltipContent } from "../modules/Tooltip.js"
import VirtualChannelStartDate from "./components/VirtualChannelStartDate.js"
import FormulaInput from "./components/ForumulaInput.js"
import VirtualChannelZeroValue from './components/VirtualChannelZeroValue.js'

const FormVirtualChannelConfig = (props) => {
	const { data } = props
	const [new_values, setNewValues] = useState(data)
	const [expression, setExpression] = useState(data.expression)
	const [enabled, setEnabled] = useState(data.enabled)
	const [last_import_changed, setLastImportChanged] = useState(false)
	const [compensation_value, setCompensationValue] = useState(0)

	const [selected_db, setSelectedDb] = useState(null)
	//const [db_list, setDbList] = useState([])

	const onChange = (field, value) => {
		setNewValues({...new_values, [field]: value})
		props.onChange(field, value)
	}

	const db_name_raw = new_values.db_name_raw ? new_values.db_name_raw : store.getState().current_cantiere_data.db_name_raw
	const db_name_live = new_values.db_name_live ? new_values.db_name_live : store.getState().current_cantiere_data.db_name_live
	const db_list = [
		store.getState().current_cantiere_data.db_name_raw ,
		store.getState().current_cantiere_data.db_name_live,
	]

	const map_object_refs = getCurrentMapObjects();
	return (
		<div className='form form-vchannel'>
			<ElementGroup caption={<><Icon icon='edit'/> Proprietà</>} className="form-b1-left">
				<FormValue 
					label="Abilitato"
					modified={data.enabled != new_values.enabled}
				>
					<Switch
						checked={enabled}
						onChange={(event) => {
							onChange("enabled", event.target.checked)
							setEnabled(event.target.checked)
						}}
					/>
				</FormValue>
				
				<StringValue
					label="Nome"
					tooltip={TooltipContent.VIRTUAL_CHANNEL_NAME}
					defaultValue={data.name}
					modified={data.name !== new_values.name}
					onChange={(event)=> {
						onChange("name", event.target.value)
					}}
				/>
				
				<StringValue
					tooltip={TooltipContent.VIRTUAL_CHANNEL_GROUP}
					label="Gruppo"
					defaultValue={data.group}
					modified={data.group !== new_values.group}
					onChange={(event)=> {
						onChange("group", event.target.value)
					}}
				/>
			</ElementGroup>
			<ElementGroup caption={<><Icon icon='database'/> Database</>} className="form-b1-right">
				<DropdownMenu
					modified={data.db_name_raw != new_values.db_name_raw}
					label="Sorgente (raw)"
					value={{label: db_name_raw, value: db_name_raw}}
					options={db_list.map((e) => ({label: e, value: e}))}
					isSearchable={false}
					onChange={(e) => (onChange("db_name_raw", e.value))}
				/>
				<DropdownMenu
					label="Destinazione (live)"
					modified={data.db_name_live != new_values.db_name_live}
					value={{label: db_name_live, value: db_name_live}}
					options={db_list.map((e) => ({label: e, value: e}))}
					isSearchable={false}
					onChange={(e) => (onChange("db_name_live", e.value))}
				/>

			</ElementGroup>

			<ElementGroup caption={<><Icon icon="function"/> Calcolo</>} className="form-b2-mid">
				<NumericValue
					tooltip={TooltipContent.VIRTUAL_CHANNEL_CHANNEL_TIMEOUT}
					label="Timeout dei canali (s)"
					defaultValue={data.channel_timeout}
					stepSize={10}
					modified={data.channel_timeout !== new_values.channel_timeout}
					onValueChange={(v) => {
						onChange("channel_timeout", v)
					}}
				/>

				<FormValue 
					label="Data d'inizio"
					modified={last_import_changed}
				>
					<VirtualChannelStartDate
						id={data.id}
						start_date_ts={data.last_import}
						onChange ={(new_date) => {
							setLastImportChanged(true)
							onChange("last_import", Math.floor(new_date.getTime()/1000))
						}}
					/>
				</FormValue>

				<VirtualChannelZeroValue 
					expression={expression}
					start_date_ts={new_values.last_import}
					timeout={new_values.channel_timeout}
					defaultValue={data.zero_value}
					value={new_values.zero_value}
					modified={data.zero_value !== new_values.zero_value}
					compensation_value = {compensation_value}
					setCompensationValue = {setCompensationValue}
					onChangeValue = {(v) => {
						onChange("zero_value", v)
					}}
				/>
				<FormulaInput
					db_name_raw={db_name_raw}
					tooltip={TooltipContent.VIRTUAL_CHANNEL_FORMULA}
					defaultValue={data.expression}
					start_date_ts={new_values.last_import}
					timeout={new_values.channel_timeout}
					expression={expression}
					modified={data.expression !== new_values.expression}
					onChange={(e) => {
						onChange("expression", e.target.value)
						setExpression(e.target.value)
					}}
					zeroValueCallback={setCompensationValue}
				/>
			</ElementGroup>

			<ElementGroup caption={<><Icon icon='annotation'/> Altro</>} className="form-b3-mid">
				<TextValue
					label="Nota"
					placeholder=""
					defaultValue={data.note}
					modified={data.note !== new_values.note}
					onChange={(e) => {
						onChange("note", e.target.value)
					}}
				/>

				<StringValue 
					label="URL Grafana" 
					modified={data.grafana_url !== new_values.grafana_url}
					defaultValue={data.grafana_url}
					onChange={(e)=> {
						onChange("grafana_url", e.target.value)
					}}
				/>
				<FormValue>
					<RemoveFromMapButton
						ready={true}
						map_object_refs={map_object_refs}
						type="vchannel"
						target_id_list={[store.getState().details.data.id]}
					/>&nbsp;
					<RemoveButton
						ready={true}
						{...store.getState().details}
					/>
				</FormValue>
			</ElementGroup>
		</div>
	);
}


export default FormVirtualChannelConfig