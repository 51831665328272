import { upload, postData } from './requests.js'

export const uploadFeedbackAttachment = (form_data, progress_callback) => {
	return upload('/api/feedback/attachment', form_data, progress_callback)
}

export const submitFeedback = (email, text, attachments, details) => {
	const data = {
		email: email,
		text: text,
		attachments: attachments,
		details: details
	}
	console.debug(data)
	return postData('/api/feedback', data)
}