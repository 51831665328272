
import React, { useState, useEffect } from 'react'
import {StringValue, TypeSelector, TextValue, DropdownMenu, NumericValue, PathValue, FormValue, ValueCheckbox, InputGroup} from '../components/Form.js'
import { Tooltip } from "@blueprintjs/core"
import Select from 'react-select'
import ElementGroup from '../components/ElementGroup.js'
import '../css/Form.scss'
import { store } from '../redux.js'
import StartDate from "./components/StartDate.js"
import { getDeviceTypesByDataloggerType, getGrafanaDashboardListByOrg } from '../api/wda_api.js'

const FormDeviceCreate = (props) => {
	const [name, setName] = useState(props.defaults.name)
	const [dropdown_options, setDropdownOptions] = useState([])
	const [device_type_dropdown_loading, setDeviceTypeDropdownLoading] = useState(true)
	const [grafana_dashboards_dropdown_loading, setGrafanaDashboardsDropdownLoading] = useState(true)
	const [device_types, setDeviceTypes] = useState([])
	const [grafana_dashboard_list, setGrafanaDashboardList] = useState([])
	const [current_device_type, setCurrentDeviceType] = useState({
		id: props.type, 
		has_path: true, 
		has_priority: true,
		has_last_index: true,
		default_max_channels: props.defaults.max_channels,
		default_channel_prefix: props.defaults.create_channels_prefix
	})
	const [max_channels, setMaxChannels] = useState(props.defaults.max_channels)
	const [create_channels, setCreateChannels] = useState(props.defaults.create_channels)
	const [create_channels_prefix, setCreateChannelsPrefix] = useState(props.defaults.create_channels_prefix)

	const [grafana_create_panel, setGrafanaCreatePanel] = useState(props.defaults.grafana_create_panel)
	const [grafana_panel_name, setGrafanaPanelName] = useState(props.defaults.name)
	const [grafana_dashboard_name, setGrafanaDashboardName] = useState("") // current cantiere name
	const [grafana_set_home_dashboard, setGrafanaSetHomeDashboard] = useState(props.defaults.grafana_set_home_dashboard)
	const [first_channel_number, setFirstChannelNumber] = useState(props.defaults.first_channel_number)
	
	useEffect(()=> {
		const state = store.getState()
		// comonentDidMount
		const default_dashboard_name = state.current_cantiere
		setGrafanaDashboardName(default_dashboard_name)
		// get device types
		getDeviceTypesByDataloggerType(props.standalone ? -1 : state.details.data.type).then((data) => {
			setDeviceTypes(data)
		}).finally(()=> {
			setDeviceTypeDropdownLoading(false)
		})
		// get available grafana dashboards
		getGrafanaDashboardListByOrg(state.current_cantiere).then((dashboard_list) => {
			setGrafanaDashboardList(dashboard_list)
		}).catch((e) => {
			setGrafanaDashboardList([])
			console.error("Failed to get grafana dashoard list for org %s, Details: %o", state.current_cantiere, e)
		}).finally(()=> {
			setGrafanaDashboardsDropdownLoading(false)
		})
		props.onChange("last_import", state.current_cantiere_data.start)
		
	}, [])

	useEffect(() => {
		// current device type changed
		setCreateChannelsPrefix(current_device_type.default_channel_prefix ? current_device_type.default_channel_prefix : props.defaults.create_channels_prefix)
		setMaxChannels(current_device_type.default_max_channels)
		
	}, [current_device_type])

	useEffect(() => {
		// name changed
		setGrafanaPanelName(name)
	}, [name])

	useEffect(() => {
		// max channels changed
		props.onChange("max_channels", max_channels)
	}, [max_channels])

	useEffect(() => {
		// create channels prefix changed
		props.onChange("create_channels_prefix", create_channels_prefix)
	}, [create_channels_prefix])

	useEffect(() => {
		// grafana dashboard name changed
		props.onChange("grafana_dashboard_name", grafana_dashboard_name)
		props.onChange("grafana_url", `${grafana_panel_name}@${grafana_dashboard_name}`)
	}, [grafana_dashboard_name])

	useEffect(() => {
		props.onChange("first_channel_number", first_channel_number)
	}, [first_channel_number])

	useEffect(() => {
		// grafana panel name changed
		props.onChange("grafana_panel_name", grafana_panel_name)
		props.onChange("grafana_url", `${grafana_panel_name}@${grafana_dashboard_name}`)
	}, [grafana_panel_name])


	return (
		<div className='form'>
			<StringValue
				label="Nome"
				value={name}
				placeholder="Nome dell'acquisitore"
				onChange={(event)=> {
					props.onChange("name", event.target.value)
					props.onChange("sn", event.target.value)
					setName(event.target.value)
				}}
			/>

			<TypeSelector
				label="Tipo"
				defaultValue={{label: "<predefinito>", value: props.type}}
				onChange={(option)=> {
					setCurrentDeviceType(option.value)
					if (!option.value.has_path)
						props.onChange({
							type: option.value.id,
							import_path : "",
							archive_path: ""
						})
					else 
						props.onChange("type", option.value.id)
				}}
				options={device_types.map((e) => {
					return {
						label: e.name,
						value: e,
						manufacturer: e.manufacturer
					}}
				)}
				isLoading={device_type_dropdown_loading}
			/>

			{ current_device_type.has_priority && 	
				<FormValue>
					<ValueCheckbox 
						label="Alta priorità" 
						defaultValue={false}
						onChange={(e)=> {
							props.onChange("priority", e.target.checked)
						}}
					/>
				</FormValue>
			}

			<NumericValue
				label="Numero dei canali"
				value={max_channels}
				min={1}
				max={1000}
				placeholder="Numero massimo dei canali"
				onValueChange={(val)=> {
					setMaxChannels(val)
				}}
			/>

			<StringValue
				label="Numero di serie"
				defaultValue={props.sn || ""}
				placeholder="S/N, MAC, ecc"
				onChange={(event)=> {
					props.onChange("sn", event.target.value)
				}}
			/>

			{ current_device_type.has_path && 
				<>
					<PathValue
						label="Path"
						value={props.import_path || ""}
						placeholder=""
						onChange={(value, real_path)=> {
							props.onChange({
								import_path: value,
								real_path: real_path
							})
						}}
					/>

					<PathValue
						label="Path Archivio"
						value={props.archive_path || ""}
						placeholder=""
						onChange={(value, real_path)=> {
							props.onChange("archive_path", value)
						}}
					/>
				</>
			}

			<FormValue 
				label={<>Inizio <br/>d'importazione</>}
			>
				<StartDate
					onChange={(new_date) => {
						props.onChange("last_import", Math.floor(new_date.getTime() / 1000))
					}}
				/>
			</FormValue>

			{ current_device_type.has_last_index && 	
				<NumericValue
					label="Ultimo indice"
					defaultValue={0}
					min={0}
					placeholder="Numero massimo dei canali"
					onValueChange={(val)=> {
						props.onChange("last_index", val)
					}}
			/>
			}

			{ current_device_type.has_parameters && 
				<TextValue
					label="Parametri"
					defaultValue=""
					placeholder="Parametri aggiuntivi"
					spellCheck={false}
					onChange={(event)=> {
						props.onChange("parameters", event.target.value)
					}}
				/>	
			}

			<FormValue label="Canali">
				<ValueCheckbox
					label={`Creare i canali automaticamente (${max_channels})`}
					checked={create_channels}
					onChange={(target)=> {
						props.onChange("create_channels", !create_channels)
						setCreateChannels(!create_channels)
					}}
				/>
				
				
				<NumericValue
					label="Numero iniziale"
					value={first_channel_number}
					min={1}
					max={1000}
					step={1}
					onValueChange={(val)=> {
						setFirstChannelNumber(val)
					}}
				/>
				
				<FormValue label="Prefisso">
					<InputGroup
						disabled={!create_channels}
						placeholder={current_device_type.default_channel_prefix}
						value={create_channels_prefix || "Canale"}
						onChange={(event) => {
							setCreateChannelsPrefix(event.target.value)
						}}
					/>
				</FormValue>
				<p>{create_channels_prefix ? `Esempio: "${create_channels_prefix} ${first_channel_number}" ... "${create_channels_prefix} ${first_channel_number+max_channels-1}"` : "Prefisso"}</p>
				
			</FormValue>
				<FormValue label="Pannello grafico">

					<ValueCheckbox
						label="Creare il grafico"
						checked={grafana_create_panel}
						onChange={(target) => {
							props.onChange("grafana_create_panel", !grafana_create_panel)
							setGrafanaCreatePanel(!grafana_create_panel)
						}}
					/>
					
					<FormValue label="Cruscotto">
						<Select
							isDisabled={!grafana_create_panel}
							options={grafana_dashboard_list.map((dashboard_name) => {
								return ({
									label: dashboard_name,
									value: dashboard_name
								})
							})}
							onChange={(option) => {
								setGrafanaDashboardName(option.value)
							}}
							onInputChange={(value) => {
								setGrafanaDashboardName(value)
							}}
							placeholder={grafana_dashboard_name}
							onBlur={(e) => {
								// workaround to prevent react-input from clearing input on blur
								e.preventDefault()
								const value = grafana_dashboard_name
								setTimeout(()=>{setGrafanaDashboardName(value)}, 100)
							}}
							
						/>
					</FormValue>

					<ValueCheckbox
						label="Imposta come la pagina principale"
						disabled={!grafana_create_panel}
						checked={grafana_create_panel && grafana_set_home_dashboard}
						onChange={(target) => {
							props.onChange("grafana_set_home_dashboard", !grafana_set_home_dashboard)
							setGrafanaSetHomeDashboard(!grafana_set_home_dashboard)
						}}
					/>

					<FormValue label="Titolo del pannello">
						<InputGroup
							disabled={!grafana_create_panel}
							defaultValue={grafana_panel_name}
							onChange={(event) => {
								setGrafanaPanelName(event.target.value)	
							}}
						/>
					</FormValue>
				</FormValue>
			
		</div>	
	)
}

export default FormDeviceCreate