import React from 'react'
import Select from 'react-select'
import '../css/DropdownSelect.css'

const DropdownSelect = (props) => {
	return (
		<Select
			className='dropdown-select'
			{...props}
		/>
	)
}

export default DropdownSelect