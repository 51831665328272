import { Button, Classes, Dialog, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from "react-redux"
import { editMapObject } from '../actions.js'
import MapObjectEditor from "../modules/MapObjectEditor.js"
import { Event, store } from "../redux.js"


const MapObjectEditorModal = (props) => {
	const [data_valid, setDataValid] = useState(true)
	const [form_data, setFormData] = useState({})
	const [ready, setReady ] = useState(true)

	const onSubmit = (data) => {
		setReady(false)
		editMapObject(data).then(() => {
			onDismiss();
		}).finally(() => {
			setReady(true);
		})
	}

	const onDismiss = () => {
		setFormData([])
		store.dispatch({
			type: Event.CLOSE_MAP_OBJECT_EDITOR
		})
	}


	return (
		<Dialog
			isOpen={props.show}
			onClose={onDismiss}
			title="Modifica l'oggetto"
		>
			<Classes.DIALOG_BODY>
				<MapObjectEditor 
					onChange={(new_form_data)=> {setFormData({...form_data, ...new_form_data})}}
					setDataValid={setDataValid}
				/>
			</Classes.DIALOG_BODY>
			<Classes.DIALOG_FOOTER>
				<Button 
					intent={Intent.PRIMARY}
					onClick={()=>{ onSubmit(form_data)}}
					disabled={!(data_valid && form_data && ready)}
				>
					Applica
				</Button>
				<Button 
					onClick={onDismiss}
				>
					Annulla
				</Button>
			</Classes.DIALOG_FOOTER>
			
		</Dialog>
	);

}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_map_object_editor
	})
}

export default connect(mapStoreToProps)(MapObjectEditorModal)