
import { Button, Classes, Dialog, Icon, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createCantiere, createChannel, createDatalogger, createDevice, createVirtualChannel } from '../actions.js';
import FormCantiereCreate from '../forms/FormCantiereCreate.js';
import FormChannelCreate from '../forms/FormChannelCreate.js';
import FormDataloggerCreate from '../forms/FormDataloggerCreate.js';
import FormDeviceCreate from '../forms/FormDeviceCreate.js';
import FormVirtualChannelCreate from '../forms/FormVirtualChannelCreate.js';
import { showNotification } from '../modules/Toast.js';
import { Event, store } from '../redux.js';



const caption_by_object_type = {
	cantiere : 			<><Icon icon='office' /> Nuovo cantiere</>,
	map : 				<><Icon icon='new-map' /> Nuova mappa</>,
	datalogger : 		<><Icon icon='cell-tower' /> Nuovo datalogger</>,
	device : 			<><Icon icon='inbox' /> Nuovo acquisitore</>,
	device_standalone : <><Icon icon='cube' /> Nuovo acquisitore </>,
	channel : 			<><Icon icon='tag' /> Nuovo canale</>,
	vchannel : 			<><Icon icon='function' /> Nuovo canale virtuale </>
}


const createCantiere2 = (data) => {
		return createCantiere(data).then(() => {
			showNotification("success", "Crea cantiere", "Il cantiere e' stato creato con successo")
		}).catch((e) => {
			if (e.code === 0)
				showNotification("danger", "Errore della connessione")
			else if (e.code === 400)
				showNotification("danger", "Richiesta errata", e.message)
			else if (e.code === 409) // cantiere gia' esiste
				store.dispatch({
					type: Event.OPEN_DELETE_CONFIRMATION,
					args: {
						id: e.id,
						prompt: <>Il cantiere <b>{e.name}</b> e' gia' stato configurato<br/> Vuoi cancellare il cantiere esistente? </>
					}
				})
			else
				showNotification("danger", `Errore del server: ${e.code}`, e.message)
			throw(e)
		})
}


const definitions_by_type = {
	cantiere : {
		func : createCantiere2,
		default_parameters: {
			name : "",
			description : "",
			start : Math.ceil(new Date().getTime()/1000),
			grafana_url : "",
			create_grafana_org: true,
			gps_lat : 45.46406,
			gps_lon : 9.18708
		},
		parent_type: null
	},

	datalogger : {
		func: createDatalogger,
		default_parameters: {
			name: "",
			sn: "",
			type : 0,
			import_path : "",
			archive_path : "",
			description : "",
			poll_period : 0,
			alarm_period: 0,
			grafana_url: "",
			priority: false
		},
		parent_type : "cantiere"
	},

	device : {
		func: createDevice,
		default_parameters : {
			name: "",
			description : "",
			type : 0,
			sn : "",
			name_as_channel_prefix: 1,
			grafana_url: "",
			max_channels: 4,
			create_channels : true,
			create_channels_prefix : "Canale",
			grafana_create_panel : true,
			first_channel_number : 1,
			grafana_set_home_dashboard : false
		},
		parent_type : "datalogger"
	},

	device_standalone : {
		func: createDevice,
		default_parameters : {
			name: "",
			description: "",
			type: 0,
			sn: "",
			name_as_channel_prefix: 1,
			grafana_url: "",
			max_channels: 4,
			create_channels : true,
			create_channels_prefix : "Canale",
			grafana_create_panel : true,
			first_channel_number : 1
		},
		parent_type: "cantiere"
	},

	channel : {
		func: createChannel,
		default_parameters : {
			x0: 0,
			x1: 1,
			x2: 0,
			x3: 0,
			x4: 0,
			x5: 0,
			vsat_min: 0,
			vsat_max: 0,
			vsat_behavior: 0,
			grafana_url: ""
		},
		parent_type : "device"
	},

	vchannel : {
		func: createVirtualChannel,
		default_parameters : {
			channel_timeout : 30,
			note : "",
			grafana_url : "",
			expression : ""
		},
		parent_type: "cantiere"
	}
}


const CreateModal = (props) => {
	const { parent_id } = props
	const [form_data, setFormData] = useState({})
	const [loading, setLoading] = useState(false)

	const apply = () => {
		if (definitions_by_type.hasOwnProperty(props.object)) {
			setLoading(true)
			// get default parameters and callback function by object type
			const { default_parameters, parent_type, func }  = definitions_by_type[props.object]
			// add parent_id attribute {parent_type : parent_id}
			var parent_ref = {}
			if (parent_type === "cantiere") {
				// workaround for virtual channels: disable override of cantiere id
				parent_ref = {"cantiere" : store.getState().current_cantiere_id}
			}
			else if (parent_type != null) {
				parent_ref = {[parent_type] : parent_id}
			}
			func ({
				...default_parameters, 
				...form_data, 
				...parent_ref
			}).then(hide).finally(complete); // do not close modal on error
		}
	}

	const hide = () => {
		setFormData({});
		store.dispatch({type: Event.CLOSE_DIALOG});
	}
		
	const complete = () => {
		setLoading(false)
	}

	const modifyHandler = (field, value) => {
		if (typeof field === "object")
			setFormData((curr_form_data) => {
				return ({
					...curr_form_data,
					...field
				})
			})
		else
			setFormData((curr_form_data) => {
				return ({
					...curr_form_data,
					[field]: value
				})
			})
	}
	
	const content = () => {
		if (props.object === "cantiere")	
			return (
				<FormCantiereCreate 
					onChange={modifyHandler}	
				/>
			)

		if (props.object === "datalogger")
			return (
				<FormDataloggerCreate 
					onChange={modifyHandler}
				/>
			)

		if (props.object === "device")
			return (
				<FormDeviceCreate
					onChange={modifyHandler}
					defaults={definitions_by_type.device.default_parameters}
				/>
			)

		if (props.object === "device_standalone")
			return (
				<FormDeviceCreate
					onChange={modifyHandler}
					standalone={true}
					defaults={definitions_by_type.device_standalone.default_parameters}
				/>
			)

		if (props.object === "channel")
			return (
				<FormChannelCreate
					onChange={modifyHandler}
				/>
			)
		
		if (props.object === "vchannel")
			return (
				<FormVirtualChannelCreate
					onChange={modifyHandler}
				/>
			)

		if (props.object === "object")
			return ("Unknown object")
	}

	return (
		<>
			{props.open && props.type === "create" && <Dialog
				className={`modal-config modal-config-${props.object}`}
				isOpen={true}
				onClose={hide}
				title={caption_by_object_type[props.object]}
			>
				<Classes.DIALOG_BODY>
					<div className='modal-content'>
						{content()}
					</div>
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={apply}
						disabled={loading}
					>
						Crea
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	);

}

const mapStoreToProps = (store) => {
	return ({
		open : store.modal_open,
		type : store.modal_type,
		object: store.modal_args.object,
		parent_id : store.modal_args.parent_id
	})
};

export default connect(mapStoreToProps)(CreateModal)