import {Event, store } from './redux.js'

import * as login_api from './api/login_api.js'
import * as api from './api/wda_api.js'
import * as media_api from './api/media_api.js'
import * as influx_api from './api/influx_api.js'

import { showNotification } from "./modules/Toast.js"
import { map } from 'leaflet'


const dbg = (text) => {
	return showNotification("warning", "debug", text)
}

const dbgObj = (data) => {
	return dbg(JSON.stringify(data));
}
const clearCurrentUser = () => {
	store.dispatch({
		type: Event.SET_CURRENT_USER,
		user : null
	})
}


export const clearCookies = () => {
	["/", "/wda-dashboard", "/wda-dashboard/", "/wda-dashboard/v1", "/report", "/wda-dashboard/v1/api"].forEach((path) => {
		document.cookie = `grafana_session=;path=${path};expires=${new Date(0).toUTCString()}`
	})
}

export const setAuthToken = (token) => {
	["wda-dashboard", "/wda-dashboard/", "/report"].forEach((path) => {
		document.cookie = `grafana_session=${token};path=${path}`
	})
}

export const getObjectByTypeAndId = (_type, _id) => {
	const targetTypeToStoreKey = (key) => {
		return ({
			"map"              : "maps",
			"channel"          : "channels",
			"device"           : "devices",
			"datalogger"       : "dataloggers",
			"cantiere"         : "cantieri",
			"vchannel"         : "vchannels",
			"vchannel-group"   : "vchannel_groups"
		}[key])
	}
	const state = store.getState()[targetTypeToStoreKey(_type)] || []
	const refs = state.filter((e) => e.id === _id)
	if (refs.length < 1) {
		console.error(`No matching object found for type ${_type} and ID ${_id}`)
		return null
	}

	return refs[0]
}


export const updateCantiereList = () => {
	return api.getCantiereList().then((data) => {
		store.dispatch({
			type : Event.FETCH_CANTIERE_LIST,
			cantieri: data.cantieri
		})
		return Promise.resolve(data)
	})
}

const updateDetails = (object, id) => {
	var details = {};
	switch (object) {
		case 'datalogger':
			details = store.getState().dataloggers.filter((e) => e.id === id)[0];
			break;
		case 'device':
			details = store.getState().devices.filter((e) => e.id === id)[0];
			break;
		case 'channel':
			details = store.getState().channels.filter((e) => e.id === id)[0];
			break;
		case 'vchannel':
			details = store.getState().vchannels.filter((e) => e.id === id)[0];
			break;
		case 'maps':
			details = store.getState().maps.filter((e) => e.id === id)[0];
			break;
		case 'map_objects':
			details = store.getState().map_objects.filter((e) => e.id === id)[0];
			break;
		default:
			details = {};
			break;
	}

	store.dispatch({
		type : Event.SHOW_DETAILS,
		object : object,
		data : details
	})
}


export const updateCurrentUser = () => {
	return login_api.getCurrentUser().then((user_info) => {
		store.dispatch({
			type: Event.SET_CURRENT_USER,
			user : user_info
		});
	
		const last_cantiere = localStorage.getItem('last_cantiere')
		return updateCantiereList().then((data) => {
			// TODO: check membership
			const cantiere = (last_cantiere && true) ? last_cantiere : user_info.current_org
			// console.log("CANTIERI %o, last %s", data.cantieri, cantiere)
			if (data.cantieri.map((c) => c.name).includes(cantiere)) {
				return selectCantiere(cantiere).then(() => {
					const map_id = parseInt(localStorage.getItem("map"))
					if (store.getState().maps.filter((m) => m.id === map_id).length > 0) // check if current map id exists
						selectMap(map_id)
				})
			}
			else
				return selectCantiere(null)
				
		})
	}).catch((e) => {
		clearCurrentUser();
		console.error(e);
		clearCookies();
	})
}	


export const login = (username : string, password : string) => {
	return login_api.login(username, password).then((response) => {
		console.log('login success')
		setAuthToken(response.token)
		updateCurrentUser();	
	}).catch((e)=> {
			console.log('login failed: %o', e)
			clearCurrentUser()
			throw e;
		}
	)
}

export const logout = () => {
	login_api.logout().then(() => {
		clearCookies()
	})
	store.dispatch({type: Event.LOGOUT})
}

export const updateTree = (cantiere_name = null) => {
	const current_cantiere = cantiere_name || store.getState().current_cantiere;
	if (current_cantiere !== null) {
		store.dispatch({type: Event.REQUEST_BEGIN, object: "cantieri"})
		store.dispatch({type: Event.REQUEST_BEGIN, object: "dataloggers"})

		return api.getCantiereData(current_cantiere).then((data) => {
			// "virtual_channel_groups" not exists in original response
			// we should recreate it by combining "group" property of each virtual channel
			const vchannel_group_names = [...new Set(data.vchannels.map((vc) => vc.group))].filter((vc) => vc !== "").sort() // unique list of vchannel group names
			const vchannel_groups = vchannel_group_names.map((group_name) => {
				// convert to standard object with "id" and "name"
				return {
					id: group_name,
					name: group_name
				}
			})
			store.dispatch({
				type: Event.FETCH_CANTIERE_DATA,
				dataloggers: data.dataloggers,
				channels : data.channels,
				vchannels : data.vchannels,
				vchannel_groups: vchannel_groups,
				devices : data.devices,
				id: data.id
			})
		}).finally(()=> {
			store.dispatch({type: Event.REQUEST_END, object: "cantieri"})
			store.dispatch({type: Event.REQUEST_END, object: "dataloggers"})
		})
	}
	else { 
		// cantiere is null
		store.dispatch({
			type: Event.FETCH_CANTIERE_DATA,
			dataloggers : [],
			channels: [],
			vchannels: [],
			devices: [],
			id : 0
		})
		return Promise.resolve()
	}
}

export const selectCantiere = (cantiere_name) => {
	store.dispatch({
		type: Event.SELECT_CANTIERE,
		name : cantiere_name
	})
	
	localStorage.setItem("last_cantiere", cantiere_name || "");
	updateTree(cantiere_name)
	
	if (cantiere_name !== null) {
		return api.getCantiereByName(cantiere_name).then((data) => {	
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object : 'cantiere',
				data : data
			})
			
			store.dispatch({
				type: Event.SHOW_CANTIERE_ON_MAP,
				map_object : {
					id: -1,
					label: data.name,
					color: "#ff0000",
					icon: "marker_default.png",
					target_type: "cantiere",
					target_id: data.id,
					scale: 2,
					description: `Cliente: ${data.client}`,
					x: data.gps_lon,
					y: data.gps_lat
				}
			})

			store.dispatch({type: Event.MAP_RENDER})
			store.dispatch({type: Event.MAP_UPDATE_MARKERS})

			getCurrentCantiereMapObjects();
			return getCurrentCantiereMaps();
		})
	}
	return Promise.resolve()
}


export const moveVirtualChannelToGroup = (vchannel, group_name) => {
	console.debug(`Move virtual channel ${vchannel.name} to group ${group_name}`)
	modifyVirtualChannel(vchannel.id, {
		group: group_name
	})
}


const getCurrentCantiereMaps = () => {
	return api.getMapsByCantiere(store.getState().current_cantiere_id).then((data) => {
		store.dispatch({
			type: Event.FETCH_MAPS,
			maps : data
		})
	})
}


const getCurrentCantiereMapObjects = () => {
	return api.getMapObjectsByCantiere(store.getState().current_cantiere_id).then((data) => {
		store.dispatch({
			type: Event.FETCH_MAP_OBJECTS,
			map_objects : data
		})
	})
}


export const getCurrentMapObjects = () => {
	const state = store.getState()
	return state.current_map_objects.filter((o) => 
		(o.target_type === state.details.type && o.target_id === state.details.data.id)
	).map((map) => map.id)
}


export const uploadMap = (form_data, progress_callback) => {
	return new Promise((resolve, reject) => {
		api.uploadMap(form_data, progress_callback).then((resp) => {
			showNotification("success", "Caricamento file", "L'immagine e' stato caricato con successo");
			resolve(resp)
		}).catch((e) => { 
			//showNotification("error", "Caricamento file", "Errore nel caricamento della mappa: "+JSON.stringify(e));
			// TODO: explain error
			showNotification("error", "Caricamento file", "Errore nel caricamento della mappa: estensione non supportata o le dimensioni non valide");
			reject(e)
		})
	})
}


export const uploadMedia = (form_data, progress_callback) => {
	return new Promise((resolve, reject) => {
		media_api.uploadMedia(form_data, progress_callback).then((resp) => {
			showNotification("success", "Caricamento file", "L'immagine e' stato caricato con successo");
			resolve(resp)
		}).catch((e) => { 
			showNotification("error", "Caricamento file", "Errore nel caricamento della mappa: estensione non supportata o le dimensioni non valide");
			reject(e)
		})
	})
}




export const createMap = (data) => {
	// TODO: handle map creation logic
	return api.createMap({
		cantiere: store.getState().current_cantiere_id,
		...data
	}).then((response) => {
		// fetch response from server containing map ID
		const id = response.id;
		// refresh map list
		getCurrentCantiereMaps().then(() => {
			// and select new map by id
			selectMap(id)
		})
	})
}


export const selectMap = (map_id) => {
	console.debug(`[actions] select map with id ${map_id}`)
	if (map_id === -1)
		return Promise.resolve();

	localStorage.setItem("map", map_id);
		
	store.dispatch({
		type: Event.SELECT_MAP,
		map_id : map_id
	})
	store.dispatch({type: Event.MAP_RENDER})

	// first get map objects from local cache 
	const cached_map_objects = store.getState().map_objects.filter((mo) => mo.map === map_id)
	store.dispatch({
		type : Event.FETCH_CURRENT_MAP_OBJECTS,
		map_objects : cached_map_objects
	})
	
	// meanwhile waiting an update from server
	return updateMapObjects(map_id).then(() => {applyMapMarkerStyles()})
}

export const updateMapObjects = (map_id = store.getState().current_map.id) => {

	// update global cache
	/*
	api.getMapObjectsByCantiere(store.getState().current_cantiere_id).then((data) => {
		store.dispatch({
			type: Event.FETCH_MAP_OBJECTS,
			map_objects: data
		})
	})
	*/
	
	// update current map object cache
	return api.getMapObjectsByMap(map_id).then((data) => {
		store.dispatch({
			type: Event.FETCH_CURRENT_MAP_OBJECTS,
			map_objects : data // TODO: make object draggable only for su, admin and editor roles
		})

		// update global cache
		store.dispatch({
			type: Event.FETCH_MAP_OBJECTS,
			map_objects: store.getState().map_objects.map((original_map_object) => {
				data.forEach((new_map_object) => {
					if (new_map_object.id === original_map_object.id)
						return new_map_object // new map object id is already present, pick new one
				})
				return original_map_object // new map object id is not present, pick unchanged values
			})
		})

		store.dispatch({type: Event.MAP_UPDATE_MARKERS})
	})
}


export const addToCurrentMap = (object) => {
	const current_map_id = store.getState().current_map.id;
	return api.createMapObject({
		map : current_map_id,
		...object
	})
}

export const saveDroppedMapObject = (target_type, target_id, x, y, data) => {
	const current_map_id = store.getState().current_map.id;
	return api.createMapObject({
		map: current_map_id,
		target_type : target_type,
		target_id : target_id,
		label: data.name,
		x: x,
		y: y,
		grafana_url: " ",
		icon: "marker_default.png",
		note: `${target_type} / ${data.name}`,
		color: "#800000",
		scale: 1
	})
}


/* ===============================   MODIFY =============================== */

export const modifyCantiere = (id : int, fields : Object) => {
	store.dispatch({type: Event.APPLY_CONFIG, object: "cantiere"});
	return api.modifyCantiere({id: id, ...fields}).then((response)=> {
		const new_name = fields.hasOwnProperty('name') ? fields.name : store.getState().details.data.name
		updateCantiereList().then(() => {
			selectCantiere(new_name)
		})
		// TODO: check if result is OK
		//query(ApiEvents.CANTIERE_QUERY, {id : this.props.data.id})
	});
}

export const modifyDatalogger = (id : int, fields : Object) => {
	store.dispatch({type: Event.APPLY_CONFIG, object: "datalogger"});
	return api.modifyDatalogger({id: id, ...fields}).then((response)=> {
		updateTree().then(() => {
			updateDetails("datalogger", id)
		})
	});
}

export const modifyDevice = (id : int, fields : Object) => {
	store.dispatch({type: Event.APPLY_CONFIG, object: "device"});
	return api.modifyDevice({id: id, ...fields}).then((response)=> {
		updateTree().then(() => {
			updateDetails("device", id)
		})
	});
}

export const modifyChannel = (id : int, fields : Object) => {
	store.dispatch({type: Event.APPLY_CONFIG, object: "channel"});
	return api.modifyChannel({id: id, ...fields}).then((response)=> {
		updateTree().then(() => {
			updateDetails("channel", id)
		})
		if (response.hasOwnProperty("message") && response.message !== "OK") {
			return showNotification("warning", "Attenzione", response.message)
		}
	});
}

export const modifyVirtualChannel = (id : int, fields : Object) => {
	store.dispatch({type: Event.APPLY_CONFIG, object: "vchannel"});
	return api.modifyVirtualChannel({id: id, ...fields}).then((response)=> {
		updateTree().then(() => {
			updateDetails("vchannel", id)
		})
	});
}

export const modifyVirtualChannelGroup = (args: api.IVirtualChannelModifyRequest) => {
	return api.modifyVirtualChannelGroup(args).then((response) => {
		updateTree().then(() => {
			// select new
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object : "vchannel-group",
				data : getObjectByTypeAndId("vchannel-group", args?.new_name || args.name)
			})
		})
	})
}

export const moveMapObject = (param) => {
	store.dispatch({
		type: Event.MOVE_MAP_OBJECT,
		...param
	})
	return editMapObject(param)
}

export const editMapObject = (param) => {
	return api.modifyMapObject(param).then(() => {
		updateCurrentMapObjects().then(applyMapMarkerStyles)
	})
}


/* ===============================  CREATE =============================== */

export const createCantiere = (fields: Object) => {
	// store.dispatch({type: Event.APPLY_CONFIG, object: "cantiere"});
	return api.createCantiere(fields).then(() => {
		updateCantiereList().then(() => 
			selectCantiere(fields.name)
		)
	})
}

export const createCantiereFromJson = (json_data: object) => {
	return api.createCantiereFromJson(json_data).then(() => {
		updateCantiereList().then((data) => {
			selectCantiere(json_data.Project)
		})
	})
}

export const createDatalogger = (fields : Object) => {
	store.dispatch({
		type: Event.APPLY_CONFIG, 
		object: "datalogger"
	});
	return api.createDatalogger(fields).then((response)=> {
		updateTree().then(() => {
			updateDetails("datalogger", response.id)
		})
	});
}

export const createDevice = (fields: Object) => {
	store.dispatch({
		type: Event.APPLY_CONFIG, 
		object: "device"
	});
	return api.createDevice(fields).then((response)=> {
		updateTree().then(() => {
			updateDetails("device", response.id)
		})
	});
}

export const createChannel = (fields: Object) => {
	store.dispatch({
		type: Event.APPLY_CONFIG, 
		object: "channel"
	});
	return api.createChannel(fields).then((response)=> {
		updateTree().then(() => {
			updateDetails("channel", response.id)
		})
		if (response.hasOwnProperty("message") && response.message !== "OK") {
			showNotification("warning", "Attenzione", response.message)
		}
	});
}

export const createVirtualChannel = (fields: Object) => {
	store.dispatch({
		type: Event.APPLY_CONFIG, 
		object: "vchannel"
	});
	return api.createVirtualChannel(fields).then((response)=> {
		updateTree().then(() => {
			updateDetails("vchannel", response.id)
		})
	});
}

/* ===============================   DELETE =============================== */

export const deleteChannel = (id) => {
	return api.deleteChannel(id).finally(() => {
		// TODO: handle deletion logic

		// get parent device of the removed channel
		const parent_device_id = store.getState().channels.filter((ch) => ch.id === id)[0].device;
		const parent_device_data = store.getState().devices.filter((d) => d.id === parent_device_id)[0];

		updateTree().finally(() => {
			// select current datalogger
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object: "device",
				data: parent_device_data
			})
			updateCurrentMapObjects()
		})
	})
}

export const deleteCantiere = (id, delete_grafana=false, delete_data=false, keyphrase="") => {
	return api.deleteCantiere(id, delete_grafana, delete_data, keyphrase).then(() => {
		updateCantiereList().finally(() => {
			selectCantiere(null)
		})
	})
}

export const deleteVirtualChannel = (id) => {
	const parent_cantiere_id = store.getState().vchannels.filter((vc) => vc.id === id)[0]?.cantiere;
	const parent_cantiere_data = store.getState().cantieri.filter((c) => c.id === parent_cantiere_id)[0];
	return api.deleteVirtualChannel(id).finally(() => {
		updateTree().finally(() => {
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object: "cantiere",
				data: parent_cantiere_data
			})
			updateCurrentMapObjects()
		})
	})
}

export const deleteVirtualChannelGroup = (name: string) => {
	const parent_cantiere_id = store.getState().vchannels.filter((vc) => vc.group === name)[0]?.cantiere;
	const parent_cantiere_data = store.getState().cantieri.filter((c) => c.id === parent_cantiere_id)[0];
	return api.deleteVirtualChannelGroup(name, parent_cantiere_id).then((response) => {
		showNotification("success", "Cancellazione", response?.message || "OK")
	}).finally(() => {
		updateTree().finally(() => {
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object: "cantiere",
				data: parent_cantiere_data
			})
			updateCurrentMapObjects()
		})
	})
}

export const deleteDevice = (id) => {
	return api.deleteDevice(id).finally(() => {
		// get parent datalogger of the removed device
		const parent_datalogger_id = store.getState().devices.filter((d) => d.id === id)[0].datalogger;
		if (parent_datalogger_id == -1) {
			return updateTree().finally(() => {
				// select current cantiere
				return selectCantiere(store.getState().current_cantiere)
			})
		}

		const parent_datalogger_data = store.getState().dataloggers.filter((dl) => dl.id === parent_datalogger_id)[0];
		return updateTree().finally(() => {
			// select current datalogger
			store.dispatch({
				type: Event.SHOW_DETAILS,
				object: "datalogger",
				data: parent_datalogger_data
			})
			updateCurrentMapObjects()
		})
	})
}

export const deleteGrafanaPanel = (dashboard_name, panel_name) => {
	const state = store.getState()
	return api.deleteGrafanaPanel(state.current_cantiere, dashboard_name, panel_name)
}

export const deleteGrafanaPanelByDeviceUrl = (url: String) => {
	if (url.indexOf("@") > 0) {
		const parts = url.split("@")
		const panel_name = parts[0]
		const dashboard_name = parts[1]
		deleteGrafanaPanel(dashboard_name, panel_name)
	}
}

// get list of map object IDs referred to the given object
export const getMapObjectIdListByObject = (obj_type, obj_id) => {
	const state = store.getState()
	var target_object_list = [];
	var map_object_id_list = [];

	// gather real object IDs
	if (obj_type === "cantiere") 
		target_object_list.push(...state.cantieri.filter((c) => c.id === obj_id))

	else if (obj_type === "datalogger")
		target_object_list.push(...state.dataloggers.filter((dl) => dl.id === obj_id))

	else if (obj_type === "device")
		target_object_list.push(...state.devices.filter((d) => d.id === obj_id))

	else if (obj_type === "channel")
		target_object_list.push(...state.channels.filter((ch) => ch.id === obj_id))

	// compare real object IDs and target IDs of the real objects
	target_object_list.forEach((obj) => {
		state.map_objects.forEach((mo) => {
			if (mo.target_id === obj.id) {
				map_object_id_list.push(mo.id)
			}
		})
	})
	console.debug("Resolved object \"%s\" ID: %d into map object list: %o", obj_type, obj_id, map_object_id_list)
	return map_object_id_list
}


export const deleteDatalogger = (id) => {
	return api.deleteDatalogger(id).finally(() => {
		return updateTree().finally(() => {
			// select current cantiere
			return selectCantiere(store.getState().current_cantiere)
		})
	})
}

export const deleteMap = (id) => {
	const current_cantiere_name = store.getState().current_cantiere
	// remove map by id
	return api.deleteMap(id).then(() => {
		// update map list
		getCurrentCantiereMaps().then(()=> {
			// select cantiere's map
			selectCantiere(current_cantiere_name)
		})
	})
}

export const deleteMapObject = (id) => {
	return api.deleteMapObject(id).then(() => {
		updateCurrentMapObjects().then(() => {
			getCurrentCantiereMapObjects()
		})
	})
}

export const toggleMapObjectLabels = () => {
	localStorage.setItem("show_map_object_labels", !store.getState().show_map_object_labels)
	store.dispatch({type : Event.TOGGLE_MAP_OBJECT_LABELS})
	if (store.getState().current_map.id !== null) {
		updateCurrentMapObjects()
	}
}

const updateCurrentMapObjects = () => {
	const current_map_id = store.getState().current_map.id
	if (!current_map_id)
		return Promise.resolve()
	return updateMapObjects()
}

export const showOnMap = (map_id, map_object_id) => {
	const gotoMapObject = () => {
		const state = store.getState()
		state.show_on_map_handler && state.show_on_map_handler(map_object_id)
	}

	if (store.getState().current_map.id !== map_id)
		selectMap(map_id).then(gotoMapObject)
	else
		gotoMapObject()
}

// modals
export const openDeleteModal = (type: str, id: int) =>  {
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type: "remove",
		modal_args: {object: type, id : id}
	})
}

export const openConfigModal = (type: str, id: int) => {
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type : "config",
		modal_args : {object: type, id : id}
	})
}

/* ===== MAIN ===== */

export const main = () => {
	updateCurrentUser();
}

const applyMapMarkerStyles = () => {
	const map_objects = store.getState().current_map_objects;
	map_objects.forEach((mo) => {
		const e = document.getElementsByClassName(`marker-id-${mo.id}`)[0]
		if (e) {
			//e.style.setProperty ("-webkit-filter", `opacity(${mo.opacity || 1.0})`)	
			e.style.setProperty("opacity", mo.opacity || 1.0)
			//e.innerHTML = "<div></div>";
		}

	})
}


export const getInfluxDBStat = (db_name, table_name) => {
	return influx_api.getDatabases().then((db_list) => {
		if (db_list.includes(db_name)) {
			// database is present
			influx_api.getTables(db_name).then((tab_list) => {
				if (tab_list.includes(table_name)) {
					influx_api.getSeriesList(db_name, table_name, 0, Date.now()).then((data) => {
						data.forEach((column) => {
							store.dispatch({
								type: Event.FETCH_INFLUXDB_STAT,
								database: db_name,
								table: table_name,
								column: column
							})
						})
					})
				}
			})
		}
	})
}

const _getVirtualChannelById = (id) => {
	return store.getState().vchannels.filter((vc) => (vc.id === id))[0]
}

export const getVirtualChannelSummaryById = (id: int) => {
	return api.getVirtualChannelHistory(id)
}

export const getVirtualChannelSummary = () => {
	return api.getVirtualChannelHistoryByCantiere(store.getState().current_cantiere_id).then((vc_stat_list) => {
		const vc_data = vc_stat_list.map((vc_stat) => {
			const channel_def = _getVirtualChannelById(vc_stat.virtual_channel)
			return {
				id : vc_stat.virtual_channel,
				name: channel_def.name,
				data: vc_stat,
				expression : channel_def.expression
			}
		})
		store.dispatch({
			type: Event.FETCH_VIRTUAL_CHANNELS_STAT,
			virtual_channel_stats: vc_data
		})
		
	})
}

export const getCantiereById = (id) => {
	const state = store.getState()
	return state.cantieri.reduce((pv, cv) => (cv.id === id ? cv : pv), null)
}

export const getDataloggersByCantiereId = (id) => {
	const state = store.getState()
	return state.dataloggers.filter((dl) => (dl.cantiere === id))
}

export const getDevicesByCantiereId = (id) => {
	const state = store.getState()
	return state.devices.filter((d) => (d.cantiere === id))
}



export const getCantiereGrafanaUrl = (id) => {
	const state = store.getState()
	const cantiere_matches = state.cantieri.filter((c) => c.id === (id || state.current_cantiere_id))
	if (cantiere_matches.length > 0)
		return cantiere_matches[0].grafana_url
	return ""
}

export const isCorrectUrl = (url) => {
	return url.match(/^https{0,1}:\/\//g)
}

export const openCantiereImportModal = () => {
	selectCantiere(null)
	store.dispatch({
		type: Event.OPEN_CANTIERE_IMPORT_MODAL,
		args: {}
	})
}

export const openCantiereCreateModal = () => {
	selectCantiere(null)
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type: "create",
		modal_args: {object: "cantiere"}
	})
}

export const openModalNewMap = () => {
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type: "create_map",
		modal_args: {}
	})
}

export const isAnyModalOpened = () => {
	const state = store.getState();
	return (
		state.modal_open || 
		state.modal_influxdb_manager || 
		state.modal_media_manager ||
		state.modal_map_object_editor || 
		state.modal_file_picker.show ||
		state.modal_virtual_channel_summary.show ||
		state.modal_feedback.show || 
		state.modal_cantiere_import.show ||
		state.modal_delete_confirmation.show
	)
}