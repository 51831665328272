
import { Button, Callout, Classes, Dialog, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createCantiereFromJson } from "../actions.js"
import { readFile } from "../api/filemanager.js"
import FilePicker from "../components/FilePicker.js"
import { showNotification } from '../modules/Toast.js'
import { Event, store } from '../redux.js'


const CantiereImportModal = (props) => {
	const [ busy, setBusy ] = useState(false)
	const [ cantiere_data, setCantiereData ] = useState(null)
	const [ error_message, setErrorMessage ] = useState(null)
	const [ selected_file, setSelectedFile ] = useState(null)

	const validateJson = (json_text) => {
		setErrorMessage(null)
		if (typeof json_text === "object")
			return json_text
		try {
			return JSON.parse(json_text)
		} catch(e) {
			setErrorMessage(e.toString())
		}
		return null
	}

	const loadSelectedFile = () => {
		setBusy(true)
		return new Promise ((resolve, reject) => {
			readFile(selected_file).then((content) => {
				var data = validateJson(content)
				setCantiereData(data)
				resolve(data)
			}).catch((e) => {
				reject(e)
			}).finally(() => {
				setBusy(false)
			})
		})
	}

	const hide = () => {
		store.dispatch({type: Event.CLOSE_CANTIERE_IMPORT_MODAL});
	}

	const createCantiere = (data) => {
		return createCantiereFromJson(data).then(() => {
			showNotification("success", "Import cantiere", "Il cantiere e' stato importato con successo")
			hide()
		}).catch((e) => {
			if (e.code === 0)
				setErrorMessage("Errore della connessione")
			else if (e.code === 400)
				setErrorMessage(`${e.message}`)
			else if (e.code === 409) // cantiere gia' esiste
				store.dispatch({
					type: Event.OPEN_DELETE_CONFIRMATION,
					args: {
						id: e.id,
						prompt: <>Il cantiere <b>{e.name}</b> e' gia' stato configurato<br/> Vuoi cancellare il cantiere esistente? </>
					}
				})
			else
				setErrorMessage(`Errore del server: ${e.code} ${e.message}`)
		}).finally(()=> {
			setBusy(false)
		})
	}

	const submit = () => {
		if (!cantiere_data) {
			loadSelectedFile().then((data) => {
				createCantiere(data)
			})
		}
		else
			createCantiere(cantiere_data)
	}
	
	return (
		<>
			{props.show && <Dialog
				className="modal-new-map"
				isOpen={props.show}
				onClose={hide}
				title={<>Import del cantiere</>}
			>
				<Classes.DIALOG_BODY>
					<FilePicker 
						dir_only={false}
						onSelect={(path) => {
							setSelectedFile(path)
							setCantiereData(null)
							setErrorMessage(null)
						}}
						onChangePath={(path) => {
							setSelectedFile(null)
							setCantiereData(null)
							setErrorMessage(null)
						}}
						file_filter={(name) => {
							return name.endsWith(".json") || name.endsWith(".txt")
						}}
					/>
				{ selected_file && 
					<Callout title="File selezionato" intent={cantiere_data && Intent.SUCCESS}>
						{selected_file}<br/>
						{!cantiere_data && (busy ? "Attendere prego..." : 
							<a 
								href="#"
								onClick={(e) => {
									e.preventDefault()
									loadSelectedFile()
								}}
							>
								Verifica...
							</a>
						)}
					
					</Callout>
				}
				{ error_message && 
					<Callout intent={Intent.DANGER} title={error_message} />  
				}
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={submit}
						disabled={busy || !selected_file}
					>
						Crea il cantiere
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	)
}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_cantiere_import.show
		// current_user : store.current_user
	})
};

export default connect(mapStoreToProps)(CantiereImportModal)