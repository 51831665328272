import React from 'react'
import { Icon } from '@blueprintjs/core'

const ShowOnGrafana = (props) => {
	const grafana_link_available = props.data.grafana_url && props.data.grafana_url.match(/^https{0,1}:\/\//g);
	return (
		grafana_link_available &&
			<div className='show-on-grafana'>
				<a href={props.data.grafana_url} target="_blank"><Icon icon='search' /> Visualizza il grafico</a>
			</div>
		
	)
}

export default ShowOnGrafana