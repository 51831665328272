import { Breadcrumbs, Button, Callout, Icon, InputGroup, Tooltip } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { createDir, listDir } from '../api/filemanager.js'

const AddressBar = (props) => {
	const items = [
		{
			icon: "more"
		}, ...props.path.map((e) => ({ text: e }))
	]
	return (
		<Breadcrumbs items={items} />
	)
}

const Toolbar = (props) => {
	const { message, file_count } = props
	var counter_text = ""
	if (file_count > 1000) {
		counter_text = <>< b > 1000</b > / <b>{file_count}</b> elementi visualizzati</>
	}
	else {
		counter_text = <><b>{file_count}</b> elementi nella cartella</>
	}

	return (
		<div className='file-picker-toolbar'>
			<Tooltip content='Indietro'>
				<Button icon='arrow-left' onClick={() => { !props.disabled && props.onBack() }} />
			</Tooltip>
			<Tooltip content='Cartella base'>
				<Button icon='home' onClick={() => { !props.disabled && props.onHome() }} />
			</Tooltip>
			<Tooltip content='Aggiorna'>
				<Button icon='refresh' onClick={() => { !props.disabled && props.onRefresh() }} />
			</Tooltip>
			<Tooltip content='Nuova cartella'>
				<Button icon='folder-new' onClick={() => { !props.disabled && props.onNewDir() }} />
			</Tooltip>
			{(file_count) &&

				<span>
					&nbsp; {counter_text}
				</span>
			}
			{message &&
				<Callout
					intent={message[0]}
					title={message[1]}
				>
					{message[2]}
				</Callout>
			}
		</div>
	)
}


const HomeLevelEntry = (props) => {
	const { loading, onClick } = props
	return (
		<div className="file-picker-list-entry" onClick={onClick}>
			<Icon icon='home' />
			<span className={`${loading ? "bp3-skeleton" : ""}`}><b>[.]</b></span>
		</div>
	)
}

const BackLevelEntry = (props) => {
	const { loading, onClick } = props
	return (
		<div className="file-picker-list-entry" onClick={onClick}>
			<Icon icon='arrow-up' />
			<span className={`${loading ? "bp3-skeleton" : ""}`}><b>[..]</b></span>
		</div>
	)
}

const FileEntry = (props) => {
	const { loading, onClick, name, selected } = props
	return (
		<div className={`file-picker-list-entry ${selected && 'file-picker-list-entry-selected'}`} onClick={onClick}>
			<Icon icon='document' />
			<span className={`${loading ? "bp3-skeleton" : ""}`}>{name}</span>
		</div>
	)
}

const DirEntry = (props) => {
	const { loading, onClick, name } = props
	return (
		<div className="file-picker-list-entry" onClick={onClick}>
			<Icon icon='folder-open' />
			<span className={`${loading ? "bp3-skeleton" : ""}`}>[{name}]</span>
		</div>
	)
}


const FilePickerEntries = (props) => {
	const { loading, entries, onDirClick, onFileClick, selected_file, file_filter } = props
	const dirs = entries.filter((e) => e.is_dir).sort((a, b) => (a.name < b.name)).map((e) => {
		return (
			<DirEntry
				name={e.name}
				loading={loading}
				onClick={() => {
					onDirClick && onDirClick(e.name)
				}}
			/>
		)
	})

	const files = entries.filter((e) => {
		return !e.is_dir && (!file_filter || (file_filter && file_filter(e.name.toLowerCase())))
	}).sort((a, b) => (a.name < b.name)).map((e) => {
		return (
			<FileEntry
				selected={e.name === selected_file}
				name={e.name}
				loading={loading}
				onClick={() => {
					onFileClick && onFileClick(e.name)
				}}
			/>
		)
	})

	return (
		<>
			{dirs}
			{files}
		</>
	)
}


const FilePicker = (props) => {
	const { dir_only, onChangePath, onSelect, path, file_filter } = props
	const show = (props.show !== false)

	const [current_path, setCurrentPath] = useState(path && path.split("/") || []) // path is an array
	const [entries, setEntries] = useState([])
	const [entries_count, setEntriesCount] = useState(0)
	const [busy, setBusy] = useState(false)
	const [message, setMessage] = useState(null)
	const [selected_file, setSelectedFile] = useState(null)

	const _setEntries = (file_list) => {
		if (file_list?.length <= 1000) {
			setEntries(file_list)
		}
		else {
			setEntries(file_list.slice(0, 1000))
		}
		setEntriesCount(file_list?.length || 0)
	}

	const selectFile = (name) => {
		setSelectedFile(name)
		onSelect && onSelect(`${current_path.join("/")}/${name}`)
	}

	const cdError = (e) => {
		setMessage(["danger", "Questa cartella non e' disponibile", `${e.code}: ${e.message}`])
		setTimeout(() => {
			setMessage(null)
		}, 2000)
	}

	const refresh = () => {
		setBusy(true)
		listDir(0, current_path.join("/")).then((data) => {
			_setEntries(data)
		}).catch(cdError).finally(() => {
			setBusy(false)
		})
	}

	useEffect(() => {
		onChangePath && onChangePath(current_path.join("/"))
		setSelectedFile(null)
	}, [current_path])


	useEffect(() => {
		if (show) {
			refresh()
		}
	}, [show])

	const goHome = () => {
		setBusy(true)
		listDir(0, "").then((data) => {  // TODO: replace cantiere id
			_setEntries(data)
			setCurrentPath([])
		}).catch(cdError).finally(() => {
			setBusy(false)
		})
	}

	const cdErrorAndGoHome = (e) => {
		cdError(e)
		goHome()
	}

	const goBack = () => {
		setBusy(true)
		var new_path = [...current_path] // clone 
		new_path.pop()
		const new_path_str = new_path.join("/")
		listDir(0, new_path_str).then((data) => {
			_setEntries(data)
			setCurrentPath(new_path)
		}).catch(cdErrorAndGoHome).finally(() => {
			setBusy(false)
		})
	}

	const goInto = (dir_name) => {
		var new_path = [...current_path] // clone 
		setBusy(true)
		new_path.push(dir_name)
		const new_path_str = new_path.join("/")
		listDir(0, new_path_str).then((data) => {
			_setEntries(data)
			setCurrentPath(new_path)
		}).catch(cdErrorAndGoHome).finally(() => {
			setBusy(false)
		})
	}

	if (!show)
		return <></>


	return (
		<>
			<AddressBar path={current_path} />
			<Toolbar
				onBack={goBack}
				onHome={goHome}
				onRefresh={refresh}
				message={message}
				onNewDir={() => {
					const name = prompt("Nome della cartella")
					if (!name || name === "")
						return
					setBusy(true)
					createDir(0, current_path.join("/"), name).then(refresh).finally(() => { setBusy(false) })
				}}
				disabled={busy}
				file_count={entries_count}
			/>
			<div className="file-picker-list-view">
				{current_path.length > 0 &&
					<>
						<HomeLevelEntry onClick={goHome} loading={busy} />
						<BackLevelEntry onClick={goBack} loading={busy} />
					</>
				}
				{entries.length > 0 ?
					<FilePickerEntries
						entries={entries}
						onDirClick={goInto}
						loading={busy}
						onFileClick={!dir_only && selectFile}
						selected_file={selected_file}
						file_filter={file_filter}
					/> :

					<div className="empty-dir-label">
						{busy ? <>Caricamento...</> : <>Questa cartella e' vuota</>}
					</div>

				}

			</div>
			<InputGroup
				value={current_path.join("/")}
				onSelect={(e) => e.preventDefault()}
			/>
		</>
	)
}


export default FilePicker