import React, { useEffect, useState } from "react"
import { Icon, Tooltip, Button, NumericInput } from "@blueprintjs/core"
import { FormValue } from "../../components/Form.js"


import { TooltipContent } from "../../modules/Tooltip.js"



const VirtualChannelZeroValue = (props) => {
	// props: start_date_ts, timeout, expression, onChangeValue
	const { onChangeValue, modified, compensation_value, setCompensationValue } = props
	const [compensation_value_string, setCompensationValueString] = useState(`${compensation_value}`)

	
	useEffect(() => {
		if (props.hasOwnProperty("defaultValue")) {
			setCompensationValue(props.defaultValue)
			setCompensationValueString(props.defaultValue)
		}
	}, [])

	useEffect(() => {
		onChangeValue && onChangeValue(compensation_value)
		setCompensationValueString(compensation_value)
	}, [compensation_value])

	return (
		<FormValue 
			label={<>Compensazione<br/><i>Valore di azzeramento</i></>}
			tooltip={TooltipContent.VIRTUAL_CHANNEL_ZERO_VALUE}
			modified={modified}
		>	
			<br/>
			<NumericInput
				value={compensation_value_string}
				onValueChange={(v, str_value) => {
					setCompensationValue(parseFloat(str_value))
					setCompensationValueString(str_value)
					
				}}
				onBlur={() => {
					setCompensationValueString(compensation_value)
				}}
			/>
			<br/>
			
		</FormValue>
	)



}


export default VirtualChannelZeroValue