
import { getData } from './requests.js'

var last_results = []
var next_request = null
var ready = true
var timer = 0

var options_update_handler = () => {}

const resolve = (results) => {
	options_update_handler && options_update_handler(results)
	last_results = results
}

export const setOptionsUpdateHandler = (func) => {
	options_update_handler = func
}

export const queryLocationList = (location_string : string) => {
	if (location_string.length < 3)
		resolve([])

	else if (!ready) {
		// only accept the latest query
		clearTimeout(timer)
		timer = setTimeout (() => {queryLocationList(location_string)}, 1000)
	}

	else {
		ready = false
		setTimeout(()=> { ready = true }, 1000)
		getData("/api/geo/location", {
			q: location_string
		}).then((data) => {
			if (Array.isArray(data))
				resolve(data.map((e) => {
					return ({
						name: e.display_name,
						lat: e.lat,
						lon: e.lon
					})
				}))
			else
				resolve([])
		})
	}
}