import React, { useEffect, useState, useMemo } from 'react'
import { Popover, Button, Position } from '@blueprintjs/core'

import { getIcons } from '../api/media_api.js'
import ColorPicker from './ColorPicker.js'


const IconPicker = (props) => {
	
	const [icon_list, fetchIconList] = useState([])

	useEffect(() => {
		getIcons().then(fetchIconList)
	}, [])


	const svgList = useMemo(() => {
		return (
			<div className='area area-icon-picker'>
					
				{icon_list.map((e) => {
					const path = `${props.base_path}/${e}`
					return (
						<div 
							className='svg-icon-container' 
							onClick={()=>{props.onSelect(e)}}
						>
							<img src={path} alt={e} width={48} height={48} />
						</div>
					)
				})}
			</div>
		)
	})
	
	return (
		<Popover
			placement={Position.RIGHT}
			className="bp3-popover-content-sizing"
			content={svgList}
		>
		<Button rightIcon="chevron-right">
			Seleziona ...
		</Button>

		</Popover>
	)
}



export default IconPicker