
import React from "react"
import { DateInput, DatePicker, TimePrecision } from "@blueprintjs/datetime"
import { Button, Popover, PopoverPosition } from "@blueprintjs/core"
import MomentLocaleUtils from "react-day-picker/moment"
import "moment/locale/it.js"


const makeDate = (action) => {
	var today = new Date()
	today.setMinutes(0)
	today.setHours(0)
	today.setSeconds(0)
	var returnVal = today
	action(returnVal)
	return returnVal
};

const time_shortcuts = [
	{
		date: makeDate((d) => d),
		label: "Oggi"
	},
	{
		date: makeDate((d) => { return d.setDate(d.getDate() - 1)}),
		label: "Ieri"
	},
	{
		date: makeDate((d) => { return d.setDate(d.getDate() - 7)}),
		label: "7 giorni fa"
	},
	{
		date: makeDate((d) => { return d.setMonth(d.getMonth() - 1)}),
		label: "1 mese fa"
	},
	{
		date: makeDate((d) => { return d.setMonth(d.getMonth() - 3)}),
		label: "3 mesi fa"
	},
	{
		date: makeDate((d) => { return d.setMonth(d.getMonth() - 6)}),
		label: "6 mesi fa"
	},
	{
		date: makeDate((d) => { return d.setFullYear(d.getFullYear() - 1)}),
		label: "1 anno fa"
	}
];


const DateTimeInput = (_props) => {
	const props = {
		timePrecision: TimePrecision.SECOND,
		includeTime: true,
		highlightCurrentDay: true,
		minDate: new Date(2000, 0, 1),
		maxDate: new Date(2099, 0, 1),
		..._props
	}

	const parseDateString = (s) => {
		const expr = /(?<D>\d{1,2})\/(?<M>\d{1,2})\/(?<Y>\d{4})[\s,]+(?<h>\d{1,2}):(?<m>\d{1,2}):(?<s>\d{1,2})/gm
		try {
			const m = expr.exec(s).groups
			return new Date(m.Y, m.M-1, m.D, m.h, m.m, m.s)
		}
		catch {
			return props.date
		}
	}
	
	return (<DateInput
		locale="it"
		localeUtils={MomentLocaleUtils}
		formatDate={(date) => date.toLocaleString()}
		shortcuts={time_shortcuts}
		//parseDate={(str) => (Date.parse(str) || props.date)}
		parseDate={parseDateString}
		value={props.date}
		{...props}
		rightElement={
			<Popover position={PopoverPosition.RIGHT} content={
				<div onClick={(e) => e.stopPropagation()} >
					<DatePicker
						value={props.date}
						{...props}
					/>
				</div>
			}>
				<Button icon="calendar"></Button>
			</Popover>
		}
	/>)
}

export const DatePickerItaliano = (props) => {
	return (<DatePicker 
		value={props.date}
		locale="it"
		localeUtils={MomentLocaleUtils}
		shortcuts={time_shortcuts}
		{...props}
	/>)
}


export default DateTimeInput