
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Popover, Menu, MenuItem, ContextMenu } from '@blueprintjs/core'
// import sample_map from '../assets/map1_4x.png'
import { connect } from 'react-redux'
import { selectMap, deleteMapObject } from '../actions.js'
import { store, Event } from '../redux.js'

import { renderLabel, renderTooltip, hidePopup, markerContextMenuHandler, markerClickHandler, MarkersLayer } from "./map/map_marker.js"
import { showNotification } from './Toast.js'

const L = require('leaflet')

const fn_stub = () => {}

const WorldMap = (props) => {
	var map = null
	var markers_layer = null
	var unsubscribe : function = fn_stub
	var _unsubscribe_cu : function = fn_stub // one time subscribe callback
	var _map = null

	const createWorldMap = (map_json) => {
		var location = null
		try {
			location = JSON.parse(map_json.image)
		}
		catch (error) {
			console.error("Error parsing map JSON: ", error)
		}
		_map = L.map('world-map-view', {
			maxZoom: 19,
			minZoom: 8,
			zoomSnap: 0.5,
			zoomDelta: 0.1
		})

		if (location) 
			_map.setView({lon: location.lon, lat: location.lat}, 37.09 * Math.pow(location.area, -0.122))
		else
			_map.setView({lon: 0, lat: 0}, 0)

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 19
		}).addTo(_map);
		L.control.scale().addTo(_map);

		_map.on("click", (e) => {
			hidePopup();
		})
		markers_layer = new MarkersLayer (_map, null);
		
		store.dispatch({ 
			type: Event.SET_SHOW_ON_MAP_HANDLER,
			handler : (map_object_id) => {
				if (markers_layer.marker_by_map_object_id.hasOwnProperty(map_object_id))
					_map.setView(markers_layer.marker_by_map_object_id[map_object_id].getLatLng(), 19)
				else
					console.error("No such map object ID %d in %o", map_object_id, markers_layer.marker_by_map_object_id)
			}
		})
		
		/* Workaround for map zoom buttons don't work */
		try {
			document.querySelector("a.leaflet-control-zoom-in").addEventListener("click", () => {
				_map.setZoom(_map.getZoom() + 1)
			})
			document.querySelector("a.leaflet-control-zoom-out").addEventListener("click", () => {
				_map.setZoom(_map.getZoom() - 1)
			})
		} catch(e) {
			console.error("Can't attach zoom buttons handler. Map is not ready yet")
		}
	}

	const destroyMap = () => {
		if (_map != null) {
			store.dispatch({
				type: Event.SET_SHOW_ON_MAP_HANDLER,
				handler: (o) => {} // dummy
			})
			_map.off()
			_map.remove()
			_map = null
			markers_layer = null
		}
	}

	// componentDidMount
	useEffect(() => {
		const current_map = store.getState().current_map
		// console.log("Current map: %o", current_map)
		createWorldMap(current_map);
		unsubscribe = store.subscribe(() => {
			const state = store.getState()
			if (state.last_event === Event.MAP_RENDER) {
				if (!state.current_map.world)
					return; // skip local map
				console.debug("[WorldMap] render triggered")
				destroyMap();
				createWorldMap(state.current_map);
			}

			else if (state.last_event === Event.MAP_UPDATE_MARKERS) {
				if (state.cantiere_ready)
					markers_layer.updateMarkers()
				else
					// marker update is triggered before the cantiere was loaded
					// so update them as soon as the cantiere gets loaded, using one time subscribe
					_unsubscribe_cu = store.subscribe(() => {
						console.debug("[WorldMap] Deferred map objects update");
						markers_layer.updateMarkers()
						_unsubscribe_cu();
					})
			}
		})

		// Drag & Drop
		const view = document.getElementById('world-map-view');
		view.ondragover = (e) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = 'move';
		}
		view.ondrop = (e) => {
			e.preventDefault();
			setTimeout(() => {
				const obj = store.getState().drag_object;
				console.debug("Place object on map: %o", obj)
				if (obj.target_type !== "") {
					const current_map = store.getState().current_map;
					if (current_map.id === null)
						showNotification("warning", "Nessuna mappa", "Si prega di selezionare la mappa")
					else
						markers_layer.addDndMarker(obj, e.clientX - view.offsetLeft, e.clientY - view.offsetTop);
				}
				store.dispatch({
					type : Event.DROP_ON_MAP // reset DND object
				})

			}, 100); // WORKAROUND: prevent 'ondrop' event before redux state update (Event.DROP_ON_MAP: target_type, target_id)
		}

		// componentWillUnmount
		return () => {
			unsubscribe();
			destroyMap();
			console.debug("[WorldMap] destroyed")
		}
	}, [])
	

	return (
		<div className='world-map-view' id='world-map-view'>

		</div>
	)

}

export default WorldMap