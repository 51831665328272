
import React, { useState } from 'react'
import { Dialog, Classes, Button, Intent, Icon, Callout } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { store, Event } from '../redux.js'
import FormMapCreate from '../forms/FormMapCreate.js'
import { createMap } from '../actions.js'

//import { createCantiere, createDatalogger, createDevice, createChannel } from '../actions.js'

const default_parameters = {
	name : "",
	description : ""
}


const NewMapModal = (props) => {
	const [ form_data, setFormData ] = useState(default_parameters)
	const [ busy, setBusy ] = useState(false)
	const [ error_message, setErrorMessage ] = useState(null)
	
	const hide = () => {
		setFormData({})
		store.dispatch({type: Event.CLOSE_DIALOG});
	}

	const apply = () => {
		if (!form_data.name) {
			setErrorMessage(["Nome della mappa", "Si prega di inserire il nome della mappa"])
		}
		else if (!form_data.world && !form_data.image) {
			setErrorMessage(["Immagine mancante", "Si prega di selezionare un'immagine"])
		}
		else {
			setBusy(true)
			createMap(form_data).then(() => {
				hide();
			}).finally(() => {
				setBusy(false)
			})
		}
		
	}

	const modifyHandler = (field, value) => {
		setErrorMessage(null)
		if (typeof field === "object")
			setFormData((curr_form_data) => {
				return ({
					...curr_form_data,
					...field
				})
			})
		else
			setFormData((curr_form_data) => {
				return ({
					...curr_form_data,
					[field]: value
				})
			})
		}	
	
	return (
		<>
			{props.open && props.type === "create_map" && <Dialog
				className="modal-new-map"
				isOpen={true}
				onClose={hide}
				title={<><Icon icon='map-create'/>Nuova mappa</>}
			>
				<Classes.DIALOG_BODY>
					<div className='modal-content'>
						<FormMapCreate
							onChange={modifyHandler}
						/>
					</div>
					{ error_message && 
						<Callout 
							intent={Intent.DANGER}
							title={error_message[0]}
						>
							{error_message[1]}
						</Callout>
					}
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={apply}
						disabled={busy}
					>
						Crea
					</Button>
					<Button 
						onClick={hide}
					>
						Anulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	)
}

const mapStoreToProps = (store) => {
	return ({
		open : store.modal_open,
		type : store.modal_type,
		cantiere_id : store.current_cantiere_id
	})
};

export default connect(mapStoreToProps)(NewMapModal)