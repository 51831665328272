
import React from 'react'
import { Icon, Button, Popover, Menu, MenuItem, Position } from '@blueprintjs/core'

import DropdownSelect from "../components/DropdownSelect.js"

import {connect} from 'react-redux'
import { store, Event } from '../redux.js'
import { MenuItem_ImportConfig } from "./ContextMenu.js"


import { selectCantiere, openCantiereCreateModal } from '../actions.js'


const DropdownListElement = (props) => {
	const { current, name } = props
	return (
		<div className='dropdown-list-element'>
			{current ? <b>{name}</b> : name}
		</div>
	);
}


// Menu contestuale
const CantiereSelectorMenu = (props) => {
	const { user, details, current_cantiere, current_cantiere_id } = props;
	const { type: _type } = details;

	const getChildrenInfo = () => {
		switch (_type) {
			case "cantiere":
				return {
					type: "datalogger",
					name: "datalogger",
					icon: "cell-tower"
				};
			case "datalogger":
				return {
					type: "device",
					name: "acquisitore",
					icon: "inbox"
				};
			case "device":
				return {
					type: "channel",
					name: "canale",
					icon: "tag"
				};
			default:
				return {
					type: "vchannel",
					name: "canale virtuale",
					icon: "function"
				};
		}
	};

	const { type: children_type, name: children_name, icon: children_icon } = getChildrenInfo();

	const cantiere_role = user.member_of?.[current_cantiere] || "None";
	const is_super = user.role === "super";
	const is_admin = cantiere_role === "Admin";
	const is_editor = cantiere_role === "Editor";
	
	// Pulsante con l'ingranaggio
	if (is_super || is_admin || is_editor) {
		// Show the button IF the user is either an admin or has a role "Admin" or "Editor" of current_cantiere
		return (	
			<Popover 
				position={Position.BOTTOM}
				content={
					<Menu>
						{is_super && 
							<MenuItem icon="office" text="Nuovo cantiere" onClick={() => {
								openCantiereCreateModal()
							}}/>
						}
	
						{current_cantiere_id !== 0 && <>
							<div className="cantiere-selector-menu-header">{current_cantiere}</div>
							{(is_super || is_admin) && 
								<MenuItem icon='edit' text="Modifica cantiere" onClick={() => {
										selectCantiere(store.getState().current_cantiere).then(() => {
											store.dispatch({
												type: Event.OPEN_DIALOG,
												modal_type: "config",
												modal_args: {
													object: "cantiere", 
													id : store.getState().current_cantiere_id, 
													name: store.getState().current_cantiere
												}
											})
										})
									}
								}/>
							}
							{is_super && <MenuItem_ImportConfig />}
							
							{(is_super || is_admin || is_editor) &&
								<MenuItem icon="function" text="Nuovo canale virtuale" onClick={() => {
									store.dispatch({
										type: Event.OPEN_DIALOG,
										modal_type: "create",
										modal_args: {object: "vchannel", parent_id: current_cantiere_id}
									})
								}}/>
							}
	
							{(is_super || is_admin || is_editor) &&
								<MenuItem  text="Nuovo acquisitore" icon="cube" onClick={()=> {
									store.dispatch({
										type: Event.OPEN_DIALOG,
										modal_type: "create",
										modal_args: {object: "device_standalone" , parent_id: current_cantiere_id}
									})
								}}/>
							}

							{(is_super || is_admin || is_editor) && 
								<MenuItem icon='cell-tower' text="Nuovo datalogger" onClick={() => {
									store.dispatch({
										type: Event.OPEN_DIALOG,
										modal_type: "create",
										modal_args: {object: "datalogger", parent_id: current_cantiere_id}
									})
								}}/>
							}
	
							{details && details?.type !== "cantiere" && details?.type !== "vchannel" && <>
								<div className="cantiere-selector-menu-header">{details?.data?.name}</div>
								{_type !== "channel" && 
									<MenuItem icon={children_icon} text={`Nuovo ${children_name}`} onClick={() => {
										store.dispatch({
											type: Event.OPEN_DIALOG,
											modal_type: "create",
											modal_args: {object: children_type, parent_id: details?.data?.id}
										})
									}}/>
								}
								<MenuItem icon='edit' text="Modifica" onClick={()=> {
									if (details?.type == "vchannel-group") {
										store.dispatch({
											type: Event.OPEN_VCHANNEL_GROUP_OPS_MODAL,
											args: details.data.id
										})
									}
									else {
										store.dispatch({
											type: Event.OPEN_VCHANNEL_GROUP_OPS_MODAL,
											args : details?.data?.id
										})
									}
								}}/>
								{(is_super || is_admin) &&
									<MenuItem icon='cross' text="Elimina" onClick={() => {
										store.dispatch({
											type: Event.OPEN_DIALOG,
											modal_type: "remove",
											modal_args: {object: details?.type, id : details?.data?.id}
										})
									}}/>
								}
							</>
							}
						</>}
					
					</Menu>
				}
			>
				<Button icon='cog' />
			</Popover>
		)
	}
	return <></>
}

// Menu drop-down con la lista dei cantieri
const CantiereSelector = (props) => {
	const { user, cantieri, current_cantiere } = props
	const onChange = (elem) => {
		selectCantiere (elem.value);			
	};

	const sudo = user.role === "super";
	const new_cantiere_item_v1 = {
		label :
			<div
				className='dropdown-list-element'
				onClick={(e) => {
					e.stopPropagation()
					openCantiereCreateModal()
				}}
			>
				<Icon icon='plus'/>&nbsp; Nuovo cantiere v1 ...
			</div>,
		value : -1
	}


	const items = cantieri.sort((ca1,ca2)=>(ca1.name > ca2.name ? 1 : -1)).map((e) => {
			const icon = !Object.keys(user.member_of).includes(e.name) ? <Icon icon='warning-sign' /> : 
				(!e.enabled ? <Icon icon='disable' />: <Icon icon='office' />)
			 
			return {
				label : <DropdownListElement 
					name={<>{icon} &nbsp; {e.name}</>}
					current={e.name === user.current_org}
				/>,
				value : e.name
			}
		})

	const filterOption = (option, input_value) => {
		const { value } = option
		if (typeof value === "string") {
			return value.toLowerCase().includes(input_value.toLowerCase())
		}
		return true
	}

	return (
		<>
			<div className='cantiere-selector'>
				<DropdownSelect
					value={{label: current_cantiere, value: current_cantiere}}
					// value={{label: current_cantiere, value: current_cantiere}}
					options={sudo ? [...items, new_cantiere_item_v1] : items}
					isSearchable={true}
					onChange={onChange}
					filterOption={filterOption}	
				>
					
				</DropdownSelect>
			</div>

			<div className='cantiere-selector-menu'>
				<CantiereSelectorMenu {...props} />
			</div>

		</>
	)
}

const mapStoreToProps = (store) => {
	return ({
		cantieri : store.cantieri,
		user : store.current_user,
		current_cantiere : store.current_cantiere,
		current_cantiere_id : store.current_cantiere_id,
		details: store.details
	});
}

export default connect(mapStoreToProps)(CantiereSelector);