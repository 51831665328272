
import React, { useState, useEffect } from 'react'
import { Icon, Button, ProgressBar, Popover, Intent, InputGroup, Input} from "@blueprintjs/core"
import Select from 'react-select'
import ElementGroup from "../components/ElementGroup.js"
import ImageUploadArea from "../components/ImageUpload.js"
import { uploadMedia } from "../actions.js"
import { DropdownMenu } from "../components/Form.js"
import "../css/MediaManager.css"
import * as api from '../api/media_api.js'
import { showNotification } from './Toast.js'

const MediaThumbnail = (props) => {
	return (
		<div className='media-thumbnail'>
			<img src={props.path} alt={props.name} />
			<div className="thumbnail-footer">
				<div className="thumbnail-label">
					<nobr>{props.name}</nobr>
				</div>
				<div className="thumbnail-buttons">
					<Popover 
						popoverClassName="bp3-popover-content-sizing"
						content={
							<ElementGroup caption="Copia link">
								<InputGroup
									className={`input-copy-${props.name}`}
									value={`${window.location.origin}${props.path}`}
									rightElement={
										<Button icon='duplicate' onClick={()=>{
												document.querySelector(`.input-copy-${props.name} .bp3-input`).select();
												document.execCommand("copy");
												showNotification("success", "Copia", "Link e' stato copiato")
											}} 
										/>
									}
								/>
							</ElementGroup>
						
					}>
						<Icon icon='link' />
					</Popover>
					<Popover 
						popoverClassName="bp3-popover-content-sizing"
						content={
							<>
								<ElementGroup caption={<>Cancellare il file <strong>{props.name}</strong> ?</>}>
									<Button 
										className="bp3-popover-dismiss"
										disabled={props.loading}
										intent={Intent.DANGER} 
										onClick={()=>{
											props.onDelete(props.path)}}
									>					
										Elimina
									</Button>
								</ElementGroup>
							</>
						} 
					>
						<Icon icon='trash'/>
					</Popover>
				</div>
			</div>
		
		</div>
	)
}


const MediaManager = (props) => {

	const [ media_path_list, setMediaPathList ] = useState([])
	const [ loading, setLoading ] = useState(true)
	const [ wait_delete, setWaitDelete ] = useState(false)

	const updateGallery = () => {
		api.getMedia().then((data) => {
			setMediaPathList(data)
		}).finally(()=>{
			setLoading(false)
		})
	}

	const onUpload = (form_data, progress_callback) => {
		return uploadMedia(form_data, progress_callback).then(() => {
			updateGallery();
		})
	}

	useEffect (() => {
		/* componentDidMount */
		updateGallery()
	}, [])

	return (
		<div className="area-media-manager">
			<div className='area-upload'>
				<ImageUploadArea
					onSubmit={()=>{}}
					allowed_extensions=".png, .gif, .jpg, .jpeg, .svg"
					onUploadComplete={()=>{}}
					uploadHandler={onUpload}
				/>
			</div>
			<div className='area-search'>
				
			</div>
			<div className='area-gallery'>
				{loading && <ProgressBar value={1} />}
				{media_path_list.map((e) => {
					return (
						<MediaThumbnail 
							{...e}
							loading = {wait_delete}
							onDelete = {((path) => {
									setWaitDelete(true)
									api.deleteMedia(path).then(() => {
										showNotification("info", "Eliminazione", `Il file ${path} e' stato cancellato`)
										updateGallery()
									}).catch((e) => {
										showNotification("danger", "Eliminazione", `Error durante cancellazione ${path} : ${e.hasOwnProperty("data") ? e.data.message : JSON.stringify(e)}`)
									}).finally(() => {
										setWaitDelete(false)
									})
								})
							}
						/>
					)
				})}
				
			</div>
		</div>
	)
}

export default MediaManager