
import React, { useState, useEffect } from 'react'
import {StringValue, TypeSelector, TextValue, DropdownMenu, NumericValue, PathValue, FormValue, ValueCheckbox } from '../components/Form.js'
import ElementGroup from '../components/ElementGroup.js'
import StartDate from "./components/StartDate.js"
import '../css/Form.scss'

import { Button, Intent } from '@blueprintjs/core';
import { getDeviceTypes, getDeviceTypesByDataloggerType } from '../api/wda_api.js'

// import ChannelsTable from './ChannelsTable.js'

import { store, Event } from '../redux.js'
import { getCurrentMapObjects } from '../actions.js'
import { RemoveButton, RemoveFromMapButton } from '../modules/Buttons.js'
import DataAlarmSwitch from './components/DataAlarmSwitch.js'


const FormDeviceConfig = (props) => {
	const [dropdown_options, setDropdownOptions] = useState([])
	const [dropdown_loading, setDropdownLoading] = useState(true)
	const [new_values, setNewValues] = useState(props.data)
	const [device_types, setDeviceTypes] = useState([])
	const [current_device_type, setCurrentDeviceType] = useState ({ id : props.data.type, has_path : true })
		
	const currentDeviceTypeToDropdownOption = () => {
		const options = device_types.filter((_type) => _type.id == current_device_type.id)
		if (options.length > 0)
			return { label : options[0].name, value : options[0] }
		return { label: "< predefinito >", value: { id: 0, has_path: true } }
	}
	
	const onChange = (field, value) => {
		if (typeof(field) === "object")  {
			setNewValues ({...new_values, ...field})
			props.onChange(field)
		}
		else {
			setNewValues ({...new_values, [field]: value})
			props.onChange(field, value)
		}
	}

	useEffect(() => {
		/* componentDidMount */
		if (props.data.datalogger === -1) {
			getDeviceTypesByDataloggerType(-1).then((data) => {
				setDeviceTypes(data)
			}).finally(()=> {
					setDropdownLoading(false)
				})
		}
		else {
			getDeviceTypes().then((data) => {
				// device types loaded
				setDeviceTypes(data)
			}).finally(()=> {
				setDropdownLoading (false)
			})
		}
	}, [])

	useEffect(() => {
		// get current device type as soon as the device_types list is ready
		const match_device = device_types.filter((dev_type) => dev_type.id === props.data.type)
		if (match_device.length > 0)
			setCurrentDeviceType(match_device[0])
	}, [device_types])

	const map_object_refs = getCurrentMapObjects()
	return (
		<div className='form'>
			<ElementGroup caption="Proprietà">
				<StringValue
					label="Nome"
					defaultValue={props.data.name}
					modified = {props.data.name !== new_values.name}
					onChange={(event)=> {
						onChange("name", event.target.value)
					}}
				/>

				{dropdown_loading ? <></> : 
					<>
						<TypeSelector
							label="Tipo"
							defaultValue={currentDeviceTypeToDropdownOption()}
							modified = {props.data.type !== new_values.type}
							onChange={(option)=> {
								setCurrentDeviceType(option.value)
								if (!option.value.has_path)
									onChange({
										import_path : "",
										archive_path : "",
										type: option.value.id
									})
								else 
									onChange("type", option.value.id)
							}}
							options={device_types.map((e) => {
								return {
									label: e.name,
									value: e,
									manufacturer: e.manufacturer
								}}
							)}
							isLoading={dropdown_loading}
						/>
						{ current_device_type.has_priority && 	
						<FormValue>
							<ValueCheckbox 
								label="Alta priorità" 
								defaultValue={props.data.priority}
								onChange={(e)=> {
									onChange("priority", e.target.checked)
								}}
							/>
						</FormValue>
						}
					</>
				}

				

				<NumericValue
					label="Numero dei canali"
					defaultValue={props.data.max_channels}
					min={1}
					max={1000}
					modified = {props.data.max_channels !== new_values.max_channels}
					placeholder="Numero massimo dei canali"
					onValueChange={(val)=> {
						onChange("max_channels", val)
					}}
				/>

				<StringValue
					label="Numero di serie"
					defaultValue={props.data.sn}
					placeholder="S/N, MAC, ecc"
					modified = {props.data.sn !== new_values.sn}
					onChange={(event)=> {
						onChange("sn", event.target.value)
					}}
				/>
				{ current_device_type.has_path && 
					<>
						<PathValue
							label="Path"
							defaultValue={props.data.import_path}
							modified = {props.data.import_path !== new_values.import_path}
							onChange={(value, real_path)=> {
								onChange({
									import_path: value,
									real_path: real_path
								})
							}}
						/>

						<PathValue
							label="Path Archivio"
							defaultValue={props.data.archive_path}
							modified = {props.data.archive_path !== new_values.archive_path}
							onChange={(value, real_path)=> {
								onChange("archive_path", value)
							}}
						/>
					</>
				}

				{ current_device_type.has_parameters && 
					<TextValue
						label="Parametri"
						defaultValue={props.data.parameters}
						modified = {props.data.parameters !== new_values.parameters}
						spellCheck={false}
						onChange = {(event) => {
							onChange("parameters", event.target.value)
						}}
					/>
				}

				<FormValue 
					label={<>Inizio <br/>d'importazione</>}
					modified= {props.data.last_import !== new_values.last_import}
				>
					<StartDate
						default_date={new Date(props.data.last_import * 1000)}
						onChange={(new_date) => {
							onChange("last_import", Math.floor(new_date.getTime() / 1000))
						}}
					/>
				</FormValue>

				{ current_device_type.has_last_index && 
					<NumericValue
						label="Ultimo indice"
						defaultValue={props.data.last_index}
						modified = {props.data.last_index !== new_values.last_index}
						min={0}
						onValueChange={(val)=> {
							onChange("last_index", val)
						}}
					/>
				}

				<DataAlarmSwitch  
					data={new_values.data_alarm}
					device_name={props.data.name}
					modified={props.data.data_alarm.hasOwnProperty("enabled") && (props.data.data_alarm.enabled != new_values.data_alarm.enabled) ||
							 (!props.data.data_alarm.hasOwnProperty("enabled") && new_values.data_alarm.enabled)}
					onChange={(new_data) => {
						onChange("data_alarm", new_data)
					}}
				/>

				<TextValue
					label="Descrizione"
					defaultValue={props.data.description}
					modified = {props.data.description !== new_values.description}
					onChange={(event)=> {
						onChange("description", event.target.value)
					}}
					
				/>
				
			</ElementGroup>

			<ElementGroup caption = "Grafana">
				<StringValue 
					label="URL" 
					defaultValue={props.data.grafana_url}
					onChange={(e)=> {
						onChange("grafana_url", e.target.value)
					}}
				/>
			</ElementGroup>

			<ElementGroup caption="Azioni">
				<Button 
					icon='tag'
					onClick={()=> {
						store.dispatch({
							type: Event.OPEN_DIALOG,
							modal_type: "create",
							modal_args: {object: "channel", parent_id: store.getState().details.data.id}
						})
					}}
				>	
					Aggiungi il canale
				</Button>&nbsp;
				<RemoveFromMapButton
					ready={true}
					map_object_refs={map_object_refs}
					type="device"
					target_id_list={[store.getState().details.data.id]}
				/>&nbsp;
				<RemoveButton
					ready={true}
					{...store.getState().details}
				/>
			</ElementGroup>
								
		</div>
	)
}


export default FormDeviceConfig