
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ModifyButton, RemoveButton } from '../../modules/Buttons.js'
import { MapMenu, getOwnMapObjects } from '../DetailsView.js'
import ShowOnGrafana from '../../components/ShowOnGrafana.js'
import { getVirtualChannelSummaryById } from '../../actions.js'


const VarsView = (props) => {
	return (
		<table className="table-virtual-channel-vars">
			<tr>
				<td>Nome</td>
				<td>Data</td>
				<td>Valore</td>
			</tr>
			{
				props.vars?.map((v) => {
					return (
						<tr>
							<td>{v.name}</td>
							<td>{(new Date(v.time)).toLocaleString()}</td>
							<td>{v.value.toFixed(2)}</td>
						</tr>
					)
				})
			}
		</table>
	)
}

const VirtualChannelStatView = (props) => {
	const toDtString = (timestamp) => {
		// <3 stackoverflow
		var date = new Date(timestamp * 1000);
		return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
	}
	return( 
		<>
			<table className="table-virtual-channel-summary">
				<tr>
					<td>Ultima<br/>importazione</td>
					<td>{(new Date(props.last_import)).toLocaleString()}</td>
				</tr>
				<tr>
					<td>Variabili</td>
					<td><VarsView vars={props.vars} /></td>
				</tr>
				<tr>
					<td>Formula</td>
					<td>{props.expression}</td>
				</tr>
				<tr>
					<td>Risultato</td>
					<td><b>{props.value?.toFixed(3)}</b></td>
				</tr>
			</table>
		</>
	)

}

const DetailsViewVirtualChannel = (props) => {
	const { 
		is_super, 
		is_admin, 
		is_editor, 
		details, 
		map_objects, 
		// virtual_channel_stats
	} = props
	const { data } = details
	const self_map_object_ids = getOwnMapObjects(map_objects, "vchannel", data.id).map((e) => e.id)

	const [stat_loading, setStatLoading ] = useState(true)
	const [stat_data, setStatData] = useState({success: false})

	useEffect(() => {
		setStatLoading(true)
		getVirtualChannelSummaryById(data.id).then((response) => {
			setStatData(response)
		}).finally(() => {
			setStatLoading(false)
		})
	}, [data.name])


	return (
		<>
			<div className='area area-details-content'>
				<h3>{data.name}</h3>
				<ShowOnGrafana {...props} />
				{data.description}<br/>
				{stat_loading && <strong>Caricamento...</strong>}
				<p>
					{
					
						stat_data.success ? 
							<VirtualChannelStatView {...{...data, ...stat_data}} /> :
							<>Nessun dato disponibile al momento</>

					}
				</p>

			</div>

			<div className='area area-details-summary'>
				<MapMenu {...props} map_object_refs={self_map_object_ids} /><br/>
			</div>
			
			<div className='area area-details-footer'>
				{(is_super || is_admin || is_editor) && <ModifyButton {...props} />}
				{(is_super || is_admin) && <RemoveButton {...props} />}
			</div>
		</>
	)
}

const mapStateToProps = ((store) => {
	return ({
		details: store.details
	})
})


export default connect(mapStateToProps)(DetailsViewVirtualChannel)