import React from "react"
import { Switch, Button } from '@blueprintjs/core'
import { useState } from "react"
import { FormValue } from "../../components/Form"
import { Event, store } from "../../redux.js"
import { useEffect } from "react"


const alert_properties_default = {
	"subject" : "",
	"message" : "",
	"sms_group" : "",
	"email" : [],
	"timeout" : 3600
}

const DataAlarmSwitch = (props) => {
	const {modified, device_name, onChange} = props
	const data = {...alert_properties_default, ...props.data}
	const [enable, setEnable] = useState(data.hasOwnProperty("enabled") && data.enabled)

	return (
		<FormValue
			label={<>Controllo frequenza di acquisizione</>}
			className="form-data-alarm-switch"
			modified= {modified}
		>
			<div>
				<Switch
					defaultChecked={enable}
					label="Abilita"
					onChange={(e) => {
						setEnable(e.target.checked)
						onChange({...data, enabled: e.target.checked})
					}}
				></Switch>
				<Button
					disabled={!enable}
					onClick={() => {
						store.dispatch({
							type: Event.OPEN_DATA_ALARM_CONFIG_MODAL,
							args: {
								device_name: device_name,
								data: data,
								onChange: (new_data) => {
									onChange({...data, ...new_data, enabled: true})
								}
							}
						})
					}}
				>
					Configura
				</Button>
			</div>
		</FormValue>
	)
}


export default DataAlarmSwitch