import React, { useState, useEffect } from 'react'

import { InputGroup } from '@blueprintjs/core'
import Select from 'react-select'

import { queryLocationList, setOptionsUpdateHandler } from '../api/geocoding.js'

import { NumericInput } from '@blueprintjs/core'
import { FormValue } from '../components/Form.js'

import "../css/AddressInputField.css"

export const CoordinatePicker = (props) => {
	const [ address_placeholder, setAddressPlaceholder ] = useState(props.placeholder)
	const [ ll, setLatLon ] = useState({lat: props.lat || "45.4668", lon: props.lon || "9.1905"})

	useEffect(() => {
		props.onChange({
			lat: parseFloat(ll.lat),
			lon: parseFloat(ll.lon)
		})
	}, [ll])

	return (
		<FormValue label={props.label} className={props.className}>
			<div className="address-picker-area">
				<div className="address-picker-address">
					<AddressInputField 
						placeholder={address_placeholder} 
						onChange={(option) => {
							setAddressPlaceholder(option.label)
							setLatLon({lat: option.value.lat, lon: option.value.lon})
						}}
					/>
				</div>
				<div className="address-picker-lat">
					<FormValue label="Lat" >
						<NumericInput
							value={ll.lat.toString()}
							buttonPosition="none"
							onValueChange={(_, s) => {
								setAddressPlaceholder(props.placeholder)
								setLatLon({...ll, lat: s})
							}}
						/>
					</FormValue>
				</div>
				<div className="address-picker-lon">
					<FormValue label="Lon" >
						<NumericInput			
							value={ll.lon.toString()}
							buttonPosition="none"
							onValueChange={(_, s) => {
								setAddressPlaceholder(props.placeholder)
								setLatLon({...ll, lon: s})
							}}
						/>
					</FormValue>
				</div>
			</div>
		</FormValue>
	)
}



const AddressInputField = (props) => {
	const [ address_str, setAddressStr ] = useState("")
	const [ address_options, setAddressOptions ] = useState([])
	const queryResultsToOptionList = (results) => {
		return results.map((e) => {
			return ({
				label: e.name,
				value: e
			})
		})
	}

	const addressOptionsUpdateHandler = (results) => {
		const _address_options = queryResultsToOptionList(results)
		setAddressOptions(_address_options)
	}
	
	useEffect (() => {
		// comonentDidMount
			setOptionsUpdateHandler(addressOptionsUpdateHandler)
		return () => {
			// componentWillUnmount
			setOptionsUpdateHandler(null)
		}
	}, [])

	return (
		<>
			<Select
				value={address_str}
				options={address_options}
				onInputChange={(s) => {
					setAddressStr(s)
					queryLocationList(s)
				}}
				filterOption={(e) => {return true}}
				{...props}
			/>
		</>
	)
}


export default AddressInputField
