
import { Classes, Dialog } from '@blueprintjs/core'
import React from 'react'
import { connect } from "react-redux"
import '../css/MediaManager.css'
import MediaManager from "../modules/MediaManager.js"
import { Event, store } from "../redux.js"

const MediaManagerModal = (props) => {
	return (
		<Dialog
			isOpen={props.show}
			onClose={() => {
				store.dispatch({type: Event.CLOSE_MEDIA_MANAGER})
			}}
			title="Gestore media"
			className="modal-media-manager"

		>
			<Classes.DIALOG_BODY>
				<MediaManager />
			</Classes.DIALOG_BODY>

		</Dialog>
	);

}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_media_manager
	})
}

export default connect(mapStoreToProps)(MediaManagerModal)