
import React, { useState, useEffect } from 'react'
import { TimePrecision } from '@blueprintjs/datetime'
import { DatePickerItaliano } from "../components/DateTimeInput.js"
import {StringValue, TextValue, FormValue, ValueCheckbox, DropdownMenu, GrafanaOrgSelector, InputGroup } from '../components/Form.js'
import Spoiler from '../components/Spoiler.js'
import ElementGroup from '../components/ElementGroup.js'
import { getGrafanaOrgsAdmin } from '../api/wda_api.js'
import { CoordinatePicker } from '../modules/AddressInputField.js'
import { store } from "../redux.js"

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '../css/Form.scss'
import { Slider, Tooltip } from '@blueprintjs/core'



const FormCantiereCreate = (props) => {

	const [ name, setName ] = useState("")
	const [ description, setDescription ] = useState("")
	const [ launch_date, setLaunchDate ] = useState(new Date())
	const [ create_grafana_org, setCreateGrafanaOrg ] = useState(true)
	const [ client, setClient ] = useState("")
	const [ grafana_url, setGrafanaUrl ] = useState("")
	const [ db_name_raw, setDbNameRaw ] = useState("")
	const [ db_name_live, setDbNameLive ] = useState("")
	const [ dropdown_options, setDropdownOptions ] = useState([])
	const [ dropdown_loading, setDropdownLoading ] = useState(true)
	const [ lat, setLat ] = useState(45.4668)
	const [ lon, setLon ] = useState(9.1905)

	const stringToDbName = (s : str) => {
		return s.toLowerCase().replace(/[^\n\w]+/g, "_");
	}


	useEffect(()=> {
		// componentDidMount
		getGrafanaOrgsAdmin().then((data) => {
			const present_cantieri = store.getState()['cantieri'].map((c) => c.name)
			setDropdownOptions(data.filter((e) => {
					// exclude already present cantieri
					return present_cantieri.indexOf(e.name) === -1

				}).map((e) => {
					return {
						label: e.name,
						value: e.id
					}
					
				}))

		}).finally(()=> {
			setDropdownLoading(false)
		})
	}, [])

	useEffect(() => {
		props.onChange("create_grafana_org", create_grafana_org)
	}, [create_grafana_org])
	
	return (
		<div className='form form-cantiere-create'>
			<GrafanaOrgSelector
				className="form-name"
				label="Nome del cantiere"
				placeholder="Nome"
				value={name}
				options={dropdown_options}
				onChange={(e) => {
					const val = e.target.value;
					props.onChange("name", val)
					setName(val)
					setDbNameRaw(`${stringToDbName(val)}_raw`)
					setDbNameLive(`${stringToDbName(val)}_live`)
				}}
				onListSelect={(e) => {
					setCreateGrafanaOrg(false)
					setGrafanaUrl(`${window.location.origin}/wda-dashboard/?orgId=${e.value}`)
				}}
			/>

			<StringValue
				label="Cliente"
				className="form-client-name"
				placeholder="Nome del cliente"
				defaultValue=""
				onChange={(event)=> {
					setClient(event.target.value)
					props.onChange("client", event.target.value)
				}}
			/>

			<TextValue
				label="Descrizione"
				className="form-description"
				placeholder="Descrizione"
				defaultValue=""
				onChange={(event)=> {
					setDescription(event.target.value)
					props.onChange("description", event.target.value)
				}}
			/>

			<CoordinatePicker label="Coordinate"
				className="form-location-picker"
				placeholder="Cerca indirizzo ..."
				onChange={(ll) => {
					setLat(ll.lat)
					setLon(ll.lon)
					props.onChange({
						gps_lat: parseFloat(ll.lat),
						gps_lon: parseFloat(ll.lon)
					})
				}}
			/>

			<div className="form-grafana-link">
				<FormValue label="Link Grafana">
					<InputGroup
						disabled={create_grafana_org}
						placeholder="https://websynthesis.it/wda-dashboard/..."
						value={grafana_url}
						onChange={(event) => {
							setGrafanaUrl(event.target.value)
							props.onChange("grafana_url", event.target.value)
						}}
					/>
					<ValueCheckbox
						label="Creare org. in Grafana"
						checked={create_grafana_org}
						onChange={(target)=> {
							setCreateGrafanaOrg(!create_grafana_org)
						}}
					/>
				</FormValue>
			</div>
			
			<Spoiler title={`Data d'inizio: ${launch_date.toLocaleString()}`}>
				<DatePickerItaliano
					highlightCurrentDay={true}
					defaultValue={launch_date}
					date={null}
					includeTime={true}
					timePrecision={TimePrecision.SECOND}
					minDate={new Date(0)}
					maxDate={new Date()}
					onChange={(new_date) => {
						setLaunchDate(new_date)
						props.onChange("start", Math.ceil(new_date.getTime()/1000));
					}}
					
				/>
			</Spoiler>

			<ElementGroup caption="Database InfluxDB">
				<FormValue label="Dati grezzi">
					<Tooltip content="Database per i dati a monte della conversione">
						<div>{db_name_raw}</div>
					</Tooltip>
				</FormValue>
				<FormValue label="Dati live"> 
					<Tooltip content="Database per i dati a valle della conversione">
						<div>{db_name_live}</div>
					</Tooltip>
				</FormValue>
			</ElementGroup>

		</div>
	);
}


export default FormCantiereCreate