
import React from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from '@blueprintjs/core'
import '../css/ControlPanel.css'
import ElementGroup from '../components/ElementGroup'
import { store, Event } from '../redux.js'

const dev_mode = process.env.NODE_ENV === 'development';
const JsonExportButton = (props) => {
	return (
		<Button
			icon="code"
			onClick={() => {
				const data_str = JSON.stringify(props.data, null, '\t');
				var new_tab = window.open("about:blank", 'json view', 'height=1000,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
				new_tab.document.write('<html><body><pre>' + data_str + '</pre></body></html>');
	
			}}
		>
			View JSON
		</Button>)
}


const ControlPanelView = (props) => {
	return (
		<>
			<div className="view view-debug">
				<div className="area area-control-panel">
					{/* <StateDebugPanel /> */ }
					<ElementGroup caption={<><Icon icon='shield'/>&nbsp;Strumenti</>}>

						{ dev_mode && 
							<p>
								<JsonExportButton data={props.data} />
							</p>
						}
						<p>
							<Button 
								icon='media'
								onClick={() => {
									store.dispatch({
										type : Event.OPEN_MEDIA_MANAGER
									})
								}}>
								Gestione media
							</Button>
						</p>
						<p>
							<Button 
								icon='database'
								onClick={() => {
									store.dispatch({
										type : Event.OPEN_INFLUXDB_MANAGER
									})
								}}
							>
								Gestore InfluxDB
							</Button>
						</p>

						<p>
						<Button 
							disabled={props.data.vchannels.length === 0}
							icon='function'
							onClick={() => {
								store.dispatch({
									type : Event.OPEN_VIRTUAL_CHANNEL_SUMMARY
								})
							}}>
							Stato dei canali virtuali
						</Button>
						</p>

						<p>
						<Button 
							icon='comment'
							onClick={() => {
								store.dispatch({
									type : Event.OPEN_FEEDBACK_MODAL,
									args : {}
								})
							}}>
							Segnala un errore
							</Button>
						</p>
					</ElementGroup>

				</div>
			</div>
		</>
		)
	}

const mapStoreToProps = (store) => {
	return ({
		data : store
	})
}

export default connect(mapStoreToProps)(ControlPanelView)