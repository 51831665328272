import React, { useState } from 'react'
import { Button, TextArea, Icon, ProgressBar, Tooltip } from '@blueprintjs/core'
import { TextValue, FormValue } from '../../components/Form.js'
import { checkFormulaString } from '../../api/wda_api.js'
import { getVirtualChannelTimePoint } from "../../api/wda_api.js"
import { store } from "../../redux.js"


const FormulaCheckResult = ({success, message}) => {
	return (
		<div class={`bp3-callout ${success ? "bp3-intent-success" : "bp3-intent-danger"}`}>
			<div class="bp3-heading">{message}</div>
		</div>
	)
}

const EvaluationError = ({message}) => {
	return (
		<div class="bp3-callout bp3-intent-danger">
			<div class="bp3-heading">{message}</div>
		</div>
	)
}

const EvaluationResult = (props) => {
	if (props.success) {
		return <EvaluationSummary {...props} />
	}
	return <EvaluationError {...props} />
}


const EvaluationSummary = ({vars, time, value}) => {
	var vars = [...vars]
	vars.sort((v1, v2) => v1.time - v2.time)
	const t0 = vars.length > 0 ? vars[0].time : 0
	return (
		<div className="area area-evaluation-summary">
			<table>
				<tr>
					<th colSpan={2}>Risultato</th>
				</tr>
				<tr>
					<td>Data</td>
					<td><strong>{(new Date(time*1000).toLocaleString())}</strong></td>
				</tr>
				<tr>
					<td>Valore</td>
					<td><strong>{value.toFixed(4)}</strong></td>
				</tr>
			</table>
			
			<table>
				<tr>
					<th>Variabile</th>
					<th>Valore</th>
					<th>Ritardo [ms]</th>
				</tr>
				{vars.map((v) => {
					return (
						<tr>
							<td>{v.name}</td>
							<td>{v.value}</td>
							<td>{v.time - t0}</td>
						</tr>
					)
				})}
			</table>
		</div>
	)

}


const FormulaInput = (props) => {
	const { zeroValueCallback, start_date_ts, timeout, expression, label, tooltip, onChange, db_name_raw } = props
	const [ formula_check_result, setFormulaCheckResult] = useState({complete: false, success: false, message: ""})
	const [ formula_string, setFormulaString ] = useState(props.defaultValue || "")

	const [summary, setSummary] = useState({ success: false, value: 0 })

	const [formula_check_loading, setFormulaCheckLoading ] = useState(false)
	const [eval_loading, setEvalLoading] = useState(false)
	const [eval_completed, setEvalCompleted] = useState(false)

	const getTimePoint = () => {
		setEvalLoading(true)
		setEvalCompleted(false)
		getVirtualChannelTimePoint(db_name_raw, start_date_ts, timeout, expression).then((data) => {
			setSummary(data)
		}).catch((e) => {
			setSummary({...summary, success: false})
		}).finally(() => {
			setEvalLoading(false)
			setEvalCompleted(true)
		})
	}

	
	const check = () => {
		setFormulaCheckLoading(true)
		checkFormulaString(formula_string).then((response) => {
			setFormulaCheckResult({...response, complete: true})
		}).catch((e) => {
			setFormulaCheckResult({...formula_check_result, success: false, complete: false})
		}).finally(() => {
			setFormulaCheckLoading(false)
		})
	}

	return (
		<>
			Formula:<br/>
			<Tooltip content={tooltip}>
				<TextArea
					{...props}
					className="formula-editor"
					onChange={(e) => {
						onChange(e)
						setFormulaString(e.target.value)
					}}
					fill={true}
					value={formula_string}
					onBlur={check}
					spellCheck={false}
				/>
			</Tooltip>
			<br/><br/>
			<Tooltip content="Verificare la sintassi">
				<Button 
					style={{width: "8em"}}
					disabled={formula_check_loading || eval_loading}
					icon='small-tick'
					onClick={check}
				>
					Verifica
				</Button>
			</Tooltip> &nbsp;
			<Tooltip content="Esegui il calcolo senza salvare il risultato">
				<Button
					style={{width: "8em"}}
					icon="calculator"
					disabled={formula_check_loading || eval_loading}
					onClick={() => {
						getTimePoint()
					}}
				>
					Test
				</Button>
			</Tooltip> &nbsp;
			<Tooltip 
				disabled={eval_loading || !summary.success}
				content="Considera il risultato di questo calcolo come il valore iniziale, da sottrarre da ogni risultato successivo"
			>
			<Button
					style={{width: "8em"}}
					icon="locate"
					disabled={eval_loading || !summary.success}
					onClick={() => {
						zeroValueCallback && zeroValueCallback(summary.value)
					}}
				>
					Azzera
				</Button>
			</Tooltip>
			<br/><br/>
			{formula_check_result.complete && <><br/><FormulaCheckResult {...formula_check_result}/></>}
			{eval_loading && <ProgressBar value={1} />}
			{!eval_loading && eval_completed && <EvaluationResult {...summary}/>}

		</>
	)
}

export default FormulaInput