
import React, { useState, useEffect } from 'react'
import { DatePickerItaliano } from "../components/DateTimeInput.js"
import { TimePrecision } from '@blueprintjs/datetime'
import {StringValue, TextValue, FormValue, SwitchValue } from '../components/Form.js'
import { CoordinatePicker } from '../modules/AddressInputField.js'
import Spoiler from '../components/Spoiler.js'
import ElementGroup from '../components/ElementGroup.js'
import { Button, Tooltip } from '@blueprintjs/core'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '../css/Form.scss'

import { store, Event } from '../redux.js'
import { getCurrentMapObjects } from '../actions.js'
import { RemoveButton, RemoveFromMapButton } from '../modules/Buttons.js'



const FormCantiereConfig = (props) => {
	
	const { onChange, data } = props

	const [ enabled, setEnable ] = useState(data.enabled)
	const [ name, setName ] = useState(data.name)
	const [ description, setDescription ] = useState(data.description)
	const [ launch_date, setLaunchDate ] = useState(data.launch_date)
	const [ db_name_raw, setDbNameRaw ] = useState(data.db_name_raw)
	const [ db_name_live, setDbNameLive ] = useState(data.db_name_live)
	const [ grafana_url, setGrafanaUrl ] = useState(data.grafana_url)
	const [ lat, setLat ] = useState(data.gps_lat)
	const [ lon, setLon ] = useState(data.gps_lon)

	const is_super = store.getState().current_user.role === "super"

	return (
		<div className='form'>
			<ElementGroup caption="Proprietà">

				<SwitchValue
					label="Abilitato" 
					checked={enabled}
					onChange={(e)=> {
						onChange("enabled", !enabled)
						setEnable(!enabled)
					}}
				/>

				<StringValue
					label="Nome"
					value={name}
					modified = {name !== data.name}
					onChange={(event)=> {
						setName (event.target.value)
						onChange("name", event.target.value)
					}}
				/>

				<TextValue
					label="Descrizione"
					value={description}
					modified = {description !== data.description}
					onChange={(event)=> {
						setDescription (event.target.value)
						onChange("description", event.target.value)
					}}
				/>


				<CoordinatePicker label="Coordinate"
					placeholder="Cerca indirizzo ..."
					lat={lat}
					lon={lon}
					modified={ lat !== data.gps_lat || lon !== data.gps_lon }
					onChange={(ll) => {
						setLat(ll.lat)
						setLon(ll.lon)
						onChange({
							gps_lat: parseFloat(ll.lat),
							gps_lon: parseFloat(ll.lon)
						})
					}}
				/>

				<ElementGroup caption="Grafana">
					<StringValue
						label="Link Grafana"
						value={grafana_url}
						modified = {grafana_url !== data.grafana_url }
						onChange={(event) => {
							setGrafanaUrl(event.target_value)
							onChange("grafana_url", event.target.value)
						}}
					/>
				</ElementGroup>
				
				<Spoiler
					title={data.launch_date.valueOf() === launch_date.valueOf() ? 
						`Data d'inizio : ${launch_date.toLocaleString()}` : 
						<b>{`Data d'inizio : ${launch_date.toLocaleString()} *`}</b>}
				>
					<DatePickerItaliano 
						highlightCurrentDay={true}
						value={launch_date}
						date={null}
						includeTime={true}
						timePrecision={TimePrecision.SECOND}
						minDate={new Date(0)}
						maxDate={new Date()}
						onChange={(new_date) => {
							setLaunchDate(new_date)
							onChange("start", Math.ceil(new_date.getTime()/1000));
						}}
					/>
				</Spoiler>

				<ElementGroup caption="Database InfluxDB" className="form-database">
					<FormValue label="Dati grezzi">
						<Tooltip content="Database per i dati a monte della conversione">
							<div>{db_name_raw}</div>
						</Tooltip>
					</FormValue>
					<FormValue label="Dati live"> 
						<Tooltip content="Database per i dati a valle della conversione">
							<div>{db_name_live}</div>
						</Tooltip>
					</FormValue>
				</ElementGroup>

				<ElementGroup caption="Azioni">
					<Button 
						icon='cell-tower'
						onClick={()=> {
							store.dispatch({
								type: Event.OPEN_DIALOG,
								modal_type: "create",
								modal_args: {object: "datalogger", parent_id: store.getState().details.data.id}
							})
						}}
					>	
						Aggiungi il datalogger
					</Button>&nbsp;
					{ is_super &&
						<RemoveButton
							ready={true}
							{...store.getState().details}
						/>
					}
				</ElementGroup>
			</ElementGroup>
		</div>
	)
}


export default FormCantiereConfig 