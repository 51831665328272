
import React, { useState, useEffect } from 'react'
import {DropdownMenu, TypeSelector, StringValue, TextValue, TimeValue, ValueCheckbox, FormValue, PathValue } from '../components/Form.js'
import '../css/Form.scss'
import { getDataloggerTypes } from '../api/wda_api.js'
import ElementGroup from '../components/ElementGroup.js'
import StartDate from "./components/StartDate.js"
import { store } from "../redux.js"

const FormDataloggerCreate = (props) => {
	const [dropdown_loading, setDropdownLoading] = useState(true)
	const [datalogger_types, setDataloggerTypes] = useState([])
	const [current_datalogger_type, setCurrentDataloggerType] = useState({id: props.type, has_path: true, has_priority: false})

	/* componentDidMount */
	useEffect(()=> {
		getDataloggerTypes().then((data) => {
			setDataloggerTypes(data)
		}).finally(()=> {
			setDropdownLoading(false)
		})
		props.onChange("last_import", store.getState().current_cantiere_data.start)
	}, [])


	return (
		<div className='form'>
			<StringValue
				label="Nome"
				defaultValue={props.name}
				placeholder="Nome del datalogger"
				onChange={(event)=> {
					props.onChange("name", event.target.value)
				}}	
			/>

			<TypeSelector
				label="Tipo"
				defaultValue={{label: "< nessuno >", value: props.type}}
				onChange={(option)=> {
					setCurrentDataloggerType(option.value)
					if (!option.value.has_path)
						props.onChange({
							type: option.value.id,
							import_path : "",
							archive_path: ""
						})
					else 
						props.onChange("type", option.value.id)
				}}
				options={datalogger_types.map((e) => {
					return {
						label: e.name,
						value: e,
						manufacturer: e.manufacturer
					}}
				)}
				isLoading={dropdown_loading}
			/>

			{ current_datalogger_type.has_priority && 	
				<FormValue>
					<ValueCheckbox 
						label="Alta priorità" 
						defaultValue={false}
						onChange={(e)=> {
							props.onChange("priority", e.target.checked)
						}}
					/>
				</FormValue>
			}

			<StringValue
				label="Numero di serie"
				defaultValue={props.sn}
				placeholder="S/N, MAC, ecc"
				onChange={(event)=> {
					props.onChange("sn", event.target.value)
				}}
			/>

			{ current_datalogger_type.has_path && 
				<>
					<PathValue
						label="Path"
						value={props.import_path}
						placeholder=""
						onChange={(value, real_path)=> {
							props.onChange({
								import_path: value,
								real_path: real_path
							})
						}}
					/>

					<PathValue
						label="Path Archivio"
						value={props.archive_path}
						placeholder=""
						onChange={(value, real_path)=> {
							props.onChange("archive_path", value)
						}}
					/>
				</>
			}

			<FormValue 
				label={<>Inizio <br/>d'importazione</>}
			>
				<StartDate
					onChange={(new_date) => {
						props.onChange("last_import", Math.floor(new_date.getTime() / 1000))
					}}
				/>
			</FormValue>

			<TimeValue
				label="Tempo allarme(s)"
				defaultValue={props.alarm_period}
				placeholder=""
				onChange={(value)=> {
					props.onChange("alarm_period", value)
				}}
			/>

			<TextValue
				label="Descrizione"
				defaultValue=""
				placeholder="Descrizione datalogger"
				onChange={(event)=> {
					props.onChange("description", event.target.value)
				}}
			/>

			<FormValue label="Pannello grafico">
				<ValueCheckbox 
					label="Crea il grafico" 
					defaultValue={false}
					onChange ={(e)=> {
						props.onChange("create_dashboard", e.target.checked)
					}}
				/>
			</FormValue>
		</div>	
	)
}

export default FormDataloggerCreate