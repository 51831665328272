import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { store } from './redux.js'
import App from './App';
import { Switch, Route, DefaultRoute, BrowserRouter as Router, Redirect } from "react-router-dom"
import * as serviceWorker from './serviceWorker';
import LandingPage from "./pages/LandingPage.js"
import { clearCookies } from "./actions.js"


const KickLogout = () => {
	clearCookies()
	document.location.href = "/"
	return <></>
}

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Switch>
				<Route path={/.+home\/*$/gm}>
					<LandingPage />
				</Route>
				<Route path={/.+logout\/*$/gm}>
					<KickLogout />
				</Route>
				<Route>
					<Provider store={store}>
						<App />
					</Provider>
				</Route>
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
