import React from 'react'

import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { store, Event } from '../redux.js'


export const MenuItem_NewDevice = (props) => {
	return (
		<MenuItem text="Nuovo acquisitore" icon={`${props.standalone ? "cube" : "inbox-update"}`} onClick={()=> {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create",
				modal_args: {object: props.standalone ? "device_standalone" : "device", parent_id: props.id}
			})
		}}/>
	)
}

export const MenuItem_NewChannel = (props) => {
	return (
		<MenuItem text="Nuovo canale" icon="tag" onClick={()=> {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create",
				modal_args: {object: "channel", parent_id: props.id}
			})
		}}/>
	)
}

export const MenuItem_NewDatalogger = (props) => {
	return (
		<MenuItem text="Nuovo datalogger" icon="cell-tower" onClick={()=> {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create",
				modal_args: {object: "datalogger", parent_id: props.id}
			})
		}}/>
	)
}

export const MenuItem_NewMap = (props) => {
	return (
		<MenuItem text="Nuova mappa" icon="map-create" onClick={() => {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create_map",
				modal_args: {}
			})
		}}/>
	)
}

export const MenuItem_NewVirtualChannel = (props) => {
	return (
		<MenuItem text="Nuovo canale virtuale" icon="function" onClick={()=> {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create",
				modal_args: {object: "vchannel"}
			})
		}}/>
	)
}

export const MenuItem_ImportConfig = (props) => {
	return (
		<MenuItem text="Importazione da JSON" icon="code" onClick={() => {
			store.dispatch({
				type: Event.OPEN_CONFIGURATION_IMPORT_MODAL,
				args: {}
			})
		}}/>
	)
}