
import React, { useEffect, useState } from 'react'
import { InputGroup, NumericInput, Switch, Icon } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import {StringValue, TextValue, FormValue, NumericValue, DropdownMenu, CheckboxValue } from '../components/Form.js'
import FormulaInput from './components/ForumulaInput.js'
import VirtualChannelStartDate from './components/VirtualChannelStartDate.js'
import '../css/Form.scss'
import { store } from '../redux.js'
import { TooltipContent } from '../modules/Tooltip.js'
import VirtualChannelZeroValue from './components/VirtualChannelZeroValue.js'


const FormVirtualChannelCreate = ({ onChange }) => {
	const [expression, setExpression] = useState("")
	const [channel_timeout, setChannelTimeout] = useState(30)
	const [enabled, setEnabled] = useState(true)
	const [last_import_ts, setLastImportTs] = useState(store.getState().current_cantiere_data.start)
	const [db_name_raw, setDbNameRaw] = useState(store.getState().current_cantiere_data.db_name_raw)
	const [db_name_live, setDbNameLive] = useState(store.getState().current_cantiere_data.db_name_live)
	const [compensation_value, setCompensationValue] = useState(0)

	const db_list = [
		store.getState().current_cantiere_data.db_name_raw ,
		store.getState().current_cantiere_data.db_name_live,
	]

	useEffect(() => {
		onChange("last_import", last_import_ts)
	}, [last_import_ts])

	useEffect(() => {
		onChange("expression", expression)
	}, [expression])

	useEffect(() => {
		onChange("channel_timeout", channel_timeout)
	}, [channel_timeout])

	useEffect(() => {
		onChange("db_name_raw", db_name_raw)
		onChange("db_name_live", db_name_live)
	}, [db_name_raw, db_name_live])

	return (
		<div className='form form-vchannel'>
			<ElementGroup caption={<><Icon icon='edit'/> Proprietà</>} className="form-b1-left">
				<FormValue label="Abilitato">
					<Switch
						checked={enabled}
						onChange={(event) => {
							setEnabled(event.target.checked)
							onChange("enabled", event.target.checked)
						}}
					/>
				</FormValue>
				<StringValue
					tooltip={TooltipContent.VIRTUAL_CHANNEL_NAME}
					label="Nome"
					placeholder="Nome del canale virtuale"
					onChange={(event)=> {
						onChange("name", event.target.value)
					}}
				/>
				<StringValue
					tooltip={TooltipContent.VIRTUAL_CHANNEL_GROUP}
					label="Gruppo"
					onChange={(event)=> {
						onChange("group", event.target.value)
					}}
				/>
			</ElementGroup>
			<ElementGroup caption={<><Icon icon='database'/> Database</>} className="form-b1-right">
				<DropdownMenu
					label="Sorgente (raw)"
					value={{label: db_name_raw, value: db_name_raw}}
					options={db_list.map((e) => ({label: e, value: e}))}
					isSearchable={false}
					onChange={(e) => (setDbNameRaw(e.value))}
				/>
				<DropdownMenu
					label="Destinazione (live)"
					value={{label: db_name_live, value: db_name_live}}
					options={db_list.map((e) => ({label: e, value: e}))}
					isSearchable={false}
					onChange={(e) => (setDbNameLive(e.value))}
				/>
			</ElementGroup>
			<ElementGroup caption={<><Icon icon="function"/> Calcolo</>} className="form-b2-mid">
				<NumericValue
					tooltip={TooltipContent.VIRTUAL_CHANNEL_CHANNEL_TIMEOUT}
					label="Timeout dei canali (s)"
					value={channel_timeout}
					stepSize={10}
					onValueChange={(v) => {
						setChannelTimeout(v)
					}}
				/>
				<FormValue label="Data d'inizio">
				<VirtualChannelStartDate
					new_channel
					start_date_ts={last_import_ts}
					onChange ={(new_date) => {
						const ts = Math.floor(new_date.getTime()/1000)
						setLastImportTs(ts)
					}}
				/>
				</FormValue>
				<VirtualChannelZeroValue 
					expression={expression}
					start_date_ts={last_import_ts}
					timeout={channel_timeout}
					defaultValue={0}
					compensation_value={compensation_value}
					setCompensationValue={setCompensationValue}
					onChangeValue = {(v) => {
						onChange("zero_value", v)
					}}
				/>
				<FormulaInput
					db_name_raw={db_name_raw}
					tooltip={TooltipContent.VIRTUAL_CHANNEL_FORMULA}
					start_date_ts={last_import_ts}
					timeout={channel_timeout}
					expression={expression}
					onChange={(e) => {
						setExpression(e.target.value)
					}}
					zeroValueCallback={setCompensationValue}
				/>
			</ElementGroup>

			<ElementGroup caption={<><Icon icon='annotation'/> Altro</>} className="form-b3-mid">
				<TextValue
					label="Nota"
					placeholder=""
					onChange={(e) => {
						onChange("note", e.target.value)
					}}
				/>
				<StringValue 
					label="URL Grafana" 
					onChange={(e)=> {
						onChange("grafana_url", e.target.value)
					}}
				/>
			</ElementGroup>
		</div>
	);	
}


export default FormVirtualChannelCreate