
import React from 'react'
import { MenuItem, Button, Tooltip, Menu, HTMLSelect, ControlGroup, Icon, Position, Popover, MenuDivider } from "@blueprintjs/core"
import DropdownSelect from "../components/DropdownSelect.js"
import { store, Event } from "../redux.js"
import { connect } from "react-redux"
import { selectMap, openModalNewMap } from "../actions.js"

const DropdownListElement = (props) => {
	return (
		<div>
			{props.current ? <b>{props.name}</b> : props.name}
		</div>
	);
}

const openModalModifyMap = () => {
	// TODO: implement
}

const openModalDeleteMap = (id, name) => {
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type: "remove_map",
		modal_args: {id: id, name: name}
	})
}

const NewMapListElement = (props) => {
	return (
		<div onClick={openModalNewMap} >
			<Icon icon='plus' />&nbsp; Nuova mappa
		</div>
	)
}

const getMapIcon = (m) => {
	if (m.id === null)
		return <Icon icon="layer" />
	else if (m.world)
		return <Icon icon="globe-network" />
	return <Icon icon="map" />
}


const MapSelector = (props) => {
	const { user } = props
	const mapToList = (m) => {
		const icon = getMapIcon(m)
		if (m) {
			if (m.id === null) {
				return ({
					value: null,
					label: <div className='map-selector-item map-selector-item-selected'> {icon} &nbsp; Seleziona una mappa ... </div>
				})
			}
			else if (m.id === props.current_map.id )
				return ({
					value: m.id,
					label: <>
						<div className='map-selector-item map-selector-item-selected'> {icon} &nbsp; {m.name} </div>
					</>
				})
			return ({
				value: m.id,
				label: <div className='map-selector-item'>{icon} &nbsp; {m.name}</div>
			})
		}
		return null;
	}

	const maps = props.maps.map((m) => {
		return mapToList(m)
	})

	const current_map = maps[0]; // TODO: read current map from state

	return (
		<>
		{!!props.cantiere_exists && 
			<>
				<DropdownSelect
					className='map-selector'
					options={[...maps, {value: -1, label: <NewMapListElement />}]}
					value={mapToList(props.current_map)}
					onChange={(m)=> {
						if (m.value !== -1) 
							selectMap(m.value); // map ID
					}}
				>
				</DropdownSelect>
				<div className='map-selector-menu'>
					{user.role === "super" &&
						<Popover
							content={
								<Menu>
									<MenuItem icon='plus' text='Nuova mappa' onClick={openModalNewMap} />
									{ props.current_map.id &&
										<>
										<MenuDivider/>
										<MenuItem icon='cross' text='Elimina' onClick={() => {openModalDeleteMap(props.current_map.id, props.current_map.name)}} />
										</>
									}	
									
								</Menu>
							}
							position={Position.BOTTOM_LEFT}
						>
							<Button icon='cog' />
						</Popover>
					}
				</div>
			</>
		}
		</>
	)
}

const mapStoreToProps = (store) => {
	return ({
		user: store.current_user,
		maps : store.maps,
		current_map : store.current_map,
		cantiere_exists : store.current_cantiere_id
	})
}

export default connect (mapStoreToProps)(MapSelector)