
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Popover, Menu, MenuItem, ContextMenu } from '@blueprintjs/core'
// import sample_map from '../assets/map1_4x.png'
import { connect } from 'react-redux'
import { selectMap, deleteMapObject } from '../actions.js'
import { store, Event } from '../redux.js'

import { renderLabel, renderTooltip, hidePopup, markerContextMenuHandler, markerClickHandler, MarkersLayer } from "./map/map_marker.js"
import { showNotification } from './Toast.js'

const L = require('leaflet')

const fn_stub = () => {}

const LocalMap = (props) => {
	var image = null
	var map = null
	var map_def = null
	var markers_layer = null
	var ratio = null
	var unsubscribe : function = fn_stub
	var _unsubscribe_cu : function = fn_stub // one time subscribe callback
	var _map = null

	class MapDef {
		constructor (map_json) {
			const img_width = map_json.width
			const img_height = map_json.height

			this.min_side = Math.min(img_width, img_height)
			this.max_side = Math.max(img_width, img_height)

			this.ratio = this.max_side / this.min_side
			this.map_ratio = [100 / this.ratio, 100]

			this.bounds = [[0, 0], this.map_ratio]

			this.absToRel = (x, y) => {
				return [this.map_ratio[0] * y / 100, this.map_ratio[1] * x / 100];
			}
	
			this.relToAbs = (x, y) => {
				return [100 / this.map_ratio[0] * y, 100 / this.map_ratio[1] * x]
			}

			this.insideMapBounds = (x, y) => {
				if (x < 0 || x > 100 || y < 0 || y > 100) { // outside
					showNotification("warning", "Fuori dalla mappa", "Si prega di posizionare l'oggetto dentro la mappa")
					return false
				}
				return true
			}
		}
	}

	const createLocalMap = (map_json) => {
		const img_url = document.location + "/upload/" + map_json.image;
		map_def = new MapDef(map_json)
		_map = L.map('raster-map-view', {
			crs: L.CRS.Simple,
			maxZoom: 10,
			minZoom: 3,
			zoomSnap: 0,
			zoomDelta: 0.5
		}) // setView 
		
		const image = L.imageOverlay(img_url, map_def.bounds).addTo(_map);
		_map.setMaxBounds(new L.LatLngBounds([-map_def.max_side / 1.2, -map_def.min_side / 1.2], [map_def.max_side / 1.2, map_def.max_side / 1.2])) // TODO: fix bounds
		_map.fitBounds(map_def.bounds);
		_map.on("click", (e) => {
			hidePopup();
		})
		markers_layer = new MarkersLayer (_map, map_def);

		store.dispatch({ 
			type: Event.SET_SHOW_ON_MAP_HANDLER,
			handler : (map_object_id) => {
				if (markers_layer.marker_by_map_object_id.hasOwnProperty(map_object_id))
					_map.setView(markers_layer.marker_by_map_object_id[map_object_id].getLatLng(), 5)
				else
					console.error("No such map object ID %d in %o", map_object_id, markers_layer.marker_by_map_object_id)
			}
		})
	}

	const destroyMap = () => {
		if (_map != null) {
			store.dispatch({
				type: Event.SET_SHOW_ON_MAP_HANDLER,
				handler: (o) => {} // dummy
			})
			_map.off()
			_map.remove()
			_map = null
			map_def = null
			markers_layer = null
		}
	}

	// componentDidMount
	useEffect(() => {
		console.debug("[LocalMap] mounted")
		createLocalMap(store.getState().current_map);
		unsubscribe = store.subscribe(() => {
			const state = store.getState()
			if (state.last_event === Event.MAP_RENDER) {
				if (state.current_map.world)
					return; // skip world map

				console.debug("[LocalMap] render triggered")
				destroyMap();
				createLocalMap(state.current_map);
			}

			else if (state.last_event === Event.MAP_UPDATE_MARKERS) {
				if (state.cantiere_ready)
					markers_layer.updateMarkers()
				else
					// marker update is triggered before the cantiere was loaded
					// so update them as soon as the cantiere gets loaded, using one time subscribe
					_unsubscribe_cu = store.subscribe(() => {
						console.debug("[LocalMap] Deferred map objects update");
						markers_layer.updateMarkers()
						_unsubscribe_cu();
					})
			}
		})

		// Drag & Drop
		const view = document.getElementById('raster-map-view');
		view.ondragover = (e) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = 'move';
		}
		view.ondrop = (e) => {
			e.preventDefault();
			setTimeout(() => {
				const obj = store.getState().drag_object;
				console.debug("Place object on map: %o", obj)
				if (obj.target_type !== "")
					markers_layer.addDndMarker(obj, e.clientX - view.offsetLeft, e.clientY - view.offsetTop);
				store.dispatch({
					type : Event.DROP_ON_MAP // reset DND object
				})
			}, 100); // WORKAROUND: prevent 'ondrop' event before redux state update (Event.DROP_ON_MAP: target_type, target_id)
		}

		// componentWillUnmount
		return () => {
			unsubscribe();
			destroyMap();
			console.debug("[LocalMap] destroyed")
		}
	}, [])
	

	return (
		<div className='raster-map-view' id='raster-map-view'>

		</div>
	)

}

export default LocalMap