
import { Classes, Dialog } from '@blueprintjs/core';
import React from 'react';
import { connect } from "react-redux";
import '../css/InfluxDBClient.scss';
import InfluxDBClient from "../modules/InfluxDBClient.js";
import { Event, store } from "../redux.js";

const InfluxManagerModal = (props) => {
	const priority_database_list = props.cantiere_data.hasOwnProperty("db_name_live") ? [props.cantiere_data.db_name_raw, props.cantiere_data.db_name_live] : []
	return (
		<Dialog
			isOpen={true}
			onClose={() => {
				store.dispatch({type: Event.CLOSE_INFLUXDB_MANAGER})
			}}
			title="Gestore InfluxDB"
			className="modal-influx-manager"

		>
			<Classes.DIALOG_BODY>
				<InfluxDBClient 
					priority_database_list={priority_database_list}
				/>
			</Classes.DIALOG_BODY>

		</Dialog>
	);

}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_influxdb_manager,
		cantiere_data : store.current_cantiere_data
	})
}

export default connect(mapStoreToProps)(InfluxManagerModal)