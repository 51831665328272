
import { getData2, putData } from './requests.js'

export const listDir = (cantiere_id, path = "") => {
	return getData2("/api/filemanager/list", {
		cantiere_id : cantiere_id,
		path : path
	})
}

export const createDir = (cantiere_id, path, dir_name) => {
	return putData("/api/filemanager/dir", {
		cantiere_id : cantiere_id,
		path: path,
		name: dir_name
	})
}

export const readFile = (path) => {
	return getData2("/api/filemanager/read", {
		path: path
	})
}