
import React, { useEffect, useState } from 'react'
import { InputGroup, NumericInput, Switch, Icon, TagInput } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import TimeIntervalInput from './components/TimeIntervalInput.js'
import {StringValue, TextValue, FormValue, NumericValue, DropdownMenu } from '../components/Form.js'
import '../css/Form.scss'

import {store, Event} from "../redux.js"


const FormDataAlarmConfig = (props) => {
	const { data } = props
	const [new_values, setNewValues] = useState(data)
	const [mail_list, setMailList] = useState(data.email)

	const onChange = (field, value) => {
		setNewValues({...new_values, [field]: value})
		props.onChange(field, value)
	}

	return (
		<div className='form form-data-alarm'>
			<ElementGroup caption="Controllo frequenza di acquisizione">
				<TimeIntervalInput
					label="Frequenza"
					className="form-field-frequency"
					defaultValue={data.timeout}
					onChange={(raw_seconds) => {
						onChange("timeout", raw_seconds)
					}}
				/>

				<FormValue
					vertical
					label="In caso di mancaza acquisizione nell'intervallo di tempo sopra indicato, inviare mail a:"
				>
					<br/>
					<TagInput
						leftIcon="envelope"
						onChange={(email_array) => {
							setMailList(email_array)
							onChange("email", email_array)
						}}
						placeholder="admin@websynthesis.it"
						values={mail_list}
						addOnBlur={true}
					/>

				</FormValue>

				<StringValue
					label="ID del gruppo SMS"
					defaultValue={data.sms_group}
					placeholder="facoltativo"
					modified={data.subject !== new_values.subject}
					onChange={(event) => {
						onChange("sms_group", event.target.value)
					}}
				/>

				<StringValue
					label="Oggetto"
					defaultValue={data.subject}
					modified={data.subject !== new_values.subject}
					onChange={(event) => {
						onChange("subject", event.target.value)
					}}
				/>

				<TextValue
					vertical
					label="Messaggio"
					defaultValue={data.message}
					placeholder="Messaggio"
					modified = {data.message !== new_values.message}
					onChange={(event)=> {
						onChange("message", event.target.value)
					}}
				/>
			</ElementGroup>
		</div>
	);
}


export default FormDataAlarmConfig