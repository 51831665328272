import React from 'react'
import { connect } from 'react-redux'
import { store, Event } from '../redux.js'
import { Button, Intent, Icon, Tooltip } from '@blueprintjs/core'
import { RemoveFromMapButton, ShowOnMapButton } from '../modules/Buttons.js'
import { addToCurrentMap, getMapObjectIdListByObject, selectCantiere, getCantiereGrafanaUrl, isCorrectUrl } from '../actions.js'
import "../css/DetailsView.scss"
import ElementGroup from '../components/ElementGroup.js'
import WdaLogo from '../assets/wda_logo.png'
import DetailsViewDatalogger from "./Details/Datalogger.js"
import DetailsViewCantiere from "./Details/Cantiere.js"
import DetailsViewDevice from "./Details/Device.js"
import DetailsViewChannel from "./Details/Channel.js"
import DetailsViewVirtualChannel from "./Details/VirtualChannel.js"

const getOwnMapObjects = (map_objects, self_type, self_id) => {
	return map_objects.filter((mo) => (mo.target_type === self_type && mo.target_id === self_id))
}


const DetailsHeader = (props) => {
	const { user } = props
	const cantiere_grafana_url = getCantiereGrafanaUrl(props.current_cantiere_id);
	return (
		<div className="area area-details-header">
			<h3><Icon icon="office" /> {props.current_cantiere} </h3>
			{ isCorrectUrl(cantiere_grafana_url) && 
				<a href={cantiere_grafana_url} target="_blank"><Icon icon="timeline-line-chart" /> Apri il grafico</a>
			}
			
			{user.role === "super" &&
				<a href="" onClick={(e) => {
						e.preventDefault()
						selectCantiere(props.current_cantiere).then(() => {
							store.dispatch({
								type: Event.OPEN_DIALOG,
								modal_type : "config",
								modal_args : {object: props.type, id : props.data.id}
							})
						})
					}}
				>
					<Icon icon="edit" /> Modifica
				</a>
			}
		</div>
	)
}


const MapMenu = (props) => {
	const { user, is_super, is_admin, is_editor } = props
	const n_objects = props.map_object_refs.length
	const content = (n_objects > 0) ?
		<>
			<ShowOnMapButton {...props} />
			{(is_super || is_editor) && <RemoveFromMapButton {...props} />}
		</> :
		<>Trascinare un elemento dall'albero sinistra per aggiungerlo sulla mappa</>
	return (
		<ElementGroup 
			className="area-details-map-info"
			caption={<><Icon icon='map'/>&nbsp;&nbsp;{n_objects <= 1 ? `Mappa` : `Mappa (${n_objects} elementi)`}</>}>
			{content}
		</ElementGroup>
	)
}


const ChannelTable = (props) => {
	const labels = ["C", "X", "X²", "X³", "X4", "X5", "OFFSET", "GAIN"]
	const values = ["x0", "x1", "x2", "x3", "x4", "x5", "offset", "gain"].map((e) => {
		return (
			<td>{props[e]}</td>
		)
	})

	return (
		<>
			<table>
				{labels.map((label, i) => {
					return (
						<tr>
							<td>{label}</td>
							<td>{values[i]}</td>
						</tr>
					)
				})}	
			</table>
		</>
	)
}


const DetailsView = (props) => {
	const content = () => {
		if (props.current_cantiere_id !== 0) {
			if (!props.type)
				return (
					<>
						<DetailsHeader {...props}/>
						<div class="area area-details-content">
							Clicca un elemento per visualizzare informazioni
						</div>
					</>
				)
			return (
				<>
					<DetailsHeader {...props}/>
					{props.type === 'datalogger' && <DetailsViewDatalogger {...props} />}
					{props.type === 'device' && <DetailsViewDevice  {...props} />}
					{props.type === 'channel' && <DetailsViewChannel  {...props}  />}
					{props.type === 'cantiere' && <DetailsViewCantiere  {...props} />}
					{props.type === 'vchannel' && <DetailsViewVirtualChannel {...props} />}
				</>
			)
		}
		return (
			<div>
				<img src={WdaLogo} />
			</div>
		)
	}

	return (
		<div className="view view-details">
			{content()}
		</div>
	
	)
}

const mapStoreToProps = (store) => {
	return ({
		user : store.current_user,
		type : store.details.type,
		data : store.details.data,
		ready : store.cantiere_ready,
		maps: store.maps,
		map_objects : store.map_objects,
		current_cantiere_id : store.current_cantiere_id,
		current_cantiere : store.current_cantiere,
		is_super: store.current_user && store.current_user.role === "super",
		is_admin: store.current_user && Object.keys(store.current_user.member_of).includes(store.current_cantiere) && store.current_user.member_of[store.current_cantiere] === "Admin",
		is_editor: store.current_user && Object.keys(store.current_user.member_of).includes(store.current_cantiere) && store.current_user.member_of[store.current_cantiere] === "Editor"
	})
}


export default connect(mapStoreToProps)(DetailsView);

export { MapMenu, getOwnMapObjects }