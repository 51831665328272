
import React from 'react'
import { toggleMapObjectLabels } from '../actions.js'
import { connect } from 'react-redux'
import { Switch } from '@blueprintjs/core'

const MapLabelSwitch = (props) => {
	return (
		<Switch
			checked={props.on}
			onChange={(e)=> { toggleMapObjectLabels() }}
		>
			Visualizza i nomi
		</Switch>
	)
}

const mapStoreToProps = (store) => {
	return ({
		on : store.show_map_object_labels,
	})
}

export default connect(mapStoreToProps)(MapLabelSwitch)