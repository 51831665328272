import React, { useState, useEffect} from "react"
import "../css/LandingPage.scss"
import image_grafana from "../assets/image_grafana.jpg"
import image_backend from "../assets/image_backend.jpg"
import image_reports from "../assets/image_reports.jpg"
import { getCurrentUser2 } from "../api/login_api.js"
import LoginPage from "./LoginPage"
import { clearCookies } from "../actions"

const gotoPage = (path) => {
	const base_url = window.location.origin
	window.location.href = `${base_url}/${path}`
}

const MenuElement = (props) => {
	const { image, title, onClick } = props
	return (
		<div 
			className="menu-element"
			onClick={onClick}
		>
			<img src={image}/>
			<div className="title">
				{title}
			</div>
		</div>
	)
}

const LandingPage = () => {
	const [ current_user, setCurrentUser ] = useState(null)
	const [ current_user_info, setCurrentUserInfo ] = useState(null)


	const getUserInfo = () => {
		return getCurrentUser2().then((user_info) => {
			setCurrentUser(user_info.name)
			setCurrentUserInfo(user_info)
		}).catch((e) => {
			setCurrentUser(null)
			clearCookies()
		})
	}

	useEffect(getUserInfo, [])

	if (current_user_info != null) {
		const is_empty = Object.values(current_user_info.member_of).length === 0;
		const viewer_only = Object.values(current_user_info.member_of).reduce((previous, current) => previous && (current === "Viewer"), true);
		if ((current_user_info.role === "super" && is_empty) || !viewer_only) {
			// Has at least 1 cantiere where role is > "Viewer"
			return (
				<div className="page landing-page">
					<div className="content">
						<MenuElement
							title="Report"
							image={image_reports}
							onClick={() => {
								gotoPage("/wda-dashboard/report")		
							}}
						/>
						<MenuElement
							title="Grafici"
							image={image_grafana}
							onClick={() => {
								gotoPage("/wda-dashboard")
							}}
						/>
						<MenuElement
							title="Amministrazione"
							image={image_backend}
							onClick={() => {
								gotoPage("/wda-dashboard/v1")
							}}
						/>
					</div>
					Benvenuto {current_user}
				</div>	
			)
		}
		else {
			// viewer only or non-admin with empty list
			console.log("Redirecting to Grafana.")
			gotoPage("/wda-dashboard")
			return <></>
		}
	}
	// not logged in
	return (
		<LoginPage
			onLogin={(username) => {
				setCurrentUser(username)
				getUserInfo()
			}}
		/>
	)
}


export default LandingPage