
import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { Dialog, Button, Classes, Intent } from '@blueprintjs/core'
import { Event, store } from '../redux.js'
import FormDataAlarmConfig from "../forms/FormDataAlarmConfig.js"



const DataAlarmModal = (props) => {
	const { show, args } = props
	const { data, device_name, onChange = ()=>{} } = args
	const [form_data, setFormData] = useState(data)
	const close = () => {
		store.dispatch({type: Event.CLOSE_DATA_ALARM_CONFIG_MODAL});
	}

	useEffect(() => {
		setFormData({...form_data, ...data})
	}, [args])

	return (
		<>
			{show && <Dialog
				isOpen={show}
				portalClassName="modal-on-top"
				isOpen={true}
				onClose={() => {
					store.dispatch({type: Event.CLOSE_DATA_ALARM_CONFIG_MODAL})
				}}
				title={`Allarme dati ${device_name}`}
				className="modal-data-alarm-config"
			>
				<Classes.DIALOG_BODY>
					<FormDataAlarmConfig
						data = {form_data}
						onChange={(key, value) => {
							setFormData({...form_data, [key] : value})
						}}
					/>
					
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={() => {
							onChange(form_data)
							close()
						}}
					>
						OK
					</Button>
					<Button 
						onClick={close}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

				</Dialog>
			}
	</>
	)
}


const mapStateToProps = (state) => {
	return (state.modal_data_alarm_config)
}


export default connect(mapStateToProps)(DataAlarmModal)
