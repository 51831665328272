import React from 'react'

import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom'
import sample_icon from "leaflet/dist/images/marker-icon-2x.png"

import { Menu, MenuItem, ContextMenu } from '@blueprintjs/core'
import { deleteMapObject, selectMap, getObjectByTypeAndId, moveMapObject, updateMapObjects, saveDroppedMapObject, showOnMap} from '../../actions.js'
import { store, Event } from "../../redux.js"
import { showNotification } from '../Toast.js'


const L = require('leaflet')


const MarkerContextMenu = (props) => {
	return (
		<Menu>
			<MenuItem text="Modifica..." icon="annotation" onClick={()=>{
				store.dispatch({
					type: Event.OPEN_MAP_OBJECT_EDITOR
				})
			}} />
			<MenuItem text="Elimina" icon="cross" onClick={()=>{
				deleteMapObject(store.getState().selected_map_object.id)
			}} />
		</Menu>
	)
}


export const renderTooltip = ((map_object, target_data) => {
	return ReactDOMServer.renderToString(
		<div>
			<b>{map_object.label}</b><br/>
			{target_data.name}<br/>
			<b>{}</b>
			{map_object.target_type}<br/><br/>
			<div>
				{map_object.note}
			</div>
		</div>
	)
})


export const renderLabel = ((text) => {
	const content = ReactDOMServer.renderToString(
		<div>
			<b>{text}</b><br/>
		</div>
	)
	return L.tooltip({permanent: true }).setContent(content);
})


export const markerClickHandler = (marker, map_object, target_data) => {
	const type = map_object.target_type;
	const id = map_object.target_id

	if (type === "map") {
		selectMap(id)
		return
	}
	else if (type === "note") {
		// nothing
	}
	else {
		store.dispatch(({
			type: Event.SHOW_DETAILS,
			object: type,
			data: target_data
		}))
	}
	hidePopup();
	L.DomUtil.addClass(marker._icon, "map-marker-active")
	store.dispatch({
		type: Event.SELECT_MAP_OBJECT,
		object: map_object
	})
}


export const hidePopup = () => {
	const elements = document.getElementsByClassName("map-marker-active")
	for (var i = 0; i < elements.length; i++) {
		elements[i].classList.remove("map-marker-active")
	}
	if (store.getState().selected_map_object_id)
		store.dispatch({
			type: Event.SELECT_MAP_OBJECT,
			object : {}
		})
}


export const markerContextMenuHandler = (e, map_object, target_data) => {
	e.originalEvent.preventDefault()
	
	if (map_object.target_type === "map") {
		store.dispatch({
			type: Event.SELECT_MAP_OBJECT,
			object: map_object
		})	
	}
	else
		markerClickHandler(e.target, map_object, target_data)
	if (map_object.id !== -1)
		ContextMenu.show(<MarkerContextMenu/>, {left: e.originalEvent.clientX, top: e.originalEvent.clientY }, () => {})
}



export class MarkersLayer {
	constructor(_map : L.map, map_def : MapDef) {
		this.map = _map
		this.map_def = map_def
		this.layer = L.layerGroup().addTo(this.map)
		this.marker_by_map_object_id = {}

		this.updateMarkers = () => {
			console.debug("[Map] Update markers triggered")
			const state = store.getState()
			if (this.map == null)
				return
			this.layer.clearLayers()
			state.current_map_objects.forEach((map_object) => {
				this.addMarker(map_object, map_object.id !== -1, state.show_map_object_labels)
			});
			
		}

		this._newMarker = (map_object, coords, draggable, show_label) => {
			const x = map_object.x;
			const y = map_object.y;
			const size_x = 45 * map_object.scale;
			const size_y = 45 * map_object.scale;
			var anchor_x = size_x / 2;
			var anchor_y = size_y;

			const anchor_match = (/\{(?<x>\d{1,3}),(?<y>\d{1,3})\}\./g).exec(map_object.icon)
			if (anchor_match) {
				anchor_x = anchor_match.groups.x / 100 * size_x
				anchor_y = anchor_match.groups.y / 100 * size_y
			}


			const icon = L.icon({
				iconUrl: `${window.location.href}/upload/icons/${map_object.icon ? map_object.icon : sample_icon}`,
				className: `marker-id-${map_object.id}`,
				iconSize: [size_x, size_y],
				iconAnchor: [anchor_x, anchor_y]
			});
	
			// get target refernce information 
			const target_data = getObjectByTypeAndId(map_object.target_type, map_object.target_id)
			if (target_data === null) {
				console.error(`Invalid reference for map object ID ${map_object.id} : type = ${map_object.target_type}, id = ${map_object.target_id}`)
				return
			}

			const tooltip = show_label ? renderLabel(map_object.label) : renderTooltip(map_object, target_data)
			const marker = new L.marker(coords, {
				icon: icon,
				draggable: draggable
			})
	
			marker.addTo(this.layer).bindTooltip(tooltip).on('click', (e) => {
				markerClickHandler(e.target, map_object, target_data)
			}).on('contextmenu', (e) => { 
				markerContextMenuHandler(e, map_object, target_data)
			})
	
			this.marker_by_map_object_id[map_object.id] = marker
	
			marker.on('dragend', (e) => {
				if (map_object.id === -1)
					return
				const x = marker.getLatLng().lng
				const y = marker.getLatLng().lat
				const coords = this.map_def ? this.map_def.relToAbs(x, y) : [y, x]
				if (!this.map_def || (this.map_def && this.map_def.insideMapBounds(coords[0], coords[1])))
					moveMapObject({
						id: map_object.id,
						x: coords[1],
						y: coords[0]
					})
				else 
					updateMapObjects()
					
			})
		}
	
		this.addMarker = (map_object, draggable, show_label) => {
			const coords = this.map_def ? this.map_def.absToRel(map_object.x, map_object.y) : [map_object.y, map_object.x];
			return this._newMarker(map_object, coords, draggable, show_label);
		}
	
		this.addDndMarker = (drag_object, x, y) => {
			const coords = _map.containerPointToLatLng(L.point([x, y]));
			if (this.map_def && !this.map_def.insideMapBounds(coords['lng'], coords['lat'] * this.map_def.ratio)) {
				console.debug(map_def)
				return
			}
	
			const icon = L.icon({
				iconUrl: sample_icon,
				iconSize: [30, 45],
				iconAnchor: [30 / 2, 45]
			});
			// save an object right after drop, wait for Object ID returned by server, then add a map marker
			const lon = coords['lng']
			const lat = coords['lat'] * (this.map_def ? this.map_def.ratio : 1)

			const existing_objects = store.getState().current_map_objects.filter((map_object) => (map_object.target_id === drag_object.target_id && map_object.target_type === drag_object.target_type))
			if (existing_objects.length > 0) {
				showNotification("warning", existing_objects[0].label, `Questo oggetto e' gia' presente sulla mappa`)
				//showOnMap(this.map_def.id, existing_objects[0].id)
				return 
			}



			saveDroppedMapObject(drag_object.target_type, drag_object.target_id, lon, lat, drag_object.data).then((response) => {
				// place a temporary object on map while updating
				const temporary_object = {
					id: response['id'],
					target_type: drag_object.target_type,
					target_id: drag_object.target_id,
					x: coords['lng'],
					y: coords['lat'],
					note: 'loading...',
					icon: 'marker_loading.svg',
					scale: 2,
				}
				this._newMarker(temporary_object, coords, true, store.getState().show_map_object_labels);
				
				updateMapObjects();
			})
		}

	}

}