import React, { useState } from 'react'
import { InputGroup, TextArea, Label, Checkbox, NumericInput,
	Menu, MenuItem, MenuDivider, Button, Popover,
	Slider, Icon, Tooltip, Switch} from '@blueprintjs/core'

import { Tooltip as TooltipWrapper } from "../modules/Tooltip.js"

import { store, Event } from "../redux.js"


import Select from 'react-select'


export const FormValue = (props) => {
	return (
		<div 
			className={`form-field ${props.className ? props.className : ""} ${props.vertical ? "form-field-vertical" : ""}`}
		>
			{props.modified ?
				<div className='form-field-label form-field-label-modified'>{props.label} *</div> : 
				<div className='form-field-label'>{props.label}</div>
			}
			<div className='form-field-value'>
				{props.tooltip ? <TooltipWrapper content={props.tooltip}><>{props.children}</></TooltipWrapper> : props.children}
			</div>
		</div>
	)
}

export const CheckboxValue = (props) => {
	return (
		<FormValue {...props}>
			<Checkbox
				checked={props.checked}
				onChange={props.onChange}
			>
			</Checkbox>
		</FormValue>
		
	)
}

export const SwitchValue = (props) => {
	const { checked, onChange } = props
	return (
		<FormValue {...props}>
			<Switch checked={checked} onChange={onChange} />
		</FormValue>
	)
}

export const SliderValue = (props) => {
	return (
		<FormValue {...props}>
			<Slider
				className='slider-container'
				{...props}
			></Slider>
		</FormValue>
	)
}

export const ValueCheckbox = ({disabled, onChange, checked, className, modified, label, defaultValue}) => {
	const [is_checked, setIsChecked] = useState(defaultValue || checked)
	const onClick = ()=>{
		onChange({target: {checked: !is_checked}})
		setIsChecked(!is_checked)
	}
	
	return (
		<div className={`form-field-checkbox ${className ? className : ""}`}>
			{modified ?
				<div className='form-field-label form-field-label-modified' onClick={onClick}>
					{label} *
				</div> : 
				<div className='form-field-label' onClick={!disabled && onClick}>
					{label}
				</div>
			}
			<div className='form-field-value'>
				<Checkbox
					onClick={!disabled && onClick}
					checked={is_checked}
					onChange={onChange}
					disabled={disabled}
				>
				</Checkbox>
			</div>
		</div>

	)
}

export const DropdownMenu = (props) => {
	return (
		<FormValue {...props}>
			<Select
				className="dropdown"
				{...props}
			/>
		</FormValue>
	)
}

export const DoubleDropdownMenu = (props) => {
	return (
		<FormValue {...props}>
			<table style={{width: "100%"}}>
				<tr>
					<td>
						<Select
							className="dropdown"
							{...props.dropdown_parent}
						/>
					</td>
					<td>
						<Select
							className="dropdown"
							{...props.dropdown_child}
						/>
					</td>
				</tr>
			</table>
		</FormValue>
	)
}


export const TypeSelector = (props) => {
	const OptionLabelFmt = (data) => {
		const capitalize = (s) => {
			if (typeof s !== 'string') return ''
			return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
		}

		return (
			<div className="dropdown-row">
				<div>
					{data.label}
				</div>
				<div className="dropdown-row-hint">
					{capitalize(data.manufacturer)}
				</div>
			</div>
		)
	}

	return (
		<FormValue {...props}>
			<Select 
				options={props.options} 
				formatOptionLabel={OptionLabelFmt}
				{...props}
			/>
		</FormValue>
	)
}

export const StringValue = (props) => {
	return (
		<FormValue {...props}>
			<InputGroup
				defaultValue={props.value}
				{...props}
			>
			</InputGroup>
		</FormValue>
	)
}

export const PathValue = (props) => {
	const [ value, setValue ] = useState(props.value)
	const [ is_real_path, setIsRealPath ] = useState(true)

	const onChange = (new_value) => {
		setValue(new_value)
		props.onChange(new_value, false)
		setIsRealPath(false)
	}

	const onFileSelected = (new_value) => {
		setValue(new_value)
		props.onChange(new_value, true)
		setIsRealPath(true)
	}

	return (
		<FormValue {...props}>
			<InputGroup
				{...props}
				spellCheck={false}
				value={value}
				onChange={(e) => {
					onChange(e.target.value)
				}}
				rightElement={
					<>
					{ is_real_path ? 
						<Tooltip content="Questa stringa e' un percorso">
							<Icon icon="folder-open"/>
						</Tooltip> :
						<Tooltip content="Questa stringa e' una chiave">
							<Icon icon="key" />
						</Tooltip>
					}
					<Button
						icon="search"
						onClick={() => {
							store.dispatch({
								type: Event.OPEN_FILE_PICKER,
								args: {
									dir_only: true,
									onSelect: onFileSelected
								}
							})
						}}
					/>
					</>
				}
			/>
		</FormValue>
	)
}


export const GrafanaOrgSelector = (props) => {
	const ClickMenu = (props) => {
		return (
			<Popover
				content={
					<Menu>
						<MenuDivider title={"Cantieri in Grafana"} />
						<MenuDivider />
						{props.options.map((e) => {
							return (<MenuItem
								text={e.label}
								onClick={() => {
									props.onChange({"target" : {"value" : e.label}})
									{props.onListSelect &&  props.onListSelect(e)}
								}}
							/>)
						})}
					</Menu>
				}
			>
				<Tooltip content="Mostra gli organizzazioni gia' presenti nella configurazione Grafana, ma non ancora inseriti come cantieri">
					<Button
						icon="search"
						rightIcon="caret-down"
					/>
				</Tooltip>
			</Popover>
		)
	}
	return (
		<FormValue {...props}>
			<InputGroup
				defaultValue={props.value}
				rightElement={props.options.length > 0 && <ClickMenu {...props} />}
				{...props}
			>

			</InputGroup>
		</FormValue>
	)
}

export const TimeValue = (props) => {
	return (
		<FormValue {...props}>
			<NumericInput
				stepSize={30}
				majorStepSize={60}
				minorStepSize={1}
				min={0}
				max={1e6}
				onValueChange={props.onChange}
				{...props}
			/>
		</FormValue>
	)
}

export const NumericValue = (props) => {
	return (
		<FormValue {...props}>
			<NumericInput
				selectAllOnFocus={true}
				{...props} 
			/>
		</FormValue>
	)
}


export const ReadOnlyValue = (props) => {
	return (
		<FormValue {...props}>	
			{props.value}
		</FormValue>
	)
}


export const TextValue = (props) => {
	return (
		<FormValue {...props}>
			<TextArea
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
				fill={true}
				{...props}
			/>
		</FormValue>
	)
}


export const PolyCoef = (props) => {
	return (
		<NumericInput
			key={props.order}
			placeholder={props.order}
			selectAllOnFocus={true}
			defaultValue={props.value}
			onValueChange={props.onChange}
			buttonPosition="none"
		/>
	)
}

export const Poly5 = (props) => {
	return (
		<FormValue {...props}>
			{
				['x0', 'x1', 'x2', 'x3', 'x4', 'x5'].map((n) => {
					return (
						<FormValue label={n}>
							<PolyCoef 
								order={n} 
								value={props.coefficients[n]} 
								onChange={(val) => {
									props.onChange({...props.coefficients, [n]: val})
								}}
							/>
						</FormValue>
					)
				})
			}
		</FormValue>
	)
}

export { InputGroup }