
import React from 'react'
import { Button } from "@blueprintjs/core"
import { store, Event } from "../../redux.js"
import { ModifyButton, RemoveButton, RemoveFromMapButton, ShowOnMapButton } from '../../modules/Buttons.js'
import { MapMenu, getOwnMapObjects } from '../DetailsView.js'
import ElementGroup from '../../components/ElementGroup.js'
import ShowOnGrafana from '../../components/ShowOnGrafana.js'

const NewDeviceButton = (props) => {
	return (
		<Button 
			icon="plus" 
			onClick={() => {	
				store.dispatch({
					type: Event.OPEN_DIALOG,
					modal_type: "create",
					modal_args: {object: "device", parent_id: props.data.id}
				})
			}}
		>
			Nuovo acquisitore
		</Button>
	)
}

const DetailsViewDatalogger = (props) => {
	const {user, is_super, is_admin, is_editor} = props
	const self_map_object_ids = getOwnMapObjects(props.map_objects, "datalogger", props.data.id).map((e) => e.id)
	return (
		<>	
			<div className='area area-details-content'>
				<h3>{props.data.name}</h3>
				<ShowOnGrafana {...props} />
				{props.data.description}
				<br/><br/>
			</div>
			
			<div className='area area-details-summary'>	
				{(is_super || is_admin || is_editor) && <NewDeviceButton {...props} />}<br/><br/>
				<MapMenu {...props} map_object_refs={self_map_object_ids} /><br/>
			</div>

		
			<div className='area area-details-footer'>
				{(is_super || is_admin || is_editor) && <ModifyButton {...props}/>}
				{(is_super || is_admin) && <RemoveButton {...props} />}
			</div>
		
		</>
	)
}

export default DetailsViewDatalogger