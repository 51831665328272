
import React, { useState, useEffect } from 'react'
import ElementGroup from '../components/ElementGroup.js'
import { StringValue, FormValue, InputGroup, ValueCheckbox, SliderValue, DropdownMenu } from '../components/Form.js'
import ColorPicker from '../components/ColorPicker.js'
import IconPicker from '../components/IconPicker.js'
import { getObjectByTypeAndId } from "../actions.js"
import { connect } from 'react-redux'
import { store } from '../redux.js'
import { Icon } from '@blueprintjs/core'
import checkboard_bg from "../assets/checkboard.svg"
import "../css/MapObjectEditor.css"


const store_container_by_object_type = {
	map : "maps",
	// cantiere : "cantieri",
	datalogger: "dataloggers",
	device: "devices",
	channel: "channels",
	vchannel: "vchannels"
}


const object_type_label_by_type_name = {
	"map" : 		<><Icon icon="map"/> 			&nbsp;Mappa</>,
	//"cantiere" :  	<><Icon icon="office"/> 		&nbsp;Cantiere</>,
	"datalogger" : 	<><Icon icon="cell-tower"/> 	&nbsp;Acquisitore</>,
	"device" :		<><Icon icon="inbox"/> 			&nbsp;Sensore</>,
	"channel" :		<><Icon icon="tag"/> 			&nbsp;Canale</>,
	"vchannel" :	<><Icon icon="tag"/> 			&nbsp;Canale virtuale</>,
}


const getObjectListByType = (object_type) => {
	return store.getState()[store_container_by_object_type[object_type]]
}


const getTargetObject = (target_type, target_id) => {
	const object_list = getObjectListByType()
	const target = object_list.filter((e) => {
		return (e.id === target_id)
	})[0]
	return { label : target.name, value: target.id }
}


const ObjectSelector = (props) => {

	const init_target = getObjectByTypeAndId(props.marker.target_type, props.marker.target_id) 
	// state
	const [available_object_list, setAvailableObjectList] = useState([])
	const [current_type_option, setCurrentTypeOption] = useState({value: props.marker.target_type, label: object_type_label_by_type_name[props.marker.target_type]})
	const [current_object_option, setCurrentObjectOption] = useState({value : init_target.name, label : init_target.name})

	const updateNameList = () => {
		console.log("Current type option IS %s", current_type_option.value)
		setAvailableObjectList(getObjectListByType(current_type_option.value))
	}


	useEffect (() => {
		props.setDataValid(current_type_option.value !== null && current_object_option.value !== null)
	}, [current_type_option, current_object_option])

	useEffect (() => {
		updateNameList()
	}, [current_type_option])

	useEffect (updateNameList, []) // componentDidMount

	
	return (
		<>
			<DropdownMenu 
				label="Tipo dell'oggetto"
				options={Object.keys(object_type_label_by_type_name).map((k) => {
					return ({
						value: k, 
						label: object_type_label_by_type_name[k]
					})
				})}
				value={current_type_option}
				onChange={(option)=> {
					setCurrentTypeOption(option)
					setCurrentObjectOption({label: "Seleziona...", value: null})
					props.onChange({target_type : option.value, target_id : 0}) // prevent to use previous ID for a new target type
					
				}}
			/>
			<DropdownMenu
				disabled={ current_type_option.value === null }
				value={current_object_option}
				options={available_object_list.map((e) => {
					return {label: e.name, value: e.id}
				})}
				onChange={(option) => {
					setCurrentObjectOption(option)	
					props.onChange({target_id : option.value})
				}}
			/>
		</>
	)
}


const IconPreview = (props) => {
	console.log(`url(${checkboard_bg})`)

	return (
		<div className='map-marker-icon-preview' style={{backgroundImage: `url(${checkboard_bg})`, width:48*5, height:48*5, display:"flex", justifyContent: "center", alignItems: "center"}}>
			<img src={`${props.base_path}/${props.icon}`} height={48 * props.scale} style={{opacity: props.opacity}}/>
		</div>
	)
}

const MapObjectEditor = (props) => {
	
	console.log(props)

	const marker = props.marker
	const base_path = `${window.location.href}/upload/icons/`
	
	const [label, setLabel] = useState(marker.label)
	const [icon, setIcon] = useState(marker.icon)
	const [scale, setScale] = useState(marker.scale)
	const [opacity, setOpacity] = useState(marker.opacity)
	const [note, setNote] = useState(marker.note)

	useEffect(() => { props.onChange ({id : marker.id})}, [])

	if (!(props.marker)) {
		return (<b>Invalid map object ID property</b>)
	}

	return (
		<div className='area area-map-object-editor'>
			<ElementGroup caption="Riferimento">
				<ObjectSelector {...props} />
			</ElementGroup>
			
			<ElementGroup caption="Visualizzazione">
				<FormValue label="Nome">
					<InputGroup
						value={label}
						modified={ label !== marker.label}
						onChange = {(e) => {
							setLabel(e.target.value)
							props.onChange({label: e.target.value})
						}}
					>
					</InputGroup>
				</FormValue>
				<StringValue 
					label="Nota"
					value={note}
					onChange={(e) => {
						setNote(e.target.value)
						props.onChange({note: e.target.value})
					}}
					modified={note !== marker.note}
				/>
				
				<SliderValue
					label="Dimensione"
					value={scale}
					stepSize={0.1}
					max={5}
					min={0}
					labelPrecision={1}
					onChange={(val) => {
						const v = val > 0.5 ? val : 0.5
						setScale(v)
						props.onChange({scale: v})
					}}
				/>

				<SliderValue
					label="Opacità"
					value={opacity}
					stepSize={0.01}
					max={1.0}
					min={0}
					labelPrecision={2}
					onChange={(val) => {
						setOpacity(val)
						props.onChange({opacity: val})
					}}
				/>
				
				<FormValue label="Icona">
					<IconPicker
						base_path={base_path}
						icon={icon}
						onSelect = {((new_icon) => {
							setIcon(new_icon)
							props.onChange({icon : new_icon})
						})}
					/>
					<IconPreview
						base_path={base_path}
						icon={icon}
						scale={scale}
						opacity={opacity}
					/>
				</FormValue>

			</ElementGroup>

		</div>
	)
}

const mapStoreToProps = (store) => {
	return {
		marker : store.selected_map_object,
		map_objects : store.current_map_objects
	}
}

export default connect(mapStoreToProps)(MapObjectEditor)