
import React, { useEffect, useState } from 'react'
import { Icon, Tooltip, Button } from '@blueprintjs/core'
import {getVirtualChannelHistory} from '../../api/wda_api.js'
import DateTimeInput from '../../components/DateTimeInput.js'
import StartDate from "./StartDate.js"
import { store } from "../../redux.js"

const ModifiedWrapper = ({modified, children}) => {
	if (modified)
		return (
			<b>
				{children}
			&nbsp;*</b>
		)
	return children
}

const VirtualChannelStartDate = ({ id, onChange, start_date_ts, new_channel}) => {
	// props: id, modified, onChange(Date : <Date> object), start_date_ts : timestamp in seconds, new_channel
	const [last_import_date, setLastImportDate] = useState(null)
	const [new_start_date, setNewStartDate] = useState(null)
	const [very_first_date, setVeryFirstDate] = useState(new Date(1577836800000)) // 01/01/2020 utc
	
	const modified = new_start_date !== null
	// date priority: configured start date -> very beginning  
	const display_date = new_start_date || (new_channel ? very_first_date : new Date(start_date_ts * 1000))

	const updateLastImportDate = () => {
		return getVirtualChannelHistory(id).then((data) => {
			if (data.success) {
				const date = new Date(data.last_import)
				setLastImportDate(date)
			}
		})
	}

	const UpdateButton = () => {
		return (<a 
			onClick={(e) => {
				e.preventDefault()
				updateLastImportDate()
			}}
		>
			Aggiorna
		</a>)
	}

	useEffect(() => {
		if (!new_channel) {
			updateLastImportDate()
		}
		// set default start date same as a cantiere start date
		setVeryFirstDate(new Date(store.getState().current_cantiere_data.start * 1000))
	}, [])

	const component_datetime_input = 
		<>
			<StartDate
				date={display_date}
				start_date={very_first_date}
				onChange={(new_date) => {
					setNewStartDate(new_date)
					onChange(new_date)
				}}
			/>
			
		</>
			
	if (new_channel)
		return (
			<>
				{component_datetime_input}	
			</>
		)

	else if (last_import_date === null)
		return (
			<>
				Questo canale non e' ancora stato eseguito. <UpdateButton/><br/><br/>
				{component_datetime_input}
			</>
		)
	return (
		<>
			Ultima importazione: {last_import_date.toLocaleString()}. <UpdateButton/><br/><br/>
			{component_datetime_input}
		</>		
	)
}

export default VirtualChannelStartDate