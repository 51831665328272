
import React, { useState } from 'react'
import { InputGroup, NumericInput } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import {StringValue, TextValue, FormValue, NumericValue, DropdownMenu, Poly5 } from '../components/Form.js'
import '../css/Form.scss'

const vsat_behavior_options = [
	{
		label: "Nessuno",
		value: 0
	},
	{
		label: "Salta il valore",
		value: 1,
	},
	{
		label: "Limita alla soglia",
		value: 2,
	},
	{
		label: "Duplica precedente",
		value: 3
	}
]

const vSatBehaviorToDropdownOption = (id) => {
	const idx = vsat_behavior_options.findIndex((e) => e.value === id)
	if (idx > -1)
		return { label : vsat_behavior_options[idx].label, value : id }
	return { label: "???", value: 0 }
}

const FormChannelCreate = (props) => {
	const [vsat_disabled, setVsatDisabled] = useState(true)
	const [coefficients, setCoefficients] = useState({x0:0, x1:1, x2:0, x3:0, x4:0, x5:0})

	const onChangeCoefficients = (new_coefficients) => {
		Object.keys(new_coefficients).forEach((x) => {
			if (new_coefficients[x] !== coefficients[x]) {
				props.onChange(x, new_coefficients[x])
			}
		})
		setCoefficients(new_coefficients)
	}

	return (
		<div className='form'>
			<StringValue
				label="Nome"
				placeholder="Nome del canale"
				onChange={(event)=> {
					props.onChange("name", event.target.value)
				}}
			/>

			<NumericValue
				label="Numero"
				placeholder="Numero progressivo del canale"
				onValueChange={(val)=> {
					props.onChange("number", val)
				}}
			/>

			<Poly5
				label="Polinomio"
				coefficients = {coefficients}
				onChange={onChangeCoefficients}
			/>


			<ElementGroup caption="Filtro">
				<DropdownMenu
					label="Comportamento"
					options = {vsat_behavior_options}
					defaultValue={vSatBehaviorToDropdownOption(0)}
					onChange={(opt)=> {
						props.onChange("vsat_behavior", opt.value)
						setVsatDisabled(opt.value === 0)
					}}
					min={0}
					max={2}
				/>	

				<NumericValue
					disabled={vsat_disabled}
					label="Val. MIN"
					defaultValue={0}
					onValueChange={(val)=> {
						props.onChange("vsat_min", val)
					}}
					min={-10e9}
					max={10e9}
					stepSize={1000}
					majorStepSize={1000}
				/>
				<NumericValue
					disabled={vsat_disabled}
					label="Val. MAX"
					defaultValue={0}
					onValueChange={(val)=> {
						props.onChange("vsat_max", val)
					}}
					min={-10e9}
					max={10e9}
					stepSize={1000}
					majorStepSize={1000}
				/>
			</ElementGroup>
			<ElementGroup caption = "Grafana">
				<StringValue 
					label="URL" 
					onChange={(e)=> {
						props.onChange("grafana_url", e.target.value)
					}}
				/>
			</ElementGroup>
		</div>
	);	
}


export default FormChannelCreate