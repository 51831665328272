import React from 'react'
import { Toaster, Position, Icon } from '@blueprintjs/core'
import '../css/Toast.css'

const Toast = Toaster.create({
	className: "alert",
	position: Position.TOP,
});

const showNotification = (type, caption, text) => {
	var icon = "info";
		if (type === "success")
			icon = "tick"
		else if (type === "warning")
			icon = "warning-sign"
		else if (type === "danger")
			icon = "error"
		Toast.show ({ 
			intent: type,
			message: <>
				<div className="alert-caption"><Icon icon={icon} />{caption}</div>
				<div className="alert-text">{text}</div>
			</>,
			timeout: 2000
		});
}


export { Toast, showNotification }