import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@blueprintjs/core'
import "../css/StateDebug.css"





const jsonToTable = (json) => {
	return (
		<table className="table-debug">
			{Object.keys(json).map((k) => {
				const v = json[k];
				return (
					<tr>
						<td className="key">{k}</td>
						{v !== null && <td className="value">{(typeof v === 'object') ? jsonToTable(v) : JSON.stringify(v)}</td>}
					</tr>
				)
			})}

		</table>
	)
}

const JsonTable = (props) => {
	return jsonToTable(props.json)
}

const StateDebugPanel = (props) => {
	return (
		<div className='state-debug-panel'>
			{/*Object.keys(this.props.redux_store).map((e) => {
				return <>{`${e} : ${this.repr(this.props.redux_store[e])}`}<br/></>
			})*/
			}
				<JsonTable json={props.redux_store} />
			
		</div>
	);
}

const mapStoreToProps = (store) => {
	return ({
		redux_store: store
	});
}

export default connect(mapStoreToProps)(StateDebugPanel);