
import { getData, getData2, postData, putData, deleteData, upload, postData2, putData2 } from './requests.js'
import { IGetVchannelTimepointResponse, ISetVirtualChannelRequest } from "./interfaces.js"

/*** CANTIERI ***/

export const getCantiereList = () => {
	return getData('/api/wda/cantieri');
}

export const getCantiereData = (name) => {
	return getData('/api/wda/cantiere_data', {name : name});
}

export const getCantiereById = (id) => {
	return getData('/api/wda/cantiere', {id : id});
}

export const getCantiereByName = (name) => {
	return getData('/api/wda/cantiere', {name : name});
}

export const modifyCantiere = (data) => {
	return postData('/api/wda/cantiere', data);
}

export const createCantiere = (data) => {
	return putData2('/api/wda/cantiere', data);
}

export const deleteCantiere = (id, delete_grafana, delete_data, keyphrase) => {
	return deleteData('/api/wda/cantiere', {id : id, delete_grafana: delete_grafana, delete_data: delete_data, keyphrase: keyphrase})
}

export const importConfigurationData = (cantiere_id, data_format, data, dry_run) => {
	return postData('/api/wda/import', {cantiere_id: cantiere_id, format: data_format, data: data, dry_run: dry_run})
}


/*** DATALOGGERS ***/

export const getDataloggerInfo = (param) => {
	return getData('/api/wda/datalogger', param); // id or name
}

export const getDataloggerTypes = () => {
	return getData('/api/wda/datalogger_type'); 
}

export const modifyDatalogger = (data) => {
	return postData('/api/wda/datalogger', data);
}

export const createDatalogger = (data) => {
	return putData('/api/wda/datalogger', data);
}

export const deleteDatalogger = (id) => {
	return deleteData('/api/wda/datalogger', {id : id});
}



/*** DEVICES ***/

export const getDeviceInfo = (param) => {
	return getData('/api/wda/device', param); // id or name
}

export const getDeviceTypes = (manufacturer = null) => {
	if (manufacturer !== null)
		return getData('/api/wda/device_type', {manufacturer: manufacturer}); 
	return getData('/api/wda/device_type'); 
}

export const getDeviceTypesByDataloggerType = (datalogger_type = null) => {
	if (datalogger_type !== null)
		return getData('/api/wda/device_type', {datalogger_type: datalogger_type}); 
	return getData('/api/wda/device_type', {datalogger_type : -1}); 
}

export const modifyDevice = (data) => {
	return postData('/api/wda/device', data);
}

export const createDevice = (data) => {
	return putData('/api/wda/device', data);
}

export const deleteDevice = (id) => {
	return deleteData('/api/wda/device', {id : id});
}


/*** CHANNELS ***/

export const getChannelInfo = (param) => {
	return getData('/api/wda/channel', param); // id or name
}

export const modifyChannel = (data) => {
	return postData('/api/wda/channel', data);
}

export const createChannel = (data) => {
	return putData('/api/wda/channel', data);
}

export const deleteChannel = (id) => {
	return deleteData('/api/wda/channel', {id : id});
}


/*** VIRTUAL CHANNELS ***/

export const getVirtualChannelInfo = (param) => {
	return getData('/api/wda/vchannel', param);
}

export const modifyVirtualChannel = (data : ISetVirtualChannelRequest) => {
	return postData('/api/wda/vchannel', data);
}

export const createVirtualChannel = (data) => {
	return putData('/api/wda/vchannel', data);
}

export const deleteVirtualChannel = (id) => {
	return deleteData('/api/wda/vchannel', {id : id});
}

export const getVirtualChannelHistory = (id) => {
	return getData('/api/wda/vc_history', {id : id});
}

export const getVirtualChannelHistoryByCantiere = (cantiere_id) => {
	return getData('/api/wda/vc_history', {cantiere_id : cantiere_id});
}

export const getVirtualChannelTimePoint = (db, t0, timeout, expression): Promise<IGetVchannelTimepointResponse> => {
	return getData('/api/wda/vc_timepoint', {db: db, t0: t0, timeout: timeout, expression: expression}, timeout=120000) // increase timeout to 120 sec
}

export const checkFormulaString = (formula) => {
	/* return : {success: bool, message: str} */
	return postData('/api/wda/vchannel/check_expression', {expression: formula})
}

export const deleteVirtualChannelGroup = (name: string, cantiere_id: int) => {
	return deleteData('/api/wda/vchannel/group', { name: name, cantiere_id: cantiere_id });
}

export interface IVirtualChannelModifyRequest {
	name         : string;
	cantiere_id  : int;
	new_name?    : string;
	last_import? : number;
	enabled?     : boolean;
}
export const modifyVirtualChannelGroup = (request : IVirtualChannelModifyRequest) => {
	return postData('/api/wda/vchannel/group', request);
}


/*** MAPS ***/

export const getMapsByCantiere = (cantiere_id) => {
	return getData('/api/wda/maps', {cantiere_id : cantiere_id});
}

export const deleteMap = (id) => {
	return deleteData('/api/wda/map', {id: id});
}

export const uploadMap = (form_data, progress_callback) => {
	return upload('/api/wda/map/upload', form_data, progress_callback)
}

export const createMap = (data) => {
	return putData('/api/wda/map', data)
}



/*** MAP OBJECT ***/

export const getMapObjectsByMap = (map_id) => {
	return getData('/api/wda/map_objects', {map_id : map_id});
}

export const getMapObjectsByCantiere = (cantiere_id) => {
	return getData('/api/wda/map_objects', {cantiere_id : cantiere_id});
}

export const modifyMapObject = (data) => {
	return postData('/api/wda/map_objects', data);
}

export const createMapObject = (data) => {
	return putData('/api/wda/map_objects', data);
}

export const deleteMapObject = (id) => {
	return deleteData('/api/wda/map_objects', {id: id});
}

/*** GRAFANA ***/

export const getGrafanaOrgsAdmin = () => {
	return getData("/api/grafana/org/all");
}

export const getGrafanaDashboardListByOrg = (org_name) => {
	return getData2("/api/grafana/dashboard/list", {org_name : org_name});
}

export const createCantiereFromJson = (json_data: object) => {
	return postData2("/api/wda/cantiere/custom", {json: json_data})
}

export const deleteGrafanaPanel = (org_name, dashboard_name, panel_name) => {
	return deleteData("/api/grafana/panel", {
		org_name: org_name, 
		dashboard_name: dashboard_name, 
		panel_name: panel_name
	})
}