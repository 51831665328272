import React, {useState, useEffect} from 'react'
import {Icon, Spinner, Collapse } from '@blueprintjs/core'
import '../css/Spoiler.css'

const Spoiler = (props) => {
	var [show, setShow] = useState(props.show);
	var [content, setContent] = useState(props.children);
	const is_loading = show && props.deferred;

	useEffect (() => {
		props.onStateChange && props.onStateChange(show)
	}, [show])

	return (
		<div 
			className='spoiler'
		>
			<div 
				className={`spoiler-title ${show ? "opened" : "closed"}`}
				onClick={()=> {
					if (!show && props.deferred) {
						props.deferred.then(setContent);
					}
					setShow(!show);
				}}
			>
				<span className='bp3-button-icon'>
					<Icon icon={show ? 'chevron-up' : 'chevron-down'} />
				</span>
				<span className='bp3-button-text'>
					{props.title}
				</span>
			</div>
			<Collapse
				className={`spoiler-content ${show ? "opened" : "closed"}`}
				keepChildrenMounted={props.persistent}
				isOpen={show}
			>
				{props.deferred ? content : props.children}
				
			</Collapse>
		</div>
	);

};

export default Spoiler