
import React, { useState, useEffect } from 'react'
import { Tree, Icon, Tooltip, Position, Tag, Menu, MenuItem, MenuDivider, ContextMenu, Divider } from "@blueprintjs/core"
import { connect } from "react-redux"
import { store, Event } from "../redux.js"
import { getObjectByTypeAndId, moveVirtualChannelToGroup, isAnyModalOpened } from "../actions.js"
import * as CM from "./ContextMenu.js"



// TODO: need to fix, search filter is not working properly
// Tree search function (case insensitive)
const filterTree = (input_objects, search_string) => {
	// the search must be started from the most inner elements, to make sure 
	// even if it's parent name doesn't match, the whole hierarchy should be preserved for the children whose names are matched.
	const filterByName = (input_objects, search_string) => {
		return input_objects.filter((element) => {
			return element?.name?.toLowerCase().includes(search_string) || (filterByName(element.childNodes || [], search_string).length > 0)
		})
	}
	// start recursively from the most inner elements
	return filterByName(input_objects, search_string)
}




// This function calculates the total number of channels associated with a given datalogger.
// It does this by:
// 1. Filtering the devices array to find all devices that belong to the given datalogger.
// 2. For each of those devices, it filters the channels array to find all the channels associated with that device.
// 3. It then sums up the number of channels for each device to get the total number of channels for the datalogger.
const getChannelCountFromDatalogger = (datalogger, devices, channels) => {
	// Filter the devices array to find all devices that belong to the given datalogger
	var rel_devices = devices.filter((d)=>{return d.datalogger === datalogger.id});
	
	// For each device, find the number of channels associated with it, and sum them up
	var sum = rel_devices?.map((d) => {
		// Filter the channels array to find all the channels associated with the current device
		var rel_channels = channels.filter((c) => c.device == d.id)
		// Return the number of channels for this device
		return rel_channels.length
	}).reduce((total, count) => total+count, 0)
	
	// Return the total number of channels for the datalogger
	return sum;
}
const EmptyAreaText = (props) => {
	return (
		<div className='empty-area-text'>
			<Icon icon='arrow-up' iconSize={24}/><br/>
			Seleziona il cantiere
		</div>
	)
}


const openDeleteModal = (object_type, object_id) => {
	store.dispatch({
		type: Event.OPEN_DIALOG,
		modal_type: "remove",
		modal_args: {object: object_type, id : object_id}
	})
}

const deleteKeyHandler = (e) => {
	if (e.key === "Delete") {
		const sel_type = store.getState().details?.type;
		if (["datalogger", "device", "channel", "vchannel"].includes(sel_type) && !isAnyModalOpened())  {
			openDeleteModal(sel_type, store.getState().details?.data?.id)
		}
	}
}

const NodeContextMenu = (props) => {
	const { 
		id,
		type,
		can_delete = false
	} = props
	const new_menu_item_by_parent_type = {
		"datalogger" : <CM.MenuItem_NewDevice {...props} />,
		"device" : <CM.MenuItem_NewChannel {...props} />,
		"channel" : <></>,
		"vchannel" : <></>
	}

	const editHandler = () => {
		if (type === "vchannel-group") {
			store.dispatch({
				type: Event.OPEN_VCHANNEL_GROUP_OPS_MODAL,
				args : id
			})
		}
		else {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type : "config",
				modal_args : {object: type, id : id}
			})	
		}
	}

	const deleteHandler = () => {
		openDeleteModal(type, id)
	}

	return (
		<Menu>
			{new_menu_item_by_parent_type[type]}
			<MenuItem text="Modifica" icon="edit" onClick={editHandler} />
			{ can_delete && <MenuItem text="Elimina" icon="cross" onClick={deleteHandler} /> }
		</Menu>
	)
}


const mapNodeProps = (store) => {
	return ({
		selection_type : store.details?.type,
		selection_id : store.details?.data?.id
	});
}


const dispatchDropEvent = (e, type, id, data) => {
	store.dispatch({ 
		type: Event.DROP_ON_MAP,
		target_type: type,
		target_id: id,
		clientX: e.clientX,
		clientY: e.clientY,
		data: data
	})
}


const DeviceNode = connect(mapNodeProps)((props) => {
	const { device, children } = props
	return (
		<div
			className="tree-node"
			draggable={true}
			onDragEnd={(e) => {
				dispatchDropEvent(e, "device", device.id, device)
			}}
		>
			{children}
		</div>
	)
})


const ChannelNode = connect(mapNodeProps)((props) => {
	const { channel, children } = props
	return (
		<div
			className="tree-node"
			draggable={true}
			onDragEnd={(e) => {
				dispatchDropEvent(e, "channel", channel.id, channel)
			}}
		>
			{children}
		</div>
	)
})

const VchannelNode = connect(mapNodeProps)((props) => {
	const { vchannel, children } = props
	return (
		<div
			classname="tree-node"
			draggable={true}
			onDragEnd={(e) => {
				dispatchDropEvent(e, "vchannel", vchannel.id, vchannel)
			}}
		>
			{children}
		</div>
	)
})

const VchannelGroupNode = (props) => {
	const { group } = props
	return (
		<div
			className="tree-node"
			onDragOver={(e) => {
				e.stopPropagation()
				e.preventDefault()
			}}
			onDrop={(e) => {
				setTimeout(() => { // hack to execute handler AFTER redux state update
					const obj = store.getState().drag_object;
					if (obj.target_type === "vchannel") 
						moveVirtualChannelToGroup (obj.data, group)
				}, 1)
			}}
		>
		{group}
		</div>
	)
}


const DataloggerNode = connect(mapNodeProps)((props) => {
	const { datalogger, children } = props
	return (
		<div
			className="tree-node"
			draggable={true}
			onDragEnd={(e) => {
				dispatchDropEvent(e, "datalogger", datalogger.id, datalogger)
			}}
		>
			<Tooltip
				position={Position.LEFT}
				content={datalogger.description}
			>
				{children}
			</Tooltip>
		</div>
	)
})


const DefaultContextMenu = (props) => {
	return (
		<Menu>
			<CM.MenuItem_NewVirtualChannel {...props} />
			<CM.MenuItem_NewDevice {...props} standalone={true}/>
			<CM.MenuItem_NewDatalogger {...props} />
			<MenuDivider/>
			<CM.MenuItem_NewMap {...props} />
			<CM.MenuItem_ImportConfig />
		</Menu>
	)
}


const DataloggerTree = (props) => {
	const { 
		user, 
		cantiere_id, 
		cantiere_name,
		filter_string,
		dataloggers, 
		devices,
		channels, 
		vchannels, 
		vchannel_groups,
		selection_id, 
		selection_type
	} = props
	const [nodes, setNodes] = useState([])
	const [expanded_node_list, setExpandedNodeList] = useState([])
	const cantiere_role = Object.keys(user.member_of).includes(cantiere_name) ? user.member_of[cantiere_name] : "None"
	const is_super = user.role === "super"
	const is_admin = cantiere_role === "Admin"
	const is_editor = cantiere_role === "Editor"

	const setNodeExpanded = (type, id, is_expanded) => {
		const uid = `expand-${type}-${id}`
		//console.debug("Set node %s to expanded=%o", uid, is_expanded)
		if (is_expanded) {
			window.localStorage.setItem(uid, "1")
			setExpandedNodeList([...expanded_node_list, uid])
		}
		else {
			window.localStorage.removeItem(uid)
			setExpandedNodeList(expanded_node_list.filter((e) => e !== uid))
		}
	}

	const getNodeExpanded = (type, id) => {
		const uid = `expand-${type}-${id}`
		const is_expanded = window.localStorage.getItem(uid) !== null
		//console.debug("GET node %s expanded=%o", uid, is_expanded)
		setNodeExpanded(type, id, is_expanded) // update component state as well
		return is_expanded
	}

	const nodeExpandHandler = (node) => {
		setNodeExpanded(node.type, node.id, true)
		// console.debug("expand handler")
		node.isExpanded = true
		if (node.type === "vchannel-group") {
			node.icon = <Icon icon="folder-open" className="icon-vchannel-group" />
		}
		
	}
		
	const nodeCollapseHandler = (node) => {
		// console.debug("collapse handler")
		setNodeExpanded(node.type, node.id, false)
		node.isExpanded = false
		if (node.type === "vchannel-group")
			node.icon = <Icon icon="folder-close" className="icon-vchannel-group" />
		
	}
		
	const nodeClickHandler = (node) => {
		setNodeExpanded(node.type, node.id, true)
		store.dispatch({
			type: Event.SHOW_DETAILS,
			object: node.type,
			data: getObjectByTypeAndId(node.type, node.id)
		})
	}
		
	const nodeDoubleClickHandler = (node) => {
		if (node.type === "vchannel-group" || is_super || is_admin || is_editor)
			return
		store.dispatch({
			type: Event.SHOW_DETAILS,
			object: node.type,
			data: getObjectByTypeAndId(node.type, node.id)
		})
		store.dispatch({
			type : Event.OPEN_DIALOG,
			modal_args : node.type,
			modal_type : "config"
		});
	}

	const nodeContextMenuHandler = (node, level, event) => {
		nodeClickHandler(node)
		if (is_super || is_admin || is_editor) {
			ContextMenu.show(
				<NodeContextMenu {...node} can_delete={is_super || is_admin} />, 
				{left: event.clientX, top: event.clientY }, () => {}
			)
		}
	}

	const defaultContextMenuHandler = (e) => {
		if (is_super || is_admin || is_editor) {
			ContextMenu.show(
				<DefaultContextMenu id={cantiere_id} />, 
				{left: e.clientX, top: e.clientY}, () => {}
			)
		}
	}

	
	const makeNode = (params) => {
		const n_children = params.childNodes.length
		const secondary_label = () => {
			if (n_children == 0)
				return null;
			if (params.type === 'datalogger')
				return (
					<Tag minimal >{params.channels_count} / {n_children}</Tag>
				);
			if (params.type === 'device' || params.type === 'vchannel-group')
				return (
					<Tag minimal >{n_children}</Tag>
				)
		}
		return {
			...params,
			hasCaret : params.hasOwnProperty("childNodes") && n_children > 0,
			// isExpanded : params.type === 'datalogger',
			isExpanded: getNodeExpanded(params.type, params.id), // TODO: fix
			secondaryLabel: secondary_label()
		}
	}

	const makeNodes_channels = (channels, device_id, selection_type, selection_id) => {
		return channels.filter((ch)=>(ch.device === device_id)).sort((ch2,ch1)=>(ch1.number < ch2.number ? 1: -1)).map((channel) => {
			const is_selected = (selection_type === "channel" && selection_id === channel.id)
			return ({
				id: channel.id,
				type: "channel",
				label: <ChannelNode channel={channel}>{`${channel.number} : ${channel.name}`}</ChannelNode>,
				icon: <Icon icon="tag"/>,
				isSelected: is_selected, 
			})
		})
	}


	const makeNodes = (dataloggers, devices, channels, vchannels, selection_id, selection_type) => {
		const datalogger_tree = dataloggers.sort((ca2,ca1)=>(ca1.name < ca2.name)).map((datalogger) => {
			const is_selected1 = (selection_type === "datalogger" && selection_id === datalogger.id)
			return makeNode({
				id : datalogger.id,
				type: "datalogger",
				key: `datalogger-${datalogger.id}`,
				channels_count: getChannelCountFromDatalogger(datalogger, devices, channels),
				name: datalogger.name,
				label: <DataloggerNode datalogger={datalogger} >{datalogger.name}</DataloggerNode>,
				icon: <Icon icon="cell-tower"/>,
				isSelected: is_selected1,
				isExpanded: getNodeExpanded("datalogger", datalogger.id), // || is_selected1,
				childNodes: devices.filter((d)=>d.datalogger === datalogger.id).sort((d2,d1)=>(d1.name < d2.name ? 1: -1)).map((device) => {
					const is_selected2 = (selection_type === "device" && selection_id === device.id)
					return makeNode({
						id : device.id,
						type: "device",
						key: `device-${device.id}`,
						name: device.name,
						label: <DeviceNode device={device} >{device.name}</DeviceNode>,
						icon: <Icon icon="inbox"/>,
						isSelected: is_selected2,
						isExpanded: getNodeExpanded("device", device.id), // || is_selected2,
						childNodes : channels.filter((ch)=>(ch.device === device.id)).sort((ch2,ch1)=>(ch1.number < ch2.number ? 1: -1)).map((channel) => {
							const is_selected3 = (selection_type === "channel" && selection_id === channel.id)
							return ({
								id: channel.id,
								type: "channel",
								name: channel.name,
								label: <ChannelNode channel={channel}>{`${channel.number} : ${channel.name}`}</ChannelNode>,
								icon: <Icon icon="tag"/>,
								isSelected: is_selected3, 
							})
						})
					})
				})
			})
		})

		const orphan_device_tree = devices.filter((dev) => (dev.datalogger === -1)).sort((d1, d2) => (d1.name < d2.name ? -1: 1)).map((device) => {
			const is_selected = (selection_type === "device" && selection_id === device.id)
			return makeNode ({
				id: device.id,
				type: "device",
				key: `orphan-device-${device.id}`,
				name: device.name,
				label: <DeviceNode device={device} > {device.name} </DeviceNode>,
				icon: <Icon icon="cube"/>,
				isSelected: is_selected,
				childNodes: makeNodes_channels(channels, device.id, selection_type, selection_id)
			})
		})

		
		const vChannelNodesByGroup = (group_name) => {
			return vchannels.filter((vc)=>(vc.group === group_name)).sort((vc2,vc1)=>(vc1.name < vc2.name ? 1: -1)).map(makeVchannelNode)
		}
		const makeVchannelNode = (vchannel) => {
			const is_selected4 = (selection_type === "vchannel" && selection_id === vchannel.id)
			const is_selected_group = (selection_type === "vchannel-group" && selection_id === vchannel.group)
			return makeNode({
				id: vchannel.id,
				type: "vchannel",
				key: `vchannel-${vchannel.id}`,
				name: vchannel.name,
				label: <VchannelNode vchannel={vchannel} > {vchannel.name} </VchannelNode>,
				icon: <Icon icon={vchannel.enabled ? "function" : "cross"} className='icon-vchannel'/>,
				isSelected: is_selected4 || is_selected_group,
				childNodes: [],
				enabled: vchannel.enabled
			})
		}

		const virtual_channel_tree = (vchannel_groups || []).map((group) => {
			const is_selected5 = (selection_type === "vchannel-group" && selection_id === group.id)
			const child_nodes = vChannelNodesByGroup(group.id)
			const all_disabled = child_nodes.reduce((pv, cv) => (pv && !cv.enabled), true)
			return makeNode({
				id: group.id,
				type: "vchannel-group",
				key: `vchannel-group-${group.id}`,
				label: <VchannelGroupNode group={group.name} />,
				icon: <Icon icon={!all_disabled ? "folder-close" : "disable"} className="icon-vchannel-group" />,
				isExpanded: getNodeExpanded("vchannel-group", group.id),
				isSelected: is_selected5,
				childNodes: child_nodes,
			})
		})
		
		// Combine all the node types into a single tree
		const full_tree = [...vChannelNodesByGroup(""), ...virtual_channel_tree, ...orphan_device_tree, ...datalogger_tree]
		//console.debug("Full tree: %o", full_tree)
		// Apply search filter
		const new_tree = filterTree(full_tree, filter_string)
		//console.debug("New tree: %o", new_tree)
		return new_tree
	}
	

	// componentWillReceiveProps
	useEffect (() => {
		// console.log(props.vchannels)
		setNodes(makeNodes(dataloggers, devices, channels, vchannels, selection_id, selection_type))
	}, [props])

	useEffect(() => {
		// ComponentDidMount
		const el = document;
		el.addEventListener('keydown', deleteKeyHandler)
		return (() => {
			// componentWillUnmount
			el.removeEventListener('keydown', deleteKeyHandler)
		})
	}, [])
	
	if (cantiere_id !== 0)
		return (
			<>
				<Tree
					contents = {nodes}
					onNodeClick = {nodeClickHandler}
					onNodeExpand = {nodeExpandHandler}
					onNodeCollapse = {nodeCollapseHandler}
					onNodeDoubleClick = {nodeDoubleClickHandler}
					onNodeContextMenu = {nodeContextMenuHandler}
				></Tree>
				<div className="area area-datalogger-tree-ctx-menu-area" onContextMenu={defaultContextMenuHandler}/>
			</>
		)
	return (<EmptyAreaText/>)
}


const mapStoreToProps = (store) => {
	return ({
		user                : store.current_user,
		cantiere_id         : store.current_cantiere_id,
		cantiere_name       : store.current_cantiere,
		dataloggers         : store.dataloggers,
		channels            : store.channels,
		vchannels           : store.vchannels,
		vchannel_groups     : store.vchannel_groups,
		devices             : store.devices,
		selection_type      : store.details?.type,
		selection_id        : store.details?.data?.id || store.details?.id
	})
}

export default connect(mapStoreToProps)(DataloggerTree)