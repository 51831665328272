import React from 'react'
import '../css/ElementGroup.css'

class ElementGroup extends React.Component
{
	render () {
		var caption = this.props.main ? <b>{this.props.caption}</b> : this.props.caption
		return (
			<fieldset className={`${this.props.main ? 'element-group element-group-main' : 'element-group '} ${this.props.className}`}>
				<legend className='element-group-caption'>{caption}</legend>
				<div className='element-group-content'>
					{this.props.children}
				</div>
			</fieldset>
			
		)
	}
}

export default ElementGroup