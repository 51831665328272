import { Button, Classes, Dialog, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import FilePicker from "../components/FilePicker.js"
import "../css/FilePickerModal.css"
import { Event, store } from '../redux.js'



const FilePickerModal = (props) => {
	const [ current_path, setCurrentPath ] = useState("")
	const hide = () => {
		store.dispatch({
			type: Event.CLOSE_FILE_PICKER
		})
	}

	const apply = () => {
		props.args.onSelect && props.args.onSelect(current_path)
		hide()
	}

	return (
		<>
			{props.show && <Dialog
				portalClassName="modal-on-top"
				isOpen={true}
				onClose={() => {
					store.dispatch({type: Event.CLOSE_FILE_PICKER})
				}}
				title={`Seleziona ${props.args.dir_only ? "la cartella" : "il file"}`}
				className="modal-file-picker"
			>
				<Classes.DIALOG_BODY>
					<FilePicker 
						dir_only={true}
						onChangePath={setCurrentPath}
						path={current_path}
					/>
					
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={apply}
					>
						Seleziona
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

				</Dialog>
			}
		</>
	)
}


const mapStateToProps = (state) => {
	return ({
		show: state.modal_file_picker.show,
		args: state.modal_file_picker.args
	})
}

export default connect(mapStateToProps)(FilePickerModal)