
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { submitFeedback } from '../api/feedback.js'
import { StringValue, TextValue } from '../components/Form.js'
import { showNotification } from '../modules/Toast.js'
import { Event, store } from '../redux.js'

const FeedbackModal = (props) => {
	const [ busy, setBusy ] = useState(false)
	const [ attachments, setAttachments ] = useState([])
	const [ email, setEmail ] = useState("")
	const [ text, setText ] = useState("")

	var details = `Cantiere \"${props.cantiere_name}\" ID: ${props.cantiere_id}\n`
	details += `Utente: ${props.current_user.name}\n`
	details += `Ruolo: ${props.current_user.role}\n`


	const hide = () => {
		store.dispatch({type: Event.CLOSE_FEEDBACK_MODAL});
	}

	const submit = () => {
		setBusy(true)
		submitFeedback(
			email,
			text,
			attachments, 
			details
		).then(() => {
			showNotification("success", "Messaggio inviato", "Grazie per averci contattato")
			hide();
		}).finally(() => {
			setBusy(false)
		});
		
	}

	// TODO: add attachment uploading
	
	return (
		<>
			{props.show && <Dialog
				className="modal-new-map"
				isOpen={props.show}
				onClose={hide}
				title={<>Segnala un errore</>}
			>
				<Classes.DIALOG_BODY>
					<div className='modal-content'>
						<>
							<StringValue
								label="Nome / Email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value)
								}}
							/>
							<TextValue
								label="Messaggio"
								onChange={(e) => {
									setText(e.target.value)
								}}
							>

							</TextValue>
						</>
					</div>
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={submit}
						icon='send-message'
						disabled={busy}
					>
						Invia
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	)
}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_feedback.show,
		args : store.modal_feedback.args,
		cantiere_id : store.current_cantiere_id,
		cantiere_name : store.current_cantiere,
		current_user : store.current_user
	})
};

export default connect(mapStoreToProps)(FeedbackModal)