import React, { useState, useEffect } from 'react'
import {FormGroup, InputGroup, Button, Tooltip, Intent, Callout} from '@blueprintjs/core'
import WdaLogo from '../assets/wda_logo.png'
import { login } from '../actions.js'

import '../css/LoginPage.css'

const LoginPage = (props) => {
	const { onLogin } = props
	const [ show_password, setShowPassword ] = useState(false)
	const [ busy, setBusy ] = useState(false)
	const [error_message, setErrorMessage ] = useState(null)

	const submit = () => {
		setBusy(true)
		setErrorMessage(null)
		var username = document.getElementById('input-login').value
		var password = document.getElementById('input-password').value
		login(username, password).then(() => {
			if (onLogin) {
				onLogin(username)
			}
		}).catch((e) => {
			if (e.code === 400)
				setErrorMessage(["Credenziali errate", "Controllare il nome utente e la password"])
			else if (e.code === 0)
				setErrorMessage(["Errore della connessione", "Controllare la connessione internet"])
			else 
				setErrorMessage([`Errore ${e.code}`, e.message])
		}).finally(() => {
			setBusy(false)
		})
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			submit();
		}
	}

	useEffect(() => {
		/* comonentDidMount */
		document.getElementById('input-login').focus()
		//setTimeout (()=>{document.getElementById('input-login').focus()}, 1000);
		document.addEventListener('keypress', handleKeyPress);
		return (() => {
			/* componentWillUnmount */
			document.removeEventListener('keypress', handleKeyPress);
		})
	}, [])

	const LockButton = (props) => {
		return (
			<Tooltip content={`${show_password ? "Nascondi" : "Mostra"} la password`}>
				<Button
					icon={show_password ? "unlock" : "lock"}
					intent={Intent.NONE}
					minimal={true}
					onClick={()=>{setShowPassword(!show_password)}}
				/>
			</Tooltip>
		)
	}
	return (
		<div className="page page-login">
			
			<div className="login-form-container">
				<FormGroup
					label="Nome utente"
					labelFor="input-login"
				>	
					<InputGroup 
						id="input-login" 
						placeholder="Nome utente" 
						onChange={() => {
							setErrorMessage(null)
						}}
					/>
				</FormGroup>
				<FormGroup
					label="Password"
					labelFor="input-password"
				>
					<InputGroup 
						id="input-password" 
						placeholder="Password" 
						type={show_password ? "text" : "password"}
						rightElement={<LockButton />}
						onChange={() => {
							setErrorMessage(null)
						}}
					/>
				</FormGroup>
				<FormGroup
					labelFor="input-password"
				>
					<Button
						intent={Intent.PRIMARY}
						onClick={submit}
						className="login-btn"
						disabled={busy}
					>
						{`${busy ? "Attendere prego..." : "Accedi"}`}
					</Button>
				</FormGroup>
				{error_message && <> 
					<br/><br/><br/>
						<Callout
							intent={Intent.DANGER}
							title={error_message[0]}
						>
							{error_message[1]}
						</Callout>
					<br/>
					</>
				}
			</div>
			<br/>
			<div className='wda-logo-container'>
				<img src={WdaLogo} />
			</div>
			<div className='login-page-footer'>
				Web Data Analysis
			</div>
		</div>
	);
}

export default LoginPage