import React, { useState, useEffect } from 'react'
import { Tooltip } from '@blueprintjs/core'
import { getDatabases, getTables, getSeriesList } from '../api/influx_api.js'
import ElementGroup from '../components/ElementGroup.js'
import { ValueCheckbox } from '../components/Form.js'
import { store, Event } from '../redux.js'


const TooltipListView = (props) => {
	return (
		<Tooltip
			placement="right"
			content={
				props.arr.length > 0 ? props.arr.map((e) => {
				return (<>{e}<br/></>)
			}) : <>Nessuna tabella presente in questo database </>}
		>
			<i>{props.children}</i>
		</Tooltip>
	)
}


const ColumnStat = (props) => {
	// props: name, count, onCheck, checked
	return (
		<>
			<ValueCheckbox 
				label={<><strong>{props.name}</strong> (<i><strong>{props.count}</strong> misure</i>)</>}
				checked={props.checked}
				onChange={(e) => {
					props.onCheck(!e.target.checked)
				}}
			>
			</ValueCheckbox>
		</>
	)
}

const ColumnStats = (props) => {
	const column_list_dom = props.column_stat_list.map((cs) => {
		return (
			<ColumnStat
				name={cs.name}
				count={cs.count}
				checked={props.selected_columns.includes(cs.name)}
				key={cs.name}
				onCheck={(checked) => {
					if (checked) {
						props.onSelectionChange(props.selected_columns.filter((name) => (name !== cs.name))) // remove current name from the list
					}
					else {
						props.onSelectionChange([...props.selected_columns, cs.name]) // append current name to the list
					}
				}}
			></ColumnStat>
		)
	})
	
	if (column_list_dom.length === 0)
		return (<>Caricamento...</>) // nessun dato ?
	return (
	props.no_group ? <> {column_list_dom} </> : <>
		<ElementGroup caption={props.db_name}>
			{column_list_dom}
		</ElementGroup>
	</>)
}

export const InfluxSummaryPanel = (props) => {
	// props: db_name, table_name, column_name


	const [database_list, setDatabaseList] = useState({ready: false, data: []})
	const [database_present, setDatabasePresent] = useState(false)

	const [table_list, setTableList] = useState({ready: false, data: []})
	const [table_present, setTablePresent] = useState(false)

	const [column_stat_list, setColumnStatList] = useState({ready: false, data: []})

	const [ selected_columns, setSelectedColumns ] = useState([])

	var stats_dom = <></>

	useEffect(() => {
		// component did mount
		getDatabases().then((db_list) => {
			setDatabaseList((database_list) => ({...database_list, data: db_list}))
			if (db_list.includes(props.db_name)) {
				// database is present
				setDatabasePresent(true)
				getTables(props.db_name).then((tab_list) => {
					setTableList((table_list) => ({...table_list, data: tab_list}))
					if (tab_list.includes(props.table_name)) {
						setTablePresent(true)
						getSeriesList(props.db_name, props.table_name, 0, Date.now()).then((data) => {
							data.forEach((column) => {
								store.dispatch({
									type: Event.FETCH_INFLUXDB_STAT,
									database: props.db_name,
									table: props.table_name,
									column: column
								})
							})
							setColumnStatList((cs_list) => ({...cs_list, data: data}))
							// select matching columns
							const column_names = data.map((cs) => cs.name)
							setSelectedColumns (props.select_all ? column_names : column_names.filter((name) => props.column_names.includes(name)))
						}).finally(() => {
							setColumnStatList((cs_list) => ({...cs_list, ready: true}))
						})
					}
				}).finally(() => {
					setTableList((table_list) => ({...table_list, ready: true}))
				})
			}
		}).finally(()=> {
			setDatabaseList((database_list) => ({...database_list, ready: true}))
		})
	}, [])

	useEffect(() => {
		// selected columns changed
		props.onSelectColumn && props.onSelectColumn(selected_columns)
	}, [selected_columns])



	if (!database_list.ready) {
		return (<>Caricamento 1/2...</>)
	}

	if (!database_present) {
		console.warn(`InfluxDB database \"${props.db_name}\" was not found in ${JSON.stringify(database_list.data)}`)
		return (<> Il database <strong>{props.db_name}</strong> non trovato. <TooltipListView arr={database_list.data}>Vedi altri</TooltipListView></>)
	}

	if (!table_list.ready) {
		return (<>Caricamento 2/2...</>)
	}

	if (!table_present) {
		console.warn(`InfluxDB table \"${props.table_name}\" of DB \"${props.db_name}\" was not found in ${JSON.stringify(table_list.data)}`)
		return (<>La tabella <strong>{props.table_name}</strong> non è ancora presente nel database <strong>{props.db_name}</strong>. <TooltipListView arr={table_list.data}>Vedi altri</TooltipListView></>)
	}

	
	return (
		<>
			<ColumnStats
				{...props}
				column_stat_list={column_stat_list.data}
				selected_columns={selected_columns}
				onSelectionChange={setSelectedColumns}
			/>
		</>
	)
}