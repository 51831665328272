
import React from 'react'

class Resizer extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {
		return (
			<p className="panel-resize-bar" id={this.props.id}> 
			
			</p>
		)
	}

	componentDidMount() {
		var resize_bar = document.getElementById(this.props.id)
		var panel = document.getElementsByClassName(this.props.target)[0]

		resize_bar.addEventListener('mousedown', initDrag, false);

		var startX, startWidth;
		// var startY, startHeight;

		function initDrag(e) {
			
			startX = e.clientX;
			//startY = e.clientY;
			startWidth = parseInt(document.defaultView.getComputedStyle(panel).width, 10);
			//startHeight = parseInt(document.defaultView.getComputedStyle(panel).height, 10);
			document.documentElement.addEventListener('mousemove', doDrag, false);
			document.documentElement.addEventListener('mouseup', stopDrag, false);
		}

		function doDrag(e) {
			panel.style.width = (startWidth + e.clientX - startX) + 'px';
			//panel.style.height = (startHeight + e.clientY - startY) + 'px';
		}

		function stopDrag(e) {
			document.documentElement.removeEventListener('mousemove', doDrag, false);
			document.documentElement.removeEventListener('mouseup', stopDrag, false);
		}
	}

	componentWillUnmount() {

	}


}

export default Resizer