import { useState } from "react"
import { connect } from "react-redux"
import { store, Event } from "../redux.js"
import { Button, ButtonGroup, Classes, Dialog, Intent, TextArea } from "@blueprintjs/core"
import { importConfigurationData } from "../api/wda_api.js"
import { updateTree } from "../actions.js"


import ElementGroup from "../components/ElementGroup.js"
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react"

import "../css/Modal.scss"
import { showNotification } from "../modules/Toast.js"

const Callout = ({intent, text}) => {
	return (
		<div class={`bp3-callout bp3-intent-${intent}`}>
			<div class="bp3-heading">{text}</div>
		</div>
	)
}

const ConfigurationImportModal = ({show, cantiere_id, cantiere_name}) => {

	const [format, setFormat ] = useState("json")
	const [import_log, setImportLog] = useState()
	const [message, setMessage] = useState({visible: false, text: "", intent: ""})
	const [verify_state, setVerifyState] = useState({valid: false, completed: false})
	const [busy, setBusy] = useState(false)
	const [code, setCode] = useState("{}")
	const [import_done, setImportDone] = useState(false)

	const dismiss = () => {
		store.dispatch({type: Event.CLOSE_CONFIGURATION_IMPORT_MODAL})
		setImportDone(false)
	}

	const runVerify = () => {
		setVerifyState({valid: false, completed: false})
		setBusy(true)
		setMessage({...message, visible: false})
		importConfigurationData(cantiere_id, format, code, true).then((response) => {
			setVerifyState({valid: true, completed: true})
			setImportLog(response.log)
			showNotification("success", "Verifica OK", response.message)
			setMessage({visible: true, text: response.message, intent: "success"})
		}).catch((e) => {
			setVerifyState({valid: false, completed: true})
			setImportLog(e.data.log)
			setMessage({visible: true, text: e.data.message, intent: "danger"})
		}).finally(() => {
			setBusy(false)
		})
	}

	const runImport = () => {
		setVerifyState({valid: false, completed: false})
		setBusy(true)
		importConfigurationData(cantiere_id, format, code, false).then((response) => {
			setVerifyState({valid: true, completed: true})
			setImportLog(response.log)
			showNotification("success", "Importazione OK", response.message)
			updateTree()
			setImportDone(true)
		}).catch((e) => {
			setVerifyState({valid: false, completed: true})
			setImportLog(e.data.log)
			console.log(e)
		}).finally(() => {
			setBusy(false)
		})
	}

	return (
		<>
			<Dialog
				isOpen={show}
				onClose={dismiss}
				title={<>Import della configurazione: {cantiere_name}</>}
				className="modal-config-import"
			>
				<Classes.DIALOG_BODY className="">
					<ElementGroup caption="Codice" className="area-code">
						<ButtonGroup>
							<Button 
								active={format==="json"}
								onClick={() => {
									setFormat("json")			
							}}>
								JSON
							</Button>
							<Button 
								active={format==="yaml"}
								onClick={() => {
									setFormat("yaml")
							}}>
								YAML
							</Button>

						</ButtonGroup>
						<Editor
							className="editor"
							defaultLanguage={format}
							value={code}
							onChange={(value) => {setCode(value)}}
							language={format}
						/>
					</ElementGroup>
					<ElementGroup caption="Stato" className="area-log">
						<TextArea
							width="100%"
							height="100%"
							readOnly={true}
							value={import_log}
							fill={true}
						/>
						
					</ElementGroup>
					{ message.visible && <Callout text={message.text} intent={message.intent} /> }

				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					{ !import_done ?
						<>
							<Button
								disabled={busy}
								onClick={runVerify}
								intent={
									verify_state.completed && verify_state.valid ? Intent.SUCCESS : 
									verify_state.completed && !verify_state.valid ? Intent.DANGER : Intent.NONE
								}
							>
								{busy ? <>Attendere prego</> : <>Verifica</>}
							</Button>
							<Button
								disabled={busy || !(verify_state.completed && verify_state.valid)}
								onClick={runImport}
							>
								Esegui
							</Button>
							<Button
								onClick={dismiss}
							>
								Annulla
							</Button> 
						</>
						:
						<>
							<Button 
								onClick={dismiss}
							>
								Chiudi
							</Button>
						</>
					}
				</Classes.DIALOG_FOOTER>
			</Dialog>
		</>
	)
}


const mapStoreToProps = (store) => {
	return ({
		show : store.modal_config_import.show,
		args : store.modal_config_import.args,
		cantiere_id : store.current_cantiere_id,
		cantiere_name : store.current_cantiere_data ? store.current_cantiere_data.name : ""
	})
}

export default connect(mapStoreToProps)(ConfigurationImportModal)