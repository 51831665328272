
import React from 'react'
import DebugView from '../views/DebugView.js'
import Userbar from '../views/CurrentUserView.js'
import DataloggerView from '../views/DataloggerView.js'
import MapView from '../views/MapView.js'
import DetailsView from '../views/DetailsView.js'
import Resizer from '../components/Resizer.js'
import ControlPanelView from '../views/ControlPanelView.js'

import { getDataloggersByCantiere } from '../api/wda_api.js'

import '../css/OverviewPage.css'

import { connect } from 'react-redux'

const OverviewPage = (props) => {
	return (
		<div className="page page-overview">
			<Userbar user={props.user}/>
			<ControlPanelView user={props.user}/>
			<Resizer id="panel-resize-bar-1" target="view-dataloggers" />
			<DataloggerView user={props.user} />
			<MapView />
			<DetailsView />
			
		</div>
	)
}

const mapStoreToProps = ((store) => {
	return ({
		user : store.current_user
	});
})

export default connect (mapStoreToProps)(OverviewPage)

