import React from 'react'
import { logout } from '../actions.js'
import { store, Event } from '../redux.js'
import { Icon, Intent } from '@blueprintjs/core'

import '../css/CurrentUserView.css'

const Userbar = (props) => {
	return (
		<div className='view view-current-user'>
			<div className='area area-user-icon'>
				<Icon icon='mugshot' iconSize="100%" intent={Intent.PRIMARY} />
			</div>
			<div className='area area-user-name'>
				{props.user.name}<br/>
				<div className='fake-link' onClick={()=> {
					logout()
				}}> 
					<Icon icon="log-out"/> Logout 
				</div>
			</div>
		</div>
	)
}

export default Userbar