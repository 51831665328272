
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Tooltip } from "@blueprintjs/core"
import { store, Event } from "../../redux.js"
import { ModifyButton, RemoveButton, RemoveFromMapButton, ShowOnMapButton } from '../../modules/Buttons.js'
import { MapMenu, getOwnMapObjects } from '../DetailsView.js'
import ElementGroup from '../../components/ElementGroup.js'
import ShowOnGrafana from '../../components/ShowOnGrafana.js'
import { getInfluxDBStat } from '../../actions.js'

const NewChannelButton = (props) => {
	return (
		<Button 
			icon="tag" 
			onClick={() => {	
				store.dispatch({
					type: Event.OPEN_DIALOG,
					modal_type: "create",
					modal_args: {object: "channel", parent_id: props.data.id}
				})
			}}
		>
			Nuovo canale
		</Button>
	)
}


const InfluxStatView = (props) => {
	const toDtString = (timestamp) => {
		// <3 stackoverflow
		var date = new Date(timestamp * 1000);
		return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
	}
	return( 
		<>
			<Tooltip
				content={
					<>
						<h3>{props.channel}</h3>
						<strong>Tot. misure: </strong> {props.count}<br/>
						<strong>Data prima misura: </strong> {toDtString(props.t_first)}<br/>
						<strong>Data ultima misura: </strong> {toDtString(props.t_last)}<br/>
						<strong>Valore medio: </strong> {props.avg.toFixed(3)}<br/>
						<strong>Valore minimo: </strong> {props.min.toFixed(3)}<br/>
						<strong>Valore massimo: </strong> {props.max.toFixed(3)}
					</>
				}
			>
				<a>{props.channel}</a> 
			</Tooltip>
			: {props.count} misure
			<br/>
		</>
	)

}

const DetailsViewDevice = (props) => {
	const { user, is_admin, is_editor, is_super } = props
	const self_map_object_ids = getOwnMapObjects(props.map_objects, "device", props.data.id).map((e) => e.id)
	const state = store.getState()
	const db_name = state.current_cantiere_data.db_name_raw
	const own_name = props.data.name
	const stat_columns = props.influxdb_stats.filter((col) => {return (col.database === db_name && col.table === own_name)})
	
	const [stat_loading, setStatLoading ] = useState(true)

	useEffect(() => {
		setStatLoading(true)
		getInfluxDBStat(db_name, own_name).finally(() => {
			setStatLoading(false)
		})
	}, [props.data.name])

	return (
		<>
			<div className='area area-details-content'>
				<h3>{own_name}</h3>
				<ShowOnGrafana {...props} />
				{props.data.description}<br/>
				<Icon icon='database'/>&nbsp;{db_name} <br/><br/>
				{stat_loading && <strong>Caricamento...</strong>}
				<p>
					{
						(stat_columns.length > 0) ? stat_columns.map((col) => {
							return (
								<InfluxStatView {...col} />
							)
						}) : <> Nessun dato </>
					}
				</p>

			</div>

			<div className='area area-details-summary'>
				{(is_super || is_admin || is_editor) && <NewChannelButton {...props} />}<br/><br/>
				<MapMenu {...props} map_object_refs={self_map_object_ids} /><br/>
			</div>
			
			<div className='area area-details-footer'>
				{(is_super || is_admin || is_editor) && <ModifyButton {...props} />}
				{(is_super || is_admin) && <RemoveButton {...props} />}
			</div>
			
		</>
	)
}

const mapStateToProps = ((store) => {
	return ({
		influxdb_stats: store.influxdb_stats
	})
})


export default connect(mapStateToProps)(DetailsViewDevice)