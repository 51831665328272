import React from "react"
import { useEffect, useState } from "react"
import { NumericInput } from '@blueprintjs/core'
import { FormValue } from '../../components/Form.js'


const TimeIntervalInput = (props) => {
	const { defaultValue, onChange, label, className = ""} = props
	const [hours, setHours] = useState(Math.floor(defaultValue / 3600))
	const [minutes, setMinutes] = useState(Math.floor(defaultValue / 60) % 60)
	const [seconds, setSeconds] = useState(defaultValue % 60)

	useEffect(() => {
		onChange(hours * 3600 + minutes * 60 + seconds) // raw seconds
	}, [hours, minutes, seconds])

	return (
		<FormValue label={label} className={className}>
			<NumericInput
				value={hours}
				onValueChange={(val) => {
					setHours(val)
				}}
				min={0}
				max={100000}
				clampValueOnBlur={true}
				allowNumericCharactersOnly={true}
				className="time-interval-input-hours"
			/>
			<div className='time-interval-label-hours'>ore</div>
			<NumericInput
				value={minutes}
				onValueChange={(val) => {
					setMinutes(val)
				}}
				min={0}
				max={59}
				clampValueOnBlur={true}
				allowNumericCharactersOnly={true}
				className="time-interval-input-minutes"
			/>
			<div className='time-interval-label-minutes'>minuti</div>
			<NumericInput
				value={seconds}
				onValueChange={(val) => {
					setSeconds(val)
				}}
				min={0}
				max={59}
				clampValueOnBlur={true}
				allowNumericCharactersOnly={true}
				className="time-interval-input-seconds"
			/>
			<div className='time-interval-label-seconds'>secondi</div>
		</FormValue> 
	)
}	


export default TimeIntervalInput