
import React, { useState, useEffect } from 'react'
import {DropdownMenu, TypeSelector, StringValue, TextValue, TimeValue, PathValue, FormValue, ValueCheckbox } from '../components/Form.js'
import ElementGroup from "../components/ElementGroup.js"
import '../css/Form.scss'
import { getDataloggerTypes } from '../api/wda_api.js'
import  { Button } from "@blueprintjs/core"
import StartDate from "./components/StartDate.js"
import {store, Event} from "../redux.js"
import { getCurrentMapObjects } from "../actions.js"
import { RemoveButton, RemoveFromMapButton } from "../modules/Buttons.js"

const FormDataloggerCreate = (props) => {
	const [ dropdown_options, setDropdownOptions ] = useState([])
	const [ dropdown_loading, setDropdownLoading ] = useState(true)
	const [ new_values, setNewValues ] = useState(props.data)
	const [ datalogger_types, setDataloggerTypes ] = useState([])
	const [ current_datalogger_type, setCurrentDataloggerType ] = useState ({ id : props.data.type, has_path : true })
	
	const currentDataloggerTypeToDropdownOption = () => {
		const options = datalogger_types.filter((_type) => _type.id == current_datalogger_type.id)
		if (options.length > 0)
			return { label : options[0].name, value : options[0] }
		return { label: "< predefinito >", value: { id: 0, has_path: true } }
	}
	
	const onChange = (field, value) => {
		if (typeof(field) === "object")  {
			setNewValues ({...new_values, ...field})
			props.onChange(field)
		}
		else {
			setNewValues ({...new_values, [field]: value})
			props.onChange(field, value)
		}
	}
	
	useEffect (() => {
		/* componentDidMount */
		getDataloggerTypes().then((data) => {
			// datalogger types loaded
			const match_datalogger = data.filter((dl_type) => dl_type.id === props.data.type)
			setDataloggerTypes(data)
			if (match_datalogger.length > 0)
				setCurrentDataloggerType(match_datalogger[0])
		}).finally(()=> {
			setDropdownLoading (false)
		})
	}, [])

	const map_object_refs = getCurrentMapObjects()
		
	return (
		<div className='form'>
			<ElementGroup caption="Proprietà">
				<StringValue
					label="Nome"
					defaultValue={props.data.name}
					modified = {props.data.name !== new_values.name}
					onChange={(event)=> {
						onChange("name", event.target.value)
					}}
				/>

				{dropdown_loading ? <></> : 
					<TypeSelector
						label="Tipo"
						value={currentDataloggerTypeToDropdownOption()}
						modified = {props.data.type !== new_values.type}
						onChange={(option)=> {
							setCurrentDataloggerType(option.value)
							if (!option.value.has_path)
								onChange({
									import_path : "",
									archive_path : "",
									type: option.value.id
								})
							else 
								onChange("type", option.value.id)
						}}
						options={datalogger_types.map((e) => {
							return {
								label: e.name,
								value: e,
								manufacturer: e.manufacturer
							}}
						)}
						isLoading={dropdown_loading}
					/>
				}

				{ current_datalogger_type.has_priority && 	
					<FormValue>
						<ValueCheckbox 
							label="Alta priorità" 
							defaultValue={props.data.priority}
							onChange={(e)=> {
								onChange("priority", e.target.checked)
							}}
						/>
					</FormValue>
				}

				<StringValue
					label="Numero di serie"
					defaultValue={props.data.sn}
					placeholder="S/N, MAC, ecc"
					modified = {props.data.sn !== new_values.sn}
					onChange={(event)=> {
						onChange("sn", event.target.value)
						
					}}
				/>
				{ current_datalogger_type.has_path && 
					<>
						<PathValue
							label="Path"
							defaultValue={props.data.import_path}
							modified = {props.data.import_path !== new_values.import_path}
							onChange={(value, real_path)=> {
								onChange({
									import_path: value,
									real_path: real_path
								})
							}}
						/>

						<PathValue
							label="Path Archivio"
							defaultValue={props.data.archive_path}
							modified = {props.data.archive_path !== new_values.archive_path}
							onChange={(value, real_path)=> {
								onChange("archive_path", value)
							}}
						/>
					</>
				}

				<FormValue 
					label={<>Inizio <br/>d'importazione</>}
					modified= {props.data.last_import !== new_values.last_import}
				>
					<StartDate
						default_date={new Date(props.data.last_import * 1000)}
						onChange={(new_date) => {
							onChange("last_import", Math.floor(new_date.getTime() / 1000))
						}}
					/>
				</FormValue>

				<TimeValue
					label={<>Tempo <br/> allarme (s)</>}
					defaultValue={props.data.alarm_period}
					modified = {props.data.alarm_period !== new_values.alarm_period}
					onChange={(value)=> {
						onChange("alarm_period", value)
					}}
				/>

				<TextValue
					label="Descrizione"
					defaultValue={props.data.description}
					placeholder="Descrizione del datalogger"
					modified = {props.data.description !== new_values.description}
					onChange={(event)=> {
						onChange("description", event.target.value)
					}}
				/>
			</ElementGroup>

			<ElementGroup caption = "Grafana">
				<StringValue 
					label="URL" 
					defaultValue={props.data.grafana_url}
					onChange={(e)=> {
						onChange("grafana_url", e.target.value)
					}}
				/>
			</ElementGroup>

			<ElementGroup caption="Azioni">
				<Button 
					icon='inbox'
					onClick={()=> {
						store.dispatch({
							type: Event.OPEN_DIALOG,
							modal_type: "create",
							modal_args: {object: "device", parent_id: store.getState().details.data.id}
						})
					}}
				>	
					Nuovo acquisitore
				</Button>&nbsp;
				<RemoveFromMapButton
					ready={true}
					map_object_refs={map_object_refs}
					type="datalogger"
					target_id_list={[store.getState().details.data.id]}
				/>&nbsp;
				<RemoveButton
					ready={true}
					{...store.getState().details}
				/>
			</ElementGroup>
			
		</div>	
	)
}

export default FormDataloggerCreate