
import { getData, getData2, postData2 } from './requests.js'
import { store, Event} from '../redux.js'

export const login = (username, password) => {
	return postData2('/api/auth/login', {'username' : username, 'password' : password});
}

export const logout = () => {
	return getData('/api/auth/logout');
}


export const getCurrentUser = () => {
	return getData2('/api/user/info');
}

export const getCurrentUser2 = () => {
	return getData2('/api/user/info');
}

/*
const updateCurrentUser = () => {
	getCurrentUser().then((user)=> {
		store.dispatch ({type: Event.SET_CURRENT_USER, user: user});
		
	}, (code, err) => {
		store.dispatch ({type: Event.SET_CURRENT_USER, user: null});
	})
}
*/
