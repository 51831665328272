
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import React from 'react';
import { getMapObjectIdListByObject, openConfigModal, openDeleteModal, showOnMap } from '../actions.js';
import { Event, store } from '../redux.js';

export const ModifyButton = (props) => {
	return (
		<Button 
			disabled={!props.ready}
			icon="edit"
			onClick={()=> {
				openConfigModal(props.type, props.data.id)
			}}
		>
			Modifica
		</Button>
	);
};


export const RemoveButton = (props) => {
	return (
		<Button 
			disabled={!props.ready}
			icon="trash" 
			onClick={() => {
				openDeleteModal(props.type, props.data.id)
			}}
		>
			Rimuovi
		</Button>
	)
}


export const RemoveFromMapButton = (props) => {
	return (
		<Button 
			disabled={!props.ready || props.map_object_refs.length == 0}
			icon="cross"
			onClick={() => {
				store.dispatch({
					type: Event.OPEN_DIALOG,
					modal_type: "remove",
					modal_args: {object: "map_objects", target_type: props.type, target_id_list: props.map_object_refs}
				})
			}}
		>
			Cancella dalla mappa ({props.map_object_refs.length})
		</Button>
	)
}


export const ShowOnMapButton = (props) => {
	const ShowOnMapMenuButton = (props) => {
		return (
			<Button 
				icon="map-marker" 
				onClick={()=> {
					if (props.type === 'cantiere') {
						store.dispatch({
							type: Event.SHOW_CANTIERE_ON_MAP,
							id: props.data.id,
							name: props.data.name,
							x: props.data.gps_lat,
							y: props.data.gps_lon
						})
					}
					else {
						const state = store.getState()
						const map_object_id_list = getMapObjectIdListByObject(state.details.type, state.details.data.id)
						showOnMap(props.map_id, map_object_id_list[Math.floor(Math.random() * map_object_id_list.length)]) // pick random one from available relevant target map object

					}
				}}
			>
				Mostra sulla mappa
			</Button>
		)
	}

	// gather names and ids of all relative maps
	 var map_id_by_name = {};

	const map_objects = props.map_objects.filter((mo) => props.map_object_refs.includes(mo.id))		// list of relevant map objects
	const rel_maps = props.maps.filter((m) => map_objects.map((mo) => mo.map).includes(m['id']))		// list of relevant maps
	 
	if (rel_maps.length === 1)
		return (
			<>
				<ShowOnMapMenuButton map_id={rel_maps[0].id} {...props} />
			</>
		)
	return (
		<>
			<Popover position={Position.LEFT}
				content={
					<Menu >
						{
							rel_maps.map((m) => {
								return (
									<MenuItem text={m.name} onClick={() => {showOnMap(m.id, -1)}} key={m.id}></MenuItem>
								)
							})
						}
					</Menu>
			}
			>
				<Button icon="map-marker" >Mostra sulla mappa...</Button>
			</Popover>
		</>
	)
}


export const ModifyMapObjectButton = (props) => {
	return (
		<Button 
			icon="annotation"
			onClick={()=>{
				store.dispatch({
					type: Event.OPEN_MAP_OBJECT_EDITOR
				})
			}}
			{...props}
		>
			Modifica l'oggetto
		</Button>
	)
}


export const NewVirtualChannelButton = (props) => {
	return (
		<Button icon='plus' onClick={() => {
			store.dispatch({
				type: Event.OPEN_DIALOG,
				modal_type: "create",
				modal_args: {object: "vchannel", parent_id: props.cantiere_id}
			})
		}}>
			Nuovo canale virtuale
		</Button>
	)
}
