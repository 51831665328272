import React, { useEffect } from 'react';
import '@blueprintjs/core'

import '@blueprintjs/core/lib/css/blueprint.css'
import "./theme.scss"


import LoginPage                          from "./pages/LoginPage.js"
import OverviewPage                       from "./pages/OverviewPage.js"
import ConfigModal                        from "./modals/ConfigModal.js"
import CreateModal                        from "./modals/CreateModal.js"
import NewMapModal                        from "./modals/NewMapModal.js"
import DeleteMapModal                     from "./modals/DeleteMapModal.js"
import DeleteConfirmationModal            from './modals/DeleteConfirmationModal.js'
import InfluxManagerModal                 from './modals/InfluxManagerModal.js'
import MediaManagerModal                  from './modals/MediaManagerModal.js'
import ConfigurationImportModal           from './modals/ConfigurationImportModal.js'
import DataAlarmModal                     from './modals/DataAlarmModal.js'
import CantiereImportModal                from './modals/CantiereImportModal.js'
import VirtualChannelSummaryModal         from './modals/VirtualChannelSummaryModal.js'
import VirtualChannelGroupOperationsModal from './modals/VirtualChannelGroupOperationsModal.js'
import FeedbackModal                      from './modals/FeedbackModal.js'
import MapObjectEditorModal               from './modals/MapObjectEditorModal.js'
import FilePickerModal                    from './modals/FilePickerModal.js'

import { ProgressBar }      from '@blueprintjs/core'
import { Event, store }     from './redux.js'
import { connect }          from 'react-redux'
import { main }             from './actions.js'

import { FocusStyleManager } from "@blueprintjs/core"

import './css/Modal.scss'


/* import { updateCurrentUser } from './api/login_api.js' */

const App = (props) => {
	const propgress_bar = (props.pending_ops > 0) ? <ProgressBar value={1.0/props.pending_ops} /> : <></>
	var content = <></>
	
	useEffect (() => {
		// componentDidMount
		FocusStyleManager.onlyShowFocusOnTabs();
		main()
	}, [])
		
	if (!props.logged_in)
		content = <LoginPage />
		
	else
		content = <>
			<ConfigModal />
			<CreateModal />
			<DeleteConfirmationModal />
			<DeleteMapModal />
			<NewMapModal />
			<OverviewPage />
			{props.modal_influxdb_manager && <InfluxManagerModal />}
			<MediaManagerModal />
			<MapObjectEditorModal />
			<FilePickerModal />
			<VirtualChannelSummaryModal />
			<FeedbackModal />
			<CantiereImportModal />
			<ConfigurationImportModal />
			<DataAlarmModal />
			<VirtualChannelGroupOperationsModal />
		</>

	return (
		<div 
			className="App"
			onContextMenu={(e) => {
				e.preventDefault(); // disable browser context menu
			}}
		>
			{propgress_bar}
			{content}
		</div>
	);
	

}

const mapStateToProps = (state) => {
	return ({
		logged_in               : state.logged_in,
		current_user            : state.current_user,
		pending_ops             : state.pending_ops,
		modal_influxdb_manager  : state.modal_influxdb_manager
	})
}


export default connect(mapStateToProps)(App);
