import React from 'react'
import '../css/MapView.scss'
import MapSelector from '../modules/MapSelector.js'
import MapLabelSwitch from '../modules/MapLabelSwitch.js'
import LocalMap from '../modules/LocalMap.js'
import WorldMap from '../modules/WorldMap.js'
import { ModifyMapObjectButton } from '../modules/Buttons.js'

import { connect } from "react-redux"
import { store } from "../redux.js"

import "leaflet/dist/leaflet.css"
import MapPlaceholder from "../components/MapPlaceholder.js"


const MapView = (props) => {
	const { current_map, selected_object } = props
	const map_is_selected = current_map.id > 0
	return (
		<div className="view view-map">
			<div className="area area-map-selector">
				<MapSelector />
			</div>
			{map_is_selected &&
				<div className="area area-map-overlay">
					<br/><br/><br/>
					<div className="map-label-switch-container">
						<MapLabelSwitch />
						{ /* {props.selected_object && <ModifyMapObjectButton style={{marginBottom: "10px"}} /> } */ }
					</div>
				</div>
			}
			{
				map_is_selected ? (current_map.world ? <WorldMap /> : <LocalMap />) : <MapPlaceholder />	
			}
		</div>
	)
}

const mapStoreToProps = ((store) => {
	return ({
		current_map : store.current_map,
		selected_object : store.selected_map_object_id
	})
})

export default connect(mapStoreToProps)(MapView)