import React from "react"
import { Icon } from "@blueprintjs/core"
import { openCantiereCreateModal, openCantiereImportModal, openModalNewMap, openConfigModal, getCantiereGrafanaUrl, isCorrectUrl, selectCantiere } from "../actions.js"
import { connect } from "react-redux"

const NoCantierePlaceholder = () => {
	return (
		<div className="area map-placeholder-no-cantiere">
			<p>
				<a 
					href="#"
					onClick={(e) => {
						e.preventDefault()
						openCantiereCreateModal()
				}}>
					[+] Nuovo cantiere v1
				</a>
			</p>
			<p>
				<a 
					href="#"
					onClick={(e) => {
						e.preventDefault()
						openCantiereImportModal()
				}}>
					[+] Nuovo cantiere v2
				</a>
			</p>
		</div>
	)
}


const MapPlaceholder = (props) => {
	const { cantiere_data, cantiere_id, is_super, is_admin, is_editor } = props
	const cantiere_grafana_url = getCantiereGrafanaUrl();
	const cantiere_is_selected = cantiere_id > 0
	

	if (cantiere_is_selected) {
		return (
			<div className="area map-placeholder">
				<div>
					<div className="title">		
						{ cantiere_data.name }
					</div>
					{!cantiere_data.enabled && 
						<h3>Questo cantiere e' disabilitato</h3>
					}
					<br/>
					{
						(is_super || is_admin) &&
						<>
							<p>
								<a 
									herf="#"
									onClick={(e) => {
										e.preventDefault()
										selectCantiere(cantiere_data.name).then(() => {
											openConfigModal("cantiere", cantiere_id)
											/*
												type: Event.OPEN_DIALOG,
												modal_type : "config",
												modal_args : {object: "cantiere", id : cantiere_id}
											})
											*/
										})
									}}
								>
									<Icon icon="edit" size={20} />
									&nbsp;&nbsp; Modifica...
								</a>
							</p>
							<br/>
						</>
					}
					{ isCorrectUrl(cantiere_grafana_url) && 
						<p>
							<a href={cantiere_grafana_url} target="_blank">
								<Icon icon="timeline-line-chart" size={20} /> 
								&nbsp;&nbsp; Apri il grafico
							</a>
						</p>
					}
					{ (is_super || is_admin || is_editor) && 
					<p>
						<a 
							href="#"
							onClick={(e) => {
								e.preventDefault()
								openModalNewMap()
							}}
						>
							<Icon icon="map-create" size={20} />
							&nbsp;&nbsp; Nuova mappa
						</a>
					</p>
					}
				</div>
			</div>
		)
	}
	return (<NoCantierePlaceholder />)
}

const mapStateToProps = (state) => {
	return ({
		cantiere_data: state.current_cantiere_data,
		cantiere_id: state.current_cantiere_id,
		is_super: state.current_user && state.current_user.role === "super",
		is_admin: state.current_user && Object.keys(state.current_user.member_of).includes(state.current_cantiere) && state.current_user.member_of[state.current_cantiere] === "Admin",
		is_editor: state.current_user && Object.keys(state.current_user.member_of).includes(state.current_cantiere) && state.current_user.member_of[state.current_cantiere] === "Editor"
	})
}

export default connect(mapStateToProps)(MapPlaceholder)