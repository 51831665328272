
import React, { useState, useEffect, useReducer } from 'react'
import { Dialog, Classes, Button, Intent, Icon, Spinner } from '@blueprintjs/core'
import { Column, Table, Cell } from '@blueprintjs/table'

import { connect } from "react-redux"
import { store, Event } from "../redux.js"
import "../css/VirtualChannelSummary.css"

import { NewVirtualChannelButton } from '../modules/Buttons.js'
import { getVirtualChannelSummary } from '../actions.js'

const VarsRow = (props) => {
	const date = new Date(props.time)
	const time_delta = props.time - props.first_measurement
	return (
		<>
		<td>{props.name}</td>
		<td className="cell-value">{props.value.toFixed(2)}</td>
		<td>{date.toLocaleTimeString()}</td>
		<td>{time_delta}</td>
		</>
	)
}


const VirtualChannelRow = (props) => {
	// props: data { last_import, value, vars }, id, expression
	const [ data, setData ] = useState(props.data)

	const first_measurement_ts = data.vars ? Math.min(...data.vars.map((e) => (e.time))) : 0
	const last_import_date = new Date(data.last_import)
	const row_span = data.vars.length
	const vars_rows = data.vars.map((v) => <VarsRow {...v} first_measurement={first_measurement_ts} key={v.name} />)
	var vars_first_row = <td>Non ancora disponibile</td>
	var vars_rows_more = []
	if (vars_rows.length > 0) {
		vars_first_row = vars_rows[0]
		if (vars_rows.length >= 1) {
			vars_rows_more = vars_rows.slice(1)
		}
	}

	return (<>
		{data.vars ?	
				<>
				<tr>
					<td rowSpan={row_span}>{props.name}</td>
					<td rowSpan={row_span}>{last_import_date.toLocaleString()}</td>
					{vars_first_row}
					<td rowSpan={row_span} className="cell-result cell-value">{data.value.toFixed(2)}</td>
					<td rowSpan={row_span}>{props.expression}</td>
				</tr>
				{
					vars_rows_more.map((vr) => <tr key={vr.name}>{vr}</tr>)
				}
					
				</> :
			<tr><td>Nessun informazione ancora disponibile</td></tr>
		}
	</>)
}




const VirtualChannelSummaryModal = (props) => {
	const [virtual_channels_data, setVirtualChannelsData] = useState([])
	const [data_ready, setDataReady ] = useState(false)

	const dismiss = () => {
		store.dispatch({type: Event.CLOSE_VIRTUAL_CHANNEL_SUMMARY})
	}


	const update = () => {
		setDataReady(false)
		getVirtualChannelSummary().finally(() => {
			setDataReady(true)
		})
	}

	useEffect(() => {
		if (props.show)
			update()
	}, [props.show])

	return (
		<Dialog
			isOpen={props.show}
			onClose={dismiss}
			title="Canali virtuali"
			className="modal-virtual-channel-summary"
		>
			<Classes.DIALOG_BODY>
				{	
					<table className={`${data_ready ? "" : "table_loading"}`}>
						<tbody>
							<tr>
								<th rowSpan={2}>Nome</th>
								<th rowSpan={2}>Data</th>
								<th colSpan={4}>Variabili</th>
								<th rowSpan={2}>Risultato</th>
								<th rowSpan={2}>Formula</th>
							</tr>
							<tr>
								{["Variabile", "Valore", "Data", "Ritardo (ms)"].map((td) => (<th>{td}</th>))}
							</tr>
							{props.vc_data.map((vc) => {
								return (
									<VirtualChannelRow {...vc} key={vc.id} />
								)
							})}
						</tbody>
					</table>
				}
				
			</Classes.DIALOG_BODY>

			<Classes.DIALOG_FOOTER>
				<Button
					intent={Intent.SUCCESS}
					onClick={update}
					icon='refresh'
					disabled={!data_ready}
				>
					Aggiorna
				</Button>
				<Button
					onClick={dismiss}
				>
					Chiudi
				</Button>
			</Classes.DIALOG_FOOTER>

		</Dialog>
	);

}

const mapStoreToProps = (store) => {
	return ({
		show : store.modal_virtual_channel_summary.show,
		args : store.modal_virtual_channel_summary.args,
		vc_data : store.virtual_channel_stats
	})
}

export default connect(mapStoreToProps)(VirtualChannelSummaryModal)