
import { createStore } from 'redux'

const Event = {
	SET_CURRENT_USER                     : 'SET_CURRENT_USER',
	FETCH_CANTIERE_LIST                  : 'FETCH_CANTIERE_LIST',
	FETCH_CANTIERE_DATA                  : 'FETCH_CANTIERE_DATA',
	SELECT_CANTIERE                      : 'SELECT_CANTIERE',
	LOGOUT                               : 'LOGOUT',
	SHOW_DETAILS                         : 'SHOW_DETAILS',
	OPEN_DIALOG                          : 'OPEN_DIALOG',
	CLOSE_DIALOG                         : 'CLOSE_DIALOG',
	SHOW_ON_MAP                          : 'SHOW_ON_MAP',
	APPLY_CONFIG                         : 'APPLY_CONFIG',
	FETCH_MAPS                           : 'FETCH_MAPS',
	FETCH_MAP_OBJECTS                    : 'FETCH_MAP_OBJECTS',
	SELECT_MAP                           : 'SELECT_MAP',
	FETCH_CURRENT_MAP_OBJECTS            : 'FETCH_CURRENT_MAP_OBJECTS',
	MOVE_MAP_OBJECT                      : 'MOVE_MAP_OBJECT',
	SHOW_CANTIERE_ON_MAP                 : 'SHOW_CANTIERE_ON_MAP',
	REQUEST_BEGIN                        : 'REQUEST_BEGIN',
	REQUEST_END                          : 'REQUEST_END',
	MAP_RENDER                           : 'MAP_RENDER',
	MAP_UPDATE_MARKERS                   : 'MAP_UPDATE_MARKERS',
	DROP_ON_MAP                          : 'DROP_ON_MAP',
	TOGGLE_MAP_OBJECT_LABELS             : 'TOGGLE_MAP_OBJECT_LABELS',
	OPEN_INFLUXDB_MANAGER                : 'OPEN_INFLUXDB_MANAGER',
	CLOSE_INFLUXDB_MANAGER               : 'CLOSE_INFLUXDB_MANAGER',
	OPEN_MEDIA_MANAGER                   : 'OPEN_MEDIA_MANAGER',
	CLOSE_MEDIA_MANAGER                  : 'CLOSE_MEDIA_MANAGER',
	SELECT_MAP_OBJECT                    : 'SELECT_MAP_OBJECT',
	OPEN_MAP_OBJECT_EDITOR               : 'OPEN_MAP_OBJECT_EDITOR',
	CLOSE_MAP_OBJECT_EDITOR              : 'CLOSE_MAP_OBJECT_EDITOR',
	SET_SHOW_ON_MAP_HANDLER              : 'SET_SHOW_ON_MAP_HANDLER',
	OPEN_FILE_PICKER                     : 'OPEN_FILE_PICKER',
	CLOSE_FILE_PICKER                    : 'CLOSE_FILE_PICKER',
	OPEN_VIRTUAL_CHANNEL_SUMMARY         : 'OPEN_VIRTUAL_CHANNEL_SUMMARY',
	CLOSE_VIRTUAL_CHANNEL_SUMMARY        : 'CLOSE_VIRTUAL_CHANNEL_SUMMARY',
	OPEN_FEEDBACK_MODAL                  : 'OPEN_FEEDBACK_MODAL',
	CLOSE_FEEDBACK_MODAL                 : 'CLOSE_FEEDBACK_MODAL',
	OPEN_CANTIERE_IMPORT_MODAL           : 'OPEN_CANTIERE_IMPORT_MODAL',
	CLOSE_CANTIERE_IMPORT_MODAL          : 'CLOSE_CANTIERE_IMPORT_MODAL',
	FETCH_INFLUXDB_STAT                  : 'FETCH_INFLUXDB_STAT',
	FETCH_VIRTUAL_CHANNELS_STAT          : 'FETCH_VIRTUAL_CHANNELS_STAT',
	OPEN_DELETE_CONFIRMATION             : 'OPEN_DELETE_CONFIRMATION',
	CLOSE_DELETE_CONFIRMATION            : 'CLOSE_DELETE_CONFIRMATION',
	OPEN_CONFIGURATION_IMPORT_MODAL      : 'OPEN_CONFIGURATION_IMPORT_MODAL',
	CLOSE_CONFIGURATION_IMPORT_MODAL     : 'CLOSE_CONFIGURATION_IMPORT_MODAL',
	OPEN_DATA_ALARM_CONFIG_MODAL         : 'OPEN_DATA_ALARM_CONFIG_MODAL',
	CLOSE_DATA_ALARM_CONFIG_MODAL        : 'CLOSE_DATA_ALARM_CONFIG_MODAL',
	OPEN_VCHANNEL_GROUP_OPS_MODAL        : 'OPEN_VCHANNEL_GROUP_OPS_MODAL',
	CLOSE_VCHANNEL_GROUP_OPS_MODAL       : 'CLOSE_VCHANNEL_GROUP_OPS_MODAL'
}


const initial_state = {
	current_user: null,
	default_cantiere: null,
	current_cantiere: null,
	current_cantiere_id : -1,
	current_cantiere_data: {}, // TODO: replace all 'current_cantiere' and 'current_cantiere_id' usages with this objects
	logged_in: false,
	last_event: "",
	event_history: [" - ", " - ", " - ", " - ", " - ", " - ", " - ", " - ", " - ", " - "],
	pending_ops : {
		cantieri : 0,
		dataloggers : 0,
		maps : 0,
		map_objects : 0
	},
	//lang: 'en',
	details : {
		type : "",
		data : {
			id : 0
		}
	},
	drag_object : {
		id : 0,
		type: "",
		clientX: 0,
		clientY: 0
	},
	cantiere_ready : false,
	member_of: {},
	cantieri: [],
	dataloggers: [],
	devices: [],
	channels : [],
	vchannels : [],
	vchannel_groups: [],
	maps: [],
	map_objects : [],
	current_map_objects : [],
	current_map: {
		id : -1,
		world : true,
		name : "Mappa mondo",
		width: 0,
		height: 0,
		image : null
	},
	modal_open : false,
	modal_type : null,
	modal_args : {
		object : "",
		parent_id: 0
	},
	show_map_object_labels: JSON.parse(localStorage.getItem("show_map_object_labels")), // localstorage can store only strings
	show_on_map_handler: null,
	marker_details : null,
	modal_influxdb_manager : false,
	modal_media_manager : false,
	modal_map_object_editor : false,
	modal_file_picker             : { show: false, args: {}},
	modal_virtual_channel_summary : { show: false, args: {}},
	modal_feedback                : { show: false, args: {}},
	modal_cantiere_import         : { show: false, args: {}},
	modal_delete_confirmation     : { show: false, args: {}},
	modal_config_import           : { show: false, args: {}},
	modal_data_alarm_config       : { show: false, args: {}},
	modal_vchannel_group_ops      : { show: false, args: {}},
	selected_map_object : {
		id: null,
		target_type: null,
		target_id: 0
	},
	influxdb_stats: [],
	virtual_channel_stats: []
}


const default_event_handler = (state, event) => {
	// remove request_begin, request_end from state history
	if ([Event.REQUEST_BEGIN, Event.REQUEST_END].includes(event.type))
		return state
	var history = [...state.event_history];
	history.unshift(event.type);
	history.pop();
	var mixins = {
		last_event : event.type,
		event_history : history
	}
	return {
		...state,
		...mixins
	}
}

const event_handler_users = {
	[Event.SET_CURRENT_USER] : (state, event) => {
		return ({
			current_user: event.user,
			default_cantiere : event.user && event.user.current_org,
			current_cantiere : event.user && event.user.current_org,
			member_of: event.user != null ? event.user.member_of : {},
			logged_in: event.user != null,
		})
	},

	[Event.LOGOUT]: (state, event) => {
		return ({
			logged_in: false,
			current_cantiere : null,
			current_cantiere_id : -1,
			current_canitere_data: {},
			current_user: null
		})
	}
}


const event_handler_cantiere = {
	[Event.FETCH_CANTIERE_LIST] : (state, event) => {
		return ({
			cantieri : event.cantieri
		})
	},

	[Event.SELECT_CANTIERE] : (state, event) => {
		const match = state.cantieri.filter((c) => c.name == event.name)
		const id = (match.length > 0) ? match[0].id : 0
		
		return ({
			current_cantiere: event.name,
			current_cantiere_id: id,
			current_cantiere_data : state.cantieri.filter((e)=> e.id === id)[0],
			maps : [],
			map_objects : [],
			cantiere_ready : false	
		})
	},
	
	[Event.SHOW_DETAILS] : (state, event) => {
		return ({
			details : {
				type : event.object,
				data : event.data
			}
		})
	}, 

	[Event.FETCH_CANTIERE_DATA] : (state, event) => {
		return ({
			current_cantiere_id : event.id,
			current_cantiere_data : state.cantieri.filter((e)=> e.id === event.id)[0],
			dataloggers : event.dataloggers,
			devices : event.devices,
			channels : event.channels,
			vchannels : event.vchannels,
			vchannel_groups : event.vchannel_groups,
			cantiere_ready : true
		})
	}
}

const event_handler_vchannels = {
	[Event.FETCH_VIRTUAL_CHANNELS_STAT] : (state, event) => {
		return ({
			virtual_channel_stats : event.virtual_channel_stats
		})
	}
}


const event_handler_misc = {
	[Event.REQUEST_BEGIN] : (state, event) => {
		return ({
			pending_ops: {
				...state.pending_ops,
				[event.object] : 1 // or +1 
			}
		})
	},

	[Event.REQUEST_END] : (state, event) => {
		return ({
			pending_ops: {
				...state.pending_ops,
				[event.object] : 0 // or -1 
			}
		})
	}
}

const event_handler_modal = {
	[Event.APPLY_CONFIG] : (state, event) => {
		if (event.object === 'cantiere') 
			return {
				cantiere_ready : false
			}
		return {}
	},

	[Event.OPEN_DIALOG] : (state, event) => {
		return ({
			modal_open: true,
			modal_type: event.modal_type,
			modal_args: event.modal_args
		})
		
	},

	[Event.CLOSE_DIALOG] : (state, event) => {
		return ({
			modal_open: false,
			modal_type: null,
			modal_args: {object: null, parent_id: -1}
		})
	},

	[Event.OPEN_INFLUXDB_MANAGER] : (state, event) => {
		return ({
			modal_influxdb_manager : true
		})
	},

	[Event.CLOSE_INFLUXDB_MANAGER] : (state, event) => {
		return ({
			modal_influxdb_manager : false
		})
	},

	[Event.OPEN_MEDIA_MANAGER] : (state, event) => {
		return ({
			modal_media_manager : true
		})
	},

	[Event.CLOSE_MEDIA_MANAGER] : (state, event) => {
		return ({
			modal_media_manager : false
		})
	},

	[Event.OPEN_MAP_OBJECT_EDITOR] : (state, event) => {
		return ({
			modal_map_object_editor : true
		})
	},

	[Event.CLOSE_MAP_OBJECT_EDITOR] : (state, event) => {
		return ({
			modal_map_object_editor : false
		})
	},

	[Event.OPEN_FILE_PICKER] : (state, event) => {
		return ({
			modal_file_picker : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_FILE_PICKER] : (state, event) => {
		return ({
			modal_file_picker : {show: false, args: {}}
		})
	},

	[Event.OPEN_VIRTUAL_CHANNEL_SUMMARY] : (state, event) => {
		return ({
			modal_virtual_channel_summary : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_VIRTUAL_CHANNEL_SUMMARY] : (state, event) => {
		return ({
			modal_virtual_channel_summary : {show: false, args: {}}
		})
	},
	
	[Event.OPEN_FEEDBACK_MODAL] : (state, event) => {
		return ({
			modal_feedback : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_FEEDBACK_MODAL] : (state, event) => {
		return ({
			modal_feedback : {show: false, args: {}}
		})
	},

	[Event.OPEN_CANTIERE_IMPORT_MODAL] : (state, event) => {
		return ({
			modal_cantiere_import : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_CANTIERE_IMPORT_MODAL] : (state, event) => {
		return ({
			modal_cantiere_import : {show: false, args: {}}
		})
	},

	[Event.OPEN_CONFIGURATION_IMPORT_MODAL] : (state, event) => {
		return ({
			modal_config_import : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_CONFIGURATION_IMPORT_MODAL] : (state, event) => {
		return ({
			modal_config_import : {show: false, args: event.args}
		})
	},

	[Event.OPEN_DELETE_CONFIRMATION] : (state, event) => {
		return ({
			modal_delete_confirmation : { show: true, args: event.args}
		})
	},

	[Event.CLOSE_DELETE_CONFIRMATION] : (state, event) => {
		return ({
			modal_delete_confirmation : {show: false, args: {}}
		})
	},

	[Event.OPEN_DATA_ALARM_CONFIG_MODAL] : (state, event) => {
		return ({
			modal_data_alarm_config : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_DATA_ALARM_CONFIG_MODAL] : (state, event) => {
		return ({
			modal_data_alarm_config : {show: false, args: {}}
		})
	},

	[Event.OPEN_VCHANNEL_GROUP_OPS_MODAL] : (state, event) => {
		return ({
			modal_vchannel_group_ops : {show: true, args: event.args}
		})
	},

	[Event.CLOSE_VCHANNEL_GROUP_OPS_MODAL] : (state, event) => {
		return ({
			modal_vchannel_group_ops : {show: false, args: {}}
		})
	}

}

const event_handler_maps = {
	[Event.FETCH_MAPS] : (state, event) => {
		return ({
			maps : event.maps
		})
	},

	[Event.FETCH_MAP_OBJECTS] : (state, event) => {
		return ({
			map_objects : event.map_objects
		})
	},

	[Event.SELECT_MAP] : (state, event) => {
		var map = {}
		map = state.maps.filter((m) => m.id === event.map_id)[0]
		return ({
			current_map : map,
			current_map_objects : []
		})
	},

	[Event.SHOW_CANTIERE_ON_MAP] : (state, event) => {
		return ({
			current_map: {
				id : null,
				name : event.map_object.label,
				world : true, 
				image : `{"lon" : ${event.map_object.x}, "lat" : ${event.map_object.y}, "area" : 100}`},

			current_map_objects: [event.map_object]
		})
	},

	[Event.FETCH_CURRENT_MAP_OBJECTS] : (state, event) => {
		return ({
			current_map_objects : event.map_objects
		})
	},

	[Event.MOVE_MAP_OBJECT] : (state, event) => {
		const objects = state.current_map_objects.map((obj) => { 
			return (obj.id === event.id) ? {
				...obj,
				x : event.x,
				y : event.y
			} : obj;
		})
		return ({
			current_map_objects : objects
		})
	},

	[Event.DROP_ON_MAP] : (state, event) => {
		//const ref = state[event.object].filter((e) => e.id === event.id)[0] // TODO: pontential error
		return ({
			drag_object : {
				target_id: event.target_id || 0,
				target_type: event.target_type || "",
				clientX : event.clientX || 0,
				clientY : event.clientY || 0,
				data: event.data || {}
			}
		})
	},

	[Event.SHOW_MARKER_DETAILS] : (state, event) => {
		return ({
			marker_details : event.data
		})
	},

	[Event.HIDE_MARKER_DETAILS] : (state, event) => {
		return ({marker_details : null})
	},

	[Event.TOGGLE_MAP_OBJECT_LABELS] : (state, event) => {
		return ({show_map_object_labels : !state.show_map_object_labels})
	},

	[Event.SET_SHOW_ON_MAP_HANDLER] : (state, event) => {
		return ({show_on_map_handler : event.handler})
	},

	[Event.SELECT_MAP_OBJECT] : (state, event) => {
		console.log(event.object)
		return ({selected_map_object : event.object})
	}
}

const event_handler_influxdb = {
	[Event.FETCH_INFLUXDB_STAT] : (state, event) => {
		const stats_without_item = state.influxdb_stats.filter((t) => {
			return (t.channel !== event.column.name || t.table !== event.table || t.database !== event.database)
		})
		return ({
			influxdb_stats: [
				...stats_without_item, 
				{
					database : event.database,
					table : event.table,
					channel : event.column.name,
					...event.column
				}
			]
		})
	}
}


const event_handlers = {
	...event_handler_users,
	...event_handler_cantiere,
	...event_handler_modal,
	...event_handler_maps,
	...event_handler_misc,
	...event_handler_vchannels,
	...event_handler_influxdb
}

const reducer = (state, event) => {
	if (!Object.keys(event_handlers).includes(event.type)) { // unknown event type
		//console.info("Unknown event: %s", event.type)
		return default_event_handler(state, event)
	}
	console.info("Event: %s : %o", event.type, event);
	return {
		...default_event_handler(state, event),
		...event_handlers[event.type](state, event)
	}
}

const store = createStore(reducer, initial_state);

export { Event, store }