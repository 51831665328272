
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { modifyCantiere, modifyChannel, modifyDatalogger, modifyDevice, modifyVirtualChannel } from '../actions.js';
import FormCantiereConfig from '../forms/FormCantiereConfig.js';
import FormChannelConfig from '../forms/FormChannelConfig.js';
import FormDataloggerConfig from '../forms/FormDataloggerConfig.js';
import FormDeviceConfig from '../forms/FormDeviceConfig.js';
import FormVirtualChannelConfig from '../forms/FormVirtualChannelConfig.js';
import { Event, store } from '../redux.js';



const function_by_type = {
	"cantiere"        : modifyCantiere,
	"datalogger"      : modifyDatalogger,
	"device"          : modifyDevice,
	"channel"         : modifyChannel,
	"vchannel"        : modifyVirtualChannel
}

const  ConfigModal = (props) => {
	const [ modified_values, setModifiedValues ] = useState({})
	const [ loading, setLoading ] = useState(false)
	
	const hide = () => {
		setModifiedValues({})
		store.dispatch({type: Event.CLOSE_DIALOG});
	}

	const complete = () => {
		setLoading(false)
	}
		
	const apply = () => {
		setLoading(true)
		function_by_type[props.object](props.data.id, modified_values).then(hide).finally(complete) // do not close modal on error 
	}

	const modifyHandler = (field, value) => {
		if (typeof field === "object")
			setModifiedValues ((curr_modified_values) => {
				return ({
					...curr_modified_values,
					...field
				})
			})
		else
			setModifiedValues ((curr_modified_values) => {
				return ({
					...curr_modified_values,
					[field]: value
				})
			})
	}

	const content = () => {
		if (props.object === "cantiere")	
			return (
				<FormCantiereConfig 
					data={{
						...props.data,
						launch_date : new Date(props.data.start * 1000)
					}}
					onChange={modifyHandler}	
				/>
			)

		if (props.object === "datalogger")
			return (
				<FormDataloggerConfig 
					data={props.data} 
					onChange={modifyHandler}
				/>
			)

		if (props.object === "device")
			return (
				<FormDeviceConfig
					data={props.data}
					onChange={modifyHandler}
				/>
			)

		if (props.object === "channel")
			return (
				<FormChannelConfig
					data={props.data}
					onChange={modifyHandler}
				/>
			)
		if (props.object === "vchannel")
			return (
				<FormVirtualChannelConfig
					data={props.data}
					onChange={modifyHandler}
				/>
			)

	}
	return (
		<>
			{props.open && props.type === "config" && <Dialog
				className={`modal-config modal-config-${props.object}`}
				isOpen={true}
				onClose={hide}
				title={`${props.data.name}`}
			>
				<Classes.DIALOG_BODY>
					<div className='modal-content'>
						{content()}
					</div>
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={apply}
						disabled={loading}
					>
						Applica
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	);
}



const mapStoreToProps = (store) => {
	return ({
		open : store.modal_open,
		type : store.modal_type,
		// object_type : store.modal_args,
		object : store.details.type,
		data : store.details.data,
		// last_event : store.last_event
	})
};

export default connect(mapStoreToProps)(ConfigModal)