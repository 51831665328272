
import React, { useState } from 'react'
import { InputGroup, NumericInput } from '@blueprintjs/core'
import ElementGroup from '../components/ElementGroup.js'
import {StringValue, TextValue, FormValue, NumericValue, DropdownMenu, Poly5 } from '../components/Form.js'
import '../css/Form.scss'

import {store, Event} from "../redux.js"
import { getCurrentMapObjects } from "../actions.js"
import { RemoveButton, RemoveFromMapButton } from "../modules/Buttons.js"

const vsat_behavior_options = [
	{
		label: "Nessuno",
		value: 0
	},
	{
		label: "Salta il valore",
		value: 1,
	},
	{
		label: "Limita alla soglia",
		value: 2,
	},
	{
		label: "Duplica precedente",
		value: 3
	}
]

const vSatBehaviorToDropdownOption = (id) => {
	const idx = vsat_behavior_options.findIndex((e) => e.value === id)
	if (idx > -1)
		return { label : vsat_behavior_options[idx].label, value : id }
	return { label: "???", value: 0 }
}

const FormChannelConfig = (props) => {

	const [new_values, setNewValues] = useState(props.data)

	const onChange = (field, value) => {
		setNewValues({...new_values, [field]: value})
		props.onChange(field, value)
	}

	const onChangeCoefficients = (coefficients) => {
		setNewValues({...new_values, ...coefficients})
		Object.keys(coefficients).forEach((x) => {
			if (coefficients[x] !== props.data[x]) {
				props.onChange(x, coefficients[x])
			}
		})
	}
	const map_object_refs = getCurrentMapObjects();
	return (
		<div className='form'>
			<ElementGroup caption="Proprietà">
				<StringValue
					label="Nome"
					defaultValue={props.data.name}
					modified={props.data.name !== new_values.name}
					onChange={(event)=> {
						onChange("name", event.target.value)
					}}
				/>

				<NumericValue
					label="Numero"
					defaultValue={props.data.number}
					modified = {props.data.number !== new_values.number}
					onValueChange={(val)=> {
						onChange("number", val)
					}}
				/>

				<Poly5
					label="Polinomio"
					modified = {
						props.data.x0 !== new_values.x0 ||
						props.data.x1 !== new_values.x1 ||
						props.data.x2 !== new_values.x2 ||
						props.data.x3 !== new_values.x3 ||
						props.data.x4 !== new_values.x4 ||
						props.data.x5 !== new_values.x5
					}
					coefficients = {{
						x0: props.data['x0'],
						x1: props.data['x1'],
						x2: props.data['x2'],
						x3: props.data['x3'],
						x4: props.data['x4'],
						x5: props.data['x5']
					}}

					onChange={onChangeCoefficients}
				/>


				<ElementGroup caption="Filtro">
					<DropdownMenu
						label="Comportamento"
						options = {vsat_behavior_options}
						defaultValue={vSatBehaviorToDropdownOption(props.data.vsat_behavior)}
						
						modified = {props.data.vsat_behavior !== new_values.vsat_behavior}
						onChange={(opt)=> {
							onChange("vsat_behavior", opt.value)
						}}
						min={0}
						max={2}
					/>	

					<NumericValue
						disabled={new_values.vsat_behavior === 0}
						label="Val. MIN"
						defaultValue={props.data.vsat_min}
						modified = {props.data.vsat_min !== new_values.vsat_min}
						onValueChange={(val)=> {
							onChange("vsat_min", val)
						}}
						min={-10e9}
						max={10e9}
						stepSize={1000}
						majorStepSize={1000}
					/>
					<NumericValue
						disabled={new_values.vsat_behavior === 0}
						label="Val. MAX"
						defaultValue={props.data.vsat_max}
						modified = {props.data.vsat_max !== new_values.vsat_max}
						onValueChange={(val)=> {
							onChange("vsat_max", val)
						}}
						min={-10e9}
						max={10e9}
						stepSize={1000}
						majorStepSize={1000}
					/>
				</ElementGroup>

				<ElementGroup caption = "Grafana">
					<StringValue 
						label="URL" 
						moidified={props.data.grafana_url !== new_values.grafana_url}
						defaultValue={props.data.grafana_url}
						onChange={(e)=> {
							onChange("grafana_url", e.target.value)
						}}
					/>
				</ElementGroup>

				<ElementGroup caption="Azioni">
					<RemoveFromMapButton
						ready={true}
						map_object_refs={map_object_refs}
						type="channel"
						target_id_list={[store.getState().details.data.id]}
					/>&nbsp;
					<RemoveButton
						ready={true}
						{...store.getState().details}
					/>
				</ElementGroup>

			</ElementGroup>
		</div>
	);
}


export default FormChannelConfig