
import React from 'react'
import { Button, Icon, Tooltip, Position } from "@blueprintjs/core"
import { store, Event } from "../../redux.js"
import { ModifyButton, RemoveButton, RemoveFromMapButton, ShowOnMapButton } from '../../modules/Buttons.js'
import { MapMenu } from '../DetailsView.js'
import ElementGroup from '../../components/ElementGroup.js'
import ShowOnGrafana from '../../components/ShowOnGrafana.js'
import { connect } from 'react-redux'
import { selectMap } from '../../actions.js'
import { map } from 'leaflet'


const MapListItem = (props) => {
	var coordinates = null
	if (props.world) {
		coordinates = {lon: 0, lat: 0, area: 0}
		try {
			coordinates = JSON.parse(props.image)
		} catch (error) {
			console.log("Invalid map entry: ", error)
		}
	}
	
	const tooltip = 
		<>
			{props.description && <div>{props.description}<br/><br/></div>}
			{coordinates && <div>{`Coordinate: ${coordinates.lat}; ${coordinates.lon}`}</div>}
			<div><i>Trascina e rilascia sulla mappa per creare un collegamento</i></div>
		</>

	return (
		<div className="map-list-item">
			<Tooltip 
				placement={Position.RIGHT}
				content={tooltip}
				hoverOpenDelay={500}
			>
			<a 
				href="" 
				onClick={(e) => {
					e.preventDefault()
					selectMap(props.id)
				}}
				onDragEnd={(e) => {
					store.dispatch({ 
						type: Event.DROP_ON_MAP,
						target_type: "map",
						target_id: props.id,
						clientX: e.clientX,
						clientY: e.clientY,
						data: props
					})
				}}
			>
				<Icon icon={`${props.world ? "globe-network" : "map"}`}/> &nbsp;
				{props.is_current ? <b>{props.name}</b> : <>{props.name}</>}
			</a>
			</Tooltip>
		</div>
	)
}

const MapList = (props) => {
	return props.maps.map((map) => {
		return (<><MapListItem {...map} is_current={props.current_map.id === map.id} /></>)
	})
}

const NewMapItem = (props) => {
	return (
		<div className="map-list-item">
			<a href="" onClick={(e) => {
				e.preventDefault()
				store.dispatch({
					type: Event.OPEN_DIALOG,
					modal_type: "create_map",
					modal_args: {}
				})
			}} >
				<Icon icon='insert'/> &nbsp;
				Nuova mappa
			</a>
		</div>
	)
}

const MapNavigationList = (props) => {
	// sort maps by world then by name
	const _maps = props.maps.sort((m1, m2) => m1.name < m2.name ? 1:-1).sort((m1, m2) => m1.world < m2.world ? 1:-1)
	return (
	<>
		{props.maps.length > 0 ? 
			<>
				<h3>Vai alla mappa</h3>
				<MapList {...props} maps={_maps} />
			</> :
			<>
				<h3>Non ci sono ancora le mappe in questo cantiere</h3>
			</>
		}	
		<NewMapItem {...props} />
	</>
	)
}

const NewDataloggerButton = (props) => {
	return (
		<Button 
			icon="cell-tower" 
			onClick={() => {
				store.dispatch({
					type: Event.OPEN_DIALOG,
					modal_type: "create",
					modal_args: {object: "datalogger", parent_id: props.current_cantiere_id}
				})
			}}
		>
			Nuovo datalogger
		</Button>
	)
}

const DetailsViewCantiere = (props) => {
	const { user, is_super, is_admin, is_editor } = props
	const launch_date = new Date(props.data.start * 1000);
	return (
		<>
			<div className='area area-details-content'>
				<div>Data d'inizio : {launch_date.toLocaleString()}</div><br/>
				<MapNavigationList {...props} />
			</div>

			<div className='area area-details-summary'>
				{/* <MapMenu {...props} map_object_refs={self_map_object_ids} /> */}
				{(is_super || is_admin || is_editor) && <NewDataloggerButton {...props} />}
			</div>
			<div className='area area-details-footer'>
				{(is_super || is_admin) && <ModifyButton {...props} />}
				{(is_super) && <RemoveButton {...props} />}
			</div>
		</>
	)
}


const mapStateToProps = (state) => {
	return ({
		user : state.current_user,
		maps : state.maps,
		current_map : state.current_map
	})
}

export default connect(mapStateToProps)(DetailsViewCantiere)