
import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@blueprintjs/core'
import StateDebugPanel from '../modules/StateDebug.js'



const JsonExportButton = (props) => {
	return (
		<Button
			onClick={() => {
				const data_str = JSON.stringify(props.data, null, '\t');
				/*
				const data_url = 'data:application/json;charset=utf-8,'+ encodeURIComponent(data_str);
				const link_element = document.createElement('a');
				link_element.setAttribute('href', data_url);
				link_element.setAttribute('download', props.name || "export.json");
			
				link_element.click();
				*/
				var new_tab = window.open("about:blank", 'json view', 'height=1000,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
				new_tab.document.write('<html><body><pre>' + data_str + '</pre></body></html>');
	
			}}
		>
			View JSON
		</Button>)
}


const DebugView = (props) => {
	return (
		<>
			
			<div className="view view-debug">
				<div className="area area-debug-state">
					{/* <StateDebugPanel /> */ }
					<JsonExportButton data={props.data} />
				</div>
			</div>
		</>
		)
	}

const mapStoreToProps = (store) => {
	return ({
		data : store
	})
}

export default connect(mapStoreToProps)(DebugView)