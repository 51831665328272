import React from 'react'
import "../css/ChannelInfo.css"


const Tab = (props) => {
	return (
		<div className={`channelinfo-tab ${props.className ? props.className : ""}`}>
			<div className='channelinfo-tab-label'>
				{props.name}
			</div>
			<div className='channelinfo-tab-content'>
				{props.children}
			</div>
		</div>
	)
}


const VsatValues = (props) => {
	if (props.vsat_behavior === 0)
		return (<><Tab name="Azione">Disabilitata</Tab></>)

	const vsat_behavior_str = {
		0: "Nessun filtro",
		1: "Salta il valore",
		2: "Limita alla soglia",
		3: "Ripeti ultimo"
	}
	
	const MinMax = (props) => {
		return (
			<>
				<Tab name="Valore minimo">
					{props.vsat_min}
				</Tab>
				<Tab name="Valore massimo">
					{props.vsat_max}
				</Tab>
			</>
		)
	}

	const Action = (props) => {
		return (
			<Tab name="Azione">
				{vsat_behavior_str[props.vsat_behavior]}
			</Tab>
		)
	}

	if (vsat_behavior_str.hasOwnProperty(props.vsat_behavior))
		return (<><MinMax {...props}/> <Action {...props} /></>)
	return (<></>)
}


const ChannelInfo = (props) => {
	return (
		<div className='channelinfo-tabs-container'>
			<Tab name="Numero">
				{props.number}
			</Tab>
			<Tab name="Abilitato">
				{props.enabled ? "true" : "false"}
			</Tab>

			<Tab name="Trasformazione" className="channelinfo-transformation">
				{["x0", "x1", "x2", "x3", "x4", "x5"].map((e) => <Tab name={e} className='channelinfo-coefficient' key={`coef-${e}`}>{(props[e]).toFixed(2)}</Tab>)}
			</Tab>

			<Tab name="Filtro" className="channelinfo-saturation">
				<VsatValues {...props} />
			</Tab>

		</div>
	)
}

export default ChannelInfo