import React, { useState, useEffect } from 'react';
import { InputGroup, Tag } from "@blueprintjs/core"
 
interface QuickSearchInputProps {
	placeholder?: string;
	className?: string;
	onInput?: (value: string) => void;
	result_count: int;
}

const QuickSearchInput = (props: QuickSearchInputProps) => {
	const { 
		placeholder = "Search...",
		onInput = (value) => {},
		className = "",
		result_count = -1
	 } = props
	const [ value, setValue ] = useState("") 

	useEffect(() => {
		onInput(value)
	}, [value, onInput]);

	return (
		<div class={`bp3-input-group search-input ${className}`}>
			<InputGroup 
				leftIcon="search"
				rightElement={(result_count >= 0) && <Tag>{result_count}</Tag>}
				type="search" 
				placeholder={placeholder}
				value={value} 
				onFocus={(ev) => {
					setValue("")
				}} 
				onInput={((ev) => {
					setValue(ev.target.value)
				})}
			/>
		</div>
	)
}

export default QuickSearchInput