import React from 'react'
import { Position, Tooltip as Tp } from "@blueprintjs/core"


const FormulaString = (props) => {
	return (
		<span style={{fontFamily:"Consolas,Inconsolata,Courier New"}}>
			{props.children}
		</span>
	)
}

export class TooltipContent {

	static VIRTUAL_CHANNEL_NAME = 
		<>
			Nome del <strong>canale virtuale</strong><br/>
			Questo nome corrisponde a un nome della <strong>colonna</strong> in InfluxDB
		</>

	static VIRTUAL_CHANNEL_GROUP = 
		<>
			Nome del gruppo per il canale virtuale<br/>
			Rapperesenta il nome della tablla influx LIVE per i dati d'uscita
		</>
	
	static VIRTUAL_CHANNEL_FORMULA = 
		<>
			La formula utilizza variabili, operatori aritmetici e costanti.<br/><br/>
			Il nome della variabile è racchiuso tra parentesi graffe {} ed è composto da due parti:<br/>
			<b>Nome dell'acquisitore</b>, <b>Nome del canale</b> separati con un simbolo <b>:</b> (due punti) <br/>
			Per esempio: <FormulaString>&#123;Sensore A:Canale 1&#125;</FormulaString><br/>
			In questo esempio, "Sensore A" è il nome dell'acquisitore e "Canale 1" è il nome del canale.<br/><br/>
			Gli operatori aritmetici possono essere:
			<table>
				<tr><td>x + y</td><td>Somma</td></tr>
				<tr><td>x - y</td><td>Sottrazione</td></tr>
				<tr><td>x / y</td><td>Divisione</td></tr>
				<tr><td>x * y</td><td>Moltiplicazione</td></tr>
				<tr><td>x ** y</td><td>Potenza</td></tr>
				<tr><td>abs(x)</td><td>Modulo</td></tr>
				<tr><td>sqrt(x)</td><td>Radice quadrata</td></tr>
				<tr><td>x % y</td><td>Resto della division</td></tr>
				<tr><td>(...)</td><td>Precedenza</td></tr>
				<tr><td>ceil()</td><td>Approssimare per eccesso</td></tr>
				<tr><td>floor()</td><td>Approssimare per diffetto</td></tr>
				<tr><td>round()</td><td>Arrotonda</td></tr>
				<tr><td>time()</td><td>Timestamp UNIX, secondi (float)</td></tr>
				<tr><td>rand()</td><td>Valore random tra 0 e 1 (float)</td></tr>
				<tr><td>rads(gradi)</td><td>Converte i gradi decimali [0..360] in radianti [0..2pi]</td></tr>
				<tr><td>sin(a)</td><td>Seno di X (radianti)</td></tr>
				<tr><td>cos(a)</td><td>Coseno di X (radianti)</td></tr>
				<tr><td>mag(x, y, ...)</td><td>Radice della somma dei quadrati (modulo)</td></tr>
				<tr><td>phase(y, x)</td><td>Fase di un vettore (radianti)</td></tr>
				<tr><td>vecRotateX(x, y, gradi)</td><td>Componente X di un vettore ruotato</td></tr>
				<tr><td>vecRotateY(x, y, gradi)</td><td>Componente Y di un vettore routato</td></tr>
			</table><br/>
			Ad esempio: <br/>
			<FormulaString>(&#123;Sensore A:Canale 4&#125; + &#123;Sensore B:Canale 3&#125;) / 2 + 0.581</FormulaString>
		</>

	static VIRTUAL_CHANNEL_CHANNEL_TIMEOUT = 
		<>
			Se la formula include più canali, questo valore rappresenta la massima differenza di tempo accettabile tra due punti di dati provenienti da canali diversi.<br/>
			Il tempo assegnato al risultato del canale virtuale sarà quello dell'ultimo dato letto all'interno di questo intervallo temporale.<br/>
			Se manca un valore da uno qualsiasi dei canali considerati, la trasformazione non verrà eseguita e i valori disponibili degli altri canali saranno scartati.
		</>

	static VIRTUAL_CHANNEL_ZERO_VALUE = 
		<>
			Il valore da sottrarre dall'ogni risultato
		</>
	
}

export const Tooltip = (props) => {
	// props: content
	return (
		<Tp 
			content={props.content}
			placement={Position.RIGHT}
			hoverOpenDelay={500}
		>
			{props.children}
		</Tp>
	)
}

