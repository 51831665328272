
import React, { useState } from 'react'
import { Icon, NumericInput } from '@blueprintjs/core'
import {DropdownMenu, StringValue, TextValue, FormValue } from '../components/Form.js'
import '../css/Form.scss'
import ImageUploadArea from '../components/ImageUpload.js'
import AddressInputField, { CoordinatePicker } from '../modules/AddressInputField.js'

const map_type_options = [
	{
		"value" : false,
		"label" : <><Icon icon='map' />&nbsp; Mappa locale </>
	},
	{
		"value" : true,
		"label" : <><Icon icon='globe-network' />&nbsp; Mappa mondo </>
	}
]


const FormMapCreate = (props) => {
	const [ is_world_map, setIsWorldMap ] = useState(false)

	const [ lat, setLat ] = useState(null)
	const [ lon, setLon ] = useState(null)
	const [ area, setArea ] = useState(100)

	const clearExtraProps = () => {
		props.onChange({
			image: "",
			width: 0,
			height: 0,
			original_file: ""
		})
	}

	return (
		<div className='form'>
			<StringValue
				label="Nome"
				defaultValue=""
				placeholder="Nuova mappa"
				onChange={(event)=> {
					props.onChange("name", event.target.value)
				}}
			/>

			<TextValue
				label="Descrizione"
				defaultValue=""
				placeholder="Descrizione..."
				onChange={(event)=> {
					props.onChange("description", event.target.value)
				}}
			/>

			<DropdownMenu
				label="Tipo"
				defaultValue={map_type_options[0]}
				options={map_type_options}
				onChange={(option) => {
					setIsWorldMap(option.value)
					props.onChange("world", option.value)
					if (option.value ) { // is world map
						clearExtraProps()
					}
				}}
			/>
			{
				is_world_map &&
				<>
				<CoordinatePicker
					label="Centro dell mappa"
					placeholder=" Cerca indirizzo ..."
					onChange = {(ll) => {
						setLat (ll.lat)
						setLon (ll.lon)
						props.onChange({
							width: 0,
							height: 0,
							image: JSON.stringify({...ll, area: area}),
							original_file: "",
							world: true,
						})
					}}
				/>
				<FormValue label="Area (m)">
					<NumericInput
						value={area}
						placeholder="Raggio in metri"
						onValueChange={(v) => {
							setArea(v)
							props.onChange("image", JSON.stringify({
								lat: lat, lon: lon, area: v
							}))
						}}
					/>
				</FormValue>
				</>
			}
			{
				!is_world_map && 
				<ImageUploadArea 
					onUploadComplete={(data) => {
						props.onChange({
							image:  data.file_name,
							width: data.width,
							height: data.height,
							original_file: data.original_file,
							world: false
						})
					}}
					allowed_extensions=".svg, .png, .jpg, .jpeg, .gif"
				/>
			}
			
		</div>	
	)
}

export default FormMapCreate