
import React, { useState, useEffect } from 'react'
import { Tree, Icon, Tooltip, Position, Tag, Menu, MenuItem, Checkbox, Button, Popover, PopoverPosition, Intent } from "@blueprintjs/core"
import { DatePicker, TimePrecision } from "@blueprintjs/datetime"
import { TimezonePicker } from "@blueprintjs/timezone"
import moment from 'moment'
import { Table, Column, Cell } from "@blueprintjs/table"
import "@blueprintjs/table/lib/css/table.css"
import Select from 'react-select'
import ElementGroup from "../components/ElementGroup.js"
import { DropdownMenu } from "../components/Form.js"
import * as api from '../api/influx_api.js'


const SummaryTable = (props) => {
	const toDtString = (timestamp) => {
		// <3 stackoverflow
		var date = new Date(timestamp * 1000);
		return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
	}

	const nameRenderer = (row_index) => <Cell loading={props.isLoading}>{props.data[row_index].name}</Cell>
	const countRenderer = (row_index) => <Cell loading={props.isLoading}>{props.data[row_index].count.toString()}</Cell>
	const vminRenderer = (row_index) => <Cell loading={props.isLoading}>{props.data[row_index].min.toFixed(3)}</Cell>
	const vmaxRenderer = (row_index) => <Cell loading={props.isLoading}>{props.data[row_index].max.toFixed(3)}</Cell>
	const avgRenderer = (row_index) => <Cell loading={props.isLoading}>{props.data[row_index].avg.toFixed(3)}</Cell>
	const lastTsRenderer = (row_index) => <Cell loading={props.isLoading}>{toDtString(props.data[row_index].t_last)}</Cell>
	const firstTsRenderer = (row_index) => <Cell loading={props.isLoading}>{toDtString(props.data[row_index].t_first)}</Cell>

	const rowSelectRenderer = (row_index) => {
		return (
			<Checkbox
				checked={props.selected_rows.includes(row_index)}
				onClick={(e)=>{
					props.onSelect(row_index, e.target.checked)
				}}
			/>
		)
	}

	return (
		<Table numRows={props.data.length} columnWidths={[30, 150,100,100,100,100,120,120]}>
			<Column name=" " cellRenderer={rowSelectRenderer} />
			<Column name="Nome" cellRenderer={nameRenderer} />
			<Column name="Quantità" cellRenderer={countRenderer}  />
			<Column name="V. min" cellRenderer={vminRenderer} />
			<Column name="V. max" cellRenderer={vmaxRenderer} />
			<Column name="V. medio" cellRenderer={avgRenderer} />
			<Column name="Prima misura" cellRenderer={firstTsRenderer} />
			<Column name="Ultima misura" cellRenderer={lastTsRenderer} />
		</Table>
	)
}


const DBSelector = (props) => {
	const priority_items = props.priority_items ? props.priority_items : []
	return (
		<DropdownMenu
			vertical
			label="Database"
			defaultValue={{label: "seleziona...", value: null}}
			value={{label: props.value, value: props.label}}
			options={[
				...priority_items.map((e) => {return ({label: <b>{e}</b>, value: e})}),
				...props.items.map((e) => {return ({label: e, value: e})})
			]}
			isSearchable={false}
			isLoading={props.isLoading}
			onChange={props.onChange}
			onClick={props.onClick}
		/>
	)
}


const TableSelector = (props) => {
	return (
		<DropdownMenu
			vertical
			label="Tabella"
			defaultValue={{label: "seleziona...", value: null}}
			value={{label: props.value, value: props.label}}
			options={props.items.map((e) => {return ({label: e, value: e})})}
			isSearchable={false}
			isLoading={props.isLoading}
			isDisabled = {props.disabled}
			onChange={props.onChange}
			onClick={props.onClick}
		/>
	)
}

const DateTimePicker = (props) => {
	return (
		<DatePicker
			timePrecision={TimePrecision.SECOND}
			includeTime={true}
			label="prova123"
			highlightCurrentDay={true}
			shortcuts={true}
			minDate={new Date(0)}
			{...props}
		/>
	)
}

const InfluxDBClient = (props) =>  {

	const [ database_list, setDatabaseList ] = useState([])
	const [ current_db, setCurrentDb ] = useState("")
	const [ table_list, setTableList ] = useState([])
	const [ current_table, setCurrentTable ] = useState("")
	const [ series, setSeries ] = useState([])
	const [ selected_series, setSelectedSeries ] = useState([])
	const [ selected_rows, setSelectedRows ] = useState([])
	const [ databases_loading, setDatabasesLoading ] = useState(false)
	const [ tables_loading, setTablesLoading ] = useState(false)
	const [ series_loading, setSeriesLoading ] = useState(false)
	const [ ts_first, setTsFirst ] = useState(345600000) // 05/01/1970, earlier date produce an error
	const [ ts_last, setTsLast ] = useState(Date.now())
	const [ tz, setTz] = useState(moment.tz.guess())


	const rowSelectRenderer = () => {	
	
	}

	const getDatabaseList = () => {
		setDatabasesLoading(true)
		setCurrentTable("")
		return api.getDatabases().then((data) => {
			setDatabaseList(data)
		}).finally(() => {
			setDatabasesLoading(false)
		})
	}

	const getTableList = () => {
		setTablesLoading(true)
		return api.getTables(current_db).then((data) => {
			setTableList(data)
		}).finally(() => {
			setTablesLoading(false)
		})
	}

	const getSeriesList = () => {
		setSeriesLoading(true)
		setSelectedSeries([])
		setSelectedRows([])

		return api.getSeriesList(current_db, current_table, ts_first, ts_last).then((data) => {
			setSeries(data)
		}).catch((e) => {
			setSeries([])
		}).finally(() => {
			setSeriesLoading(false)
		})
	}


	const updateSelectedSeries = () => {
		// remove duplicates from selected rows
		var _selected_rows = selected_rows.reduce((acc, n) => {
			if (!acc.includes(n))
				acc.push(n)
			return acc;
		}, [])
		setSelectedSeries(_selected_rows.map((n, i)=> series[n].name))
		
	}


	useEffect (() => {
		// componentDidMount
		getDatabaseList()
	}, [])

	useEffect (() => {
		// current database changed
		if (current_db !== "")
			getTableList()
	}, [current_db])

	useEffect (() => {
		// current table or time range changed
		if (current_table !== "")
			getSeriesList()
	}, [current_table, ts_first, ts_last, tz])

	useEffect (() => {
		// row selection changed
		updateSelectedSeries()
	}, [selected_rows])

 
	return (
		<div className="area-influxdb-client">
			<ElementGroup caption="InfluxDB" className="area-table-selector">
				<DBSelector 
					items={database_list}
					priority_items={props.priority_database_list}
					value={current_db}
					isLoading={databases_loading}
					onClick={getDatabaseList}
					onChange = {(e) => {
						setCurrentDb(e.value)
						setCurrentTable("")
						setSeries([])
					}}
				/>

				<TableSelector
					items={table_list}
					isLoading={tables_loading}
					disabled={current_db ? false : true}
					onClick={getTableList}
					onChange = {(e) => {
						setCurrentTable(e.value)
					}}
					value={current_table}
				/>
			</ElementGroup>
			<ElementGroup caption="Data" className="area-timerange-selector">
				<p>
				<TimezonePicker 
					value={tz}
					defaultValue
					onChange={(tz) => {
						setTz(tz)
					}}
					valueDisplayFormat="composite"
					popoverProps={{
						position: PopoverPosition.BOTTOM
					}}
				/>
				</p>
				<p>
					<Popover position={PopoverPosition.RIGHT} content={
						<div onClick={(e) => e.stopPropagation()} >
							<DateTimePicker
								value={new Date(ts_first)}
								onChange={(date) => {	
									setTsFirst(date.getTime())
								}}
							/>
						</div>
					}>
						<Button><strong>Inizio </strong>{moment(ts_first).tz(tz).format()}</Button>
					</Popover>
				</p>
				<p>
					<Popover position={PopoverPosition.RIGHT} content={
						<div onClick={(e) => e.stopPropagation()} >
							<DateTimePicker
								value={new Date(ts_last)}
								onChange={(date) => {
									setTsLast(date.getTime())
								}}
							/>
						</div>
					}>
						<Button><strong>Fine&nbsp;&nbsp;&nbsp;</strong>{moment(ts_last).tz(tz).format()}</Button>
					</Popover>
				</p>

			</ElementGroup>

			<div className="area-toolbar">
		
				{/*
				// TODO: in develompent
				<Button icon='series-search' disabled={true}>
					Anteprima
				</Button>
				
				<Button 
					icon='th'
					disabled={true}
				>
					Esporta in CSV
				</Button>

				<Button 
					icon='function'
					disabled={true}
				>
					Applica la trasformazione
				</Button>
				*/}

				<Popover 
					disabled={selected_rows.length === 0}
					position={PopoverPosition.BOTTOM}
					content={
						<div className="popover-delete-confirmation">
							
							Dal <strong>{Date(ts_first)}</strong><br/> 
							al <strong>{Date(ts_last)}</strong><br/><br/>
							Le seguenti misure saranno <b>eliminate</b>:<br/><br/>
							{selected_series.map((e) => {
								return (
									<strong>{e}<br/></strong>
								)
							})}
							<br/>
							Conferma?
							<br/>

							<center>
								<Button 
									intent={Intent.DANGER}
									onClick={()=>{
										api.deleteSeries(current_db, current_table, selected_series, ts_first, ts_last)
										getSeriesList()
									}}
								>
									Si
								</Button>
								<Button onClick={(e) => e.stopPropagation(false)}>
									{/* TODO: find out how to close popover on click */}
									No
								</Button>
							</center>
						</div>
					}>
					<Button 
						icon='cross' 
						disabled={selected_rows.length === 0}>
						Elimina
					</Button>
				</Popover>

			</div>
			
			<ElementGroup caption="Panoramica" className="area-summary-table">
				<SummaryTable 
					isLoading={series_loading}
					data={series}
					selected_rows = {selected_rows}

					onSelect = {(row_id, checked) => {
						if (checked)
							setSelectedRows([...selected_rows, row_id])
						else {
							var _selected_rows = [...selected_rows]
							var index = selected_rows.indexOf(row_id)

							if (index > -1) {
								_selected_rows.splice(index, 1);
							}
							setSelectedRows(_selected_rows)
						}
					}}
					
				/>
			</ElementGroup>

		</div>
		
	)
}

export default InfluxDBClient