
import { Button, Classes, Dialog, Intent, InputGroup } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { 
	deleteCantiere, deleteChannel, deleteDatalogger, deleteDevice, deleteMapObject, 
	deleteVirtualChannel, deleteVirtualChannelGroup, deleteGrafanaPanelByDeviceUrl 
} from '../actions.js'
import { deleteSeries } from '../api/influx_api.js'
import { ValueCheckbox } from '../components/Form.js'
import { InfluxSummaryPanel } from '../modules/InfluxSummaryPanel.js'
import { showNotification } from "../modules/Toast.js"
import { Event, store } from '../redux.js'



const DeleteChannelForm = (props) => {
	const [ delete_influx, setDeleteInflux ] = useState(false)
	const [ selected_columns, setSelectedColumns ]= useState([props.data.name])
	const parent_device_name = store.getState().devices.filter((dev) => (dev.id === props.data.device))[0].name
	const state = store.getState()
	const db_name = state.current_cantiere_data.db_name_raw

	const before_delete_func = () => {
		// console.debug("Columns to delete: ", db_name, parent_device_name, selected_columns)
		if (selected_columns.length > 0)
			return deleteSeries(db_name, parent_device_name, selected_columns)
		else {
			showNotification("warning", "Nessun colonna selezionata", "Selezionare le colonne da cancellare da InfluxDB")
			return Promise.reject()
		}
		// return Promise.resolve()
	}

	useEffect(() => {
		// column selection changed
		if (delete_influx)
			props.before_delete_promise_setter(before_delete_func)
		else
			props.before_delete_promise_setter(()=>{ return Promise.resolve()})
	}, [delete_influx, selected_columns])

	return (
		<>
			Sei sicuro di voler eliminare il canale <b>{props.data.name}</b> ?

			<ValueCheckbox 
				label="Elimina i dati da InfluxDB"
				checked={delete_influx}
				onChange={(e) => {
					setDeleteInflux(e.target.checked)
				}}
			>
			</ValueCheckbox>
			
			{delete_influx && 
				<InfluxSummaryPanel 
					db_name={state.current_cantiere_data.db_name_raw}
					table_name={parent_device_name}
					select_all={false}
					column_names={[props.data.name]}
					onSelectColumn={(column_name_list) => {
						setSelectedColumns(column_name_list)
					}}
				/>
			}
		</>
	)
}

const DeleteCantiereForm = (props) => {
	const { data } = props
	const [ delete_influx, setDeleteInflux ] = useState(false)
	const [ delete_grafana, setDeleteGrafana] = useState(true)
	const [ delete_confirmation_string, setDeleteConfirmationString ] = useState("")
	const dl_count = props.dataloggers.filter((dl) => (dl.cantiere === data.id)).length;
	const dev_count = props.devices.filter((d) => (d.cantiere === data.id)).length;
		
	useEffect(() => {
		props.before_delete_promise_setter(() => {
			return Promise.resolve([delete_grafana, delete_influx, delete_confirmation_string])
		})
	}, [delete_influx, delete_grafana, delete_confirmation_string])

	return (
		<>
			Attenzione! Stai per cancellare il cantiere <b>{data.name}</b>
			{(dl_count > 0 || dev_count > 0) &&
				<>
					<br/>
					Questo cantiere cantiere contiene
					&nbsp;{dl_count > 0 && <> <b>{dl_count}</b> datalogger e </>}
					&nbsp;<b>{dev_count}</b> acquisitori.<br/>
				</>
			}
			<br/>
			Per procedere scrivi <b>{`eliminare ${data.name.toLowerCase()}`}</b> nel campo di sotto:<br/><br/>
			<InputGroup
				autoFocus={true}
				value={delete_confirmation_string}
				onChange={(e) => { 
					setDeleteConfirmationString(e.target.value)
				}}
			/>
		
			<br/>
			<ValueCheckbox 
				label="Elimina i grafici"
				checked={delete_grafana}
				onChange={(e) => {
					setDeleteGrafana(e.target.checked)
				}}
			/>

			<ValueCheckbox 
				label="Elimina i dati acquisiti"
				checked={delete_influx}
				onChange={(e) => {
					setDeleteInflux(e.target.checked)
				}}
			/>

		</>
	)
}

const DeleteDeviceForm = (props) => {
	const [ delete_influx, setDeleteInflux ] = useState(false)
	const [ delete_grafana, setDeleteGrafana ] = useState(false)
	const [ selected_columns, setSelectedColumns ] = useState([])

	const channels = props.channels.filter((ch) => {return (ch.device === props.data.id)})
	const state = store.getState()
	const db_name = state.current_cantiere_data.db_name_raw
	const table_name = props.data.name

	const before_delete_func = () => {
		// console.debug("Columns to delete: ", db_name, table_name, selected_columns)
		if (delete_grafana) {
			deleteGrafanaPanelByDeviceUrl(props.data.grafana_url)
		}
		if (delete_influx) {
			return deleteSeries(db_name, table_name, selected_columns)
		}
		// return Promise.resolve()
	}

	const question = channels.length > 0 ? 
		<div>
			L'acquisitore <b>{props.data.name}</b> contiene <b>{channels.length}</b> canali. <br/>
			Sei sicuro di voler eliminarlo ?
		</div>
	: <>Sei sicuro di voler eliminare l'acquisitore <p>{props.data.name}</p> ?</>

	useEffect(() => {
		// column selection changed
		if (delete_influx || delete_grafana)
			props.before_delete_promise_setter(before_delete_func)
		else
			props.before_delete_promise_setter(()=>{ return Promise.resolve()})
	}, [delete_influx, delete_grafana, selected_columns])

	return (<>
		
		<ValueCheckbox
			label="Elimina il grafico"
			checked={delete_grafana}
			onChange={(e) => {
				setDeleteGrafana(e.target.checked)
			}}
		/>
		<ValueCheckbox 
			label="Elimina i dati da InfluxDB"
			checked={delete_influx}
			onChange={(e) => {
				setDeleteInflux(e.target.checked)
			}}
		>
		</ValueCheckbox>
		{delete_influx && 
			<InfluxSummaryPanel 
				db_name={state.current_cantiere_data.db_name_raw}
				table_name={props.data.name}
				select_all={true}
				onSelectColumn={(column_name_list) => {
					setSelectedColumns(column_name_list)
				}}
			/>
		}
	</>)
}

const DeleteDataloggerForm = (props) => {
	return (<></>)
}


const DeleteConfirmationModal = (props) => {
	const {
		id = -1,
		obj_id = -1,
		obj_type,
		target_id_list,
		data
	} = props

	// react state cannot hold pure promise or function, so wrap it inside the dict!
	const [ before_delete_promise, _setBeforeDeletePromise ] = useState({exec: () => {return Promise.resolve([])}})
	const [ busy, setBusy ] = useState(false) 
	const setBeforeDeletePromise = (func) => {
		_setBeforeDeletePromise({exec : func})
	}

	const hide = () => {
		store.dispatch({type: Event.CLOSE_DIALOG});
	}

	const apply = () => {
		setBusy(true)
		setTimeout(() => {
			setBusy(false)
		}, 3000) // workaround measure for broken action promises
		return before_delete_promise.exec().then((args) => {
			var this_action = null
			switch(props.obj_type) {
				case "cantiere":
					this_action = () => deleteCantiere(obj_id, ...args)
					break;
				case "datalogger":
					this_action = () => deleteDatalogger (obj_id)
					break;
				case "device":
					this_action = () => deleteDevice (obj_id)
					break;
				case "channel":
					this_action = () => deleteChannel (obj_id)
					break;
				case "vchannel":
					this_action = () => deleteVirtualChannel (obj_id)
					break;
				case "vchannel-group":
					this_action = () => deleteVirtualChannelGroup (obj_id)
					break;
				case "map_objects":
					this_action = () => deleteMapObject(target_id_list)
					break;
				default:
					this_action = () => {}
			}
			if (this_action !== null) {
				return this_action().then(() => {
					hide();
				}).finally(() => {
					setBusy(false)
				})
			}
			return Promise.reject().finally(() => {
				setBusy(false)
			})
		})
	}

	const content = () => {
		const id = props.data?.id || props.id;
		const def_question = <> Sei sicuro di voler eliminare il {props.obj_type} <b>{props.data.name}</b> ? </>
				
		switch (obj_type) {
			case "cantiere":
				return <DeleteCantiereForm before_delete_promise_setter={setBeforeDeletePromise} {...props} />;
			case "datalogger":
				const dev_count = props.devices.filter((dev) => dev.datalogger === id).length;
				if (dev_count > 0) {
					return (
						<div>
						Il datalogger <b>{props.data.name}</b> contiene <b>{dev_count}</b> acquisitori. <br />
						Sei sicuro di voler eliminarlo ?
						</div>
					);
				}
				return def_question;
			case "device":
				return (
					<>
					<>{def_question}</>
					<DeleteDeviceForm {...props} before_delete_promise_setter={setBeforeDeletePromise} />
					</>
				)
			case "channel":
				return <DeleteChannelForm {...props} before_delete_promise_setter={setBeforeDeletePromise} />;
			case "vchannel":
				return def_question;
			case "vchannel-group":
				return (
					<>
					Sei sicuro di voler eliminare<br/>
					<b>Tutti i canali virtuali</b> del gruppo <b>{props.data.name} ?</b>
					</>
				);
			case "map_objects":
				if (props.target_id_list.length > 0) {
				return (
					<div>
					Eliminare {props.target_id_list.length} oggetti ?
					{/* TODO: add switch to allow delete only from current map, or all maps */}
					</div>
				);
				}
				return "Nessun oggetto trovato sulle mappe";
			default:
				return null;
		  }
	}

	return (
		<>
			{props.open && props.type === "remove" && <Dialog
				className="modal-config"
				isOpen={true}
				onClose={hide}
				title="Elimina"
			>
				<Classes.DIALOG_BODY>
					<div className='modal-content'>
						{content()}
					</div>
				</Classes.DIALOG_BODY>
				<Classes.DIALOG_FOOTER>
					<Button 
						intent={Intent.PRIMARY}
						onClick={apply}
						disabled={busy}
					>
						Elimina
					</Button>
					<Button 
						onClick={hide}
					>
						Annulla
					</Button>
				</Classes.DIALOG_FOOTER>

			</Dialog>
			}
		</>
	);
}

const mapStoreToProps = (store) => {
	return ({
		open           : store.modal_open,
		type           : store.modal_type,
		obj_type       : store.modal_args.object,
		obj_id         : store.modal_args.id,
		target_type    : store.modal_args.target_type, // for map objects
		target_id_list : store.modal_args.target_id_list, // for map objects
		data           : store.details.data,
		dataloggers    : store.dataloggers,
		devices        : store.devices,
		channels       : store.channels 
		// last_event : store.last_event
	})
};

export default connect(mapStoreToProps)(DeleteConfirmationModal)